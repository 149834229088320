import React from 'react';

import { Box, Typography } from '@mui/material';

import useDocumentTitle from 'common/documentTitleHook';
import MainBox from 'components/layout/MainBox';
import EditRankingTypeDataGrid from 'features/rankingTypes/list/EditRankingTypeDataGrid';

function ManageRankingType() {
  useDocumentTitle('Admin - Gestion des ranking type | benhur');

  return (
    <Box>
      <Typography variant="h1">
        Gestion des ranking type
      </Typography>
      <MainBox>
        <EditRankingTypeDataGrid />
      </MainBox>
    </Box>
  );
}

export default ManageRankingType;
