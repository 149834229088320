import { fork } from 'redux-saga/effects';

import {
  sagaFetchActors,
  sagaUpdateActorsWithSites,
  sagaUpdateActor,
  sagaAddActorWithSites,
  sagaAddSiteUrlPattern,
  sagaUpdateSiteUrlPattern,
  sagaDeleteSiteUrlPattern,
  sagaDeleteActorWithSites,
} from 'features/actors/state/saga';
import {
  sagaLogin,
  sagaLogout,
  sagaFetchCurrentUser,
  sagaRefreshToken,
  sagaFetchIsInternal,
  sagaLoginPassword,
} from 'features/authentication/state/saga';
import {
  sagaFetchCategoryGroups,
  sagaUpdateCategoryGroup,
  sagaAddCategoryGroup,
  sagaDeleteCategoryGroups,
  sagaDeleteCategoryValues,
  sagaUpdateCategoryValue,
  sagaUpdateCategory,
  sagaCreateCategoryWithValues,
  sagaCreateCategoryValues,
  sagaUpdateCategoryValueMultiple,
} from 'features/categoryGroups/state/saga';
import {
  sagaDeleteDataFiles,
  sagaDownloadDataFile,
  sagaFetchDataFiles,
  sagaUpdateDataFiles,
  sagaUploadDataFiles,
} from 'features/dataFiles/state/saga';
import { sagaFetchDataSources } from 'features/dataSource/state/saga';
import { sagaFetchChartData, sagaFetchExportData } from 'features/dataviz/state/saga';
import sagaFetchFiscalYears from 'features/fiscalYears/state/saga';
import sagaFetchHarvestProjects from 'features/harvestProjects/state/saga';
import {
  sagaDeleteKeywords,
  sagaAddKeywords,
  sagaFetchKeywords,
} from 'features/keywords/state/saga';
import {
  sagaCreateKpiViews,
  sagaDeleteKpiViews,
  sagaFetchKpiViews,
  sagaUpdateKpiViews,
  sagaDownloadKpiViews,
} from 'features/kpiView/state/saga';
import sagaFetchPeriodByScopeAndDataSource from 'features/period/state/saga';
import {
  sagaCreateProject,
  sagaUpdateProject,
  sagaArchiveProject,
  sagaFetchProjects,
  sagaProjectUpdateUsers,
  sagaProjectAddUser,
  sagaProjectRemoveUser,
  sagaCreditProject,
  sagaUpdateProjectManagerAndDircli,
  sagaReactivateProject,
  sagaPurgeProject,
} from 'features/projects/state/saga';
import { sagaFetchTransactions } from 'features/projectTransaction/state/saga';
import { sagaFetchRankingTypes, sagaUpdateRankingTypes } from 'features/rankingTypes/state/saga';
import {
  sagaFetchSerpAnalyses,
  sagaUpdateSerpAnalyses,
  sagaDeleteSerpAnalyses,
  sagaCreateSerpAnalysis,
  sagaCreateSerpAnalysisScrapping,
} from 'features/serpAnalysis/state/saga';
import { sagaFetchSerpAnalysisIndex } from 'features/serpAnalysisIndex/state/saga';
import sagaFetchSerpAnalysisPlanning
  from 'features/serpAnalysisPlanning/state/saga';
import sagaFetchserpAnalysisScrapping from 'features/serpAnalysisScrapping/state/saga';
import {
  sagaFetchUsers,
  sagaUpdateUser,
  sagaDeleteUser,
  sagaUpdateUserProjects,
  sagaCreateUser,
} from 'features/users/state/saga';

export default function* rootSaga() {
  // sagas authentication
  yield fork(sagaLogin);
  yield fork(sagaLoginPassword);
  yield fork(sagaLogout);
  yield fork(sagaRefreshToken);
  yield fork(sagaFetchIsInternal);

  // sagas project
  yield fork(sagaCreateProject);
  yield fork(sagaUpdateProject);
  yield fork(sagaUpdateProjectManagerAndDircli);
  yield fork(sagaArchiveProject);
  yield fork(sagaFetchProjects);
  yield fork(sagaProjectUpdateUsers);
  yield fork(sagaProjectAddUser);
  yield fork(sagaProjectRemoveUser);
  yield fork(sagaCreditProject);
  yield fork(sagaReactivateProject);
  yield fork(sagaPurgeProject);

  // sagas analyses
  yield fork(sagaFetchSerpAnalyses);
  yield fork(sagaUpdateSerpAnalyses);
  yield fork(sagaDeleteSerpAnalyses);
  yield fork(sagaCreateSerpAnalysis);
  yield fork(sagaCreateSerpAnalysisScrapping);

  // sagas actor
  yield fork(sagaFetchActors);
  yield fork(sagaUpdateActorsWithSites);
  yield fork(sagaUpdateActor);
  yield fork(sagaAddActorWithSites);
  yield fork(sagaAddSiteUrlPattern);
  yield fork(sagaUpdateSiteUrlPattern);
  yield fork(sagaDeleteSiteUrlPattern);
  yield fork(sagaDeleteActorWithSites);

  // sagas categoryGroups
  yield fork(sagaFetchCategoryGroups);
  yield fork(sagaUpdateCategoryGroup);
  yield fork(sagaAddCategoryGroup);
  yield fork(sagaDeleteCategoryGroups);
  yield fork(sagaUpdateCategoryValue);
  yield fork(sagaUpdateCategory);
  yield fork(sagaDeleteCategoryValues);
  yield fork(sagaCreateCategoryWithValues);
  yield fork(sagaCreateCategoryValues);
  yield fork(sagaUpdateCategoryValueMultiple);

  // sagas dataFile
  yield fork(sagaDeleteDataFiles);
  yield fork(sagaDownloadDataFile);
  yield fork(sagaFetchDataFiles);
  yield fork(sagaUpdateDataFiles);
  yield fork(sagaUploadDataFiles);

  // saga dataSources
  yield fork(sagaFetchDataSources);

  // saga fiscalYears
  yield fork(sagaFetchFiscalYears);

  // saga harvestProjects
  yield fork(sagaFetchHarvestProjects);

  // saga keywords
  yield fork(sagaAddKeywords);
  yield fork(sagaDeleteKeywords);
  yield fork(sagaFetchKeywords);

  // saga kpi_view
  yield fork(sagaCreateKpiViews);
  yield fork(sagaDeleteKpiViews);
  yield fork(sagaFetchKpiViews);
  yield fork(sagaUpdateKpiViews);
  yield fork(sagaDownloadKpiViews);

  // saga users
  yield fork(sagaFetchUsers);
  yield fork(sagaCreateUser);
  yield fork(sagaUpdateUser);
  yield fork(sagaUpdateUserProjects);
  yield fork(sagaFetchCurrentUser);
  yield fork(sagaDeleteUser);

  // saga dataviz
  yield fork(sagaFetchChartData);
  yield fork(sagaFetchExportData);

  // saga periode
  yield fork(sagaFetchPeriodByScopeAndDataSource);

  // saga rankingTypes
  yield fork(sagaFetchRankingTypes);
  yield fork(sagaUpdateRankingTypes);

  // saga serpAnalysisPlanning
  yield fork(sagaFetchSerpAnalysisPlanning);

  // saga serp_analysis_index
  yield fork(sagaFetchSerpAnalysisIndex);

  // saga serp_analysis_scrapping
  yield fork(sagaFetchserpAnalysisScrapping);

  // saga transaction
  yield fork(sagaFetchTransactions);
}
