import React from 'react';
import { NavLink } from 'react-router-dom';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Divider,
  Stack,
  styled,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  StackProps,
  Typography,
} from '@mui/material';

import ProjectDropdown from 'features/projects/components/ProjectDropdown';
import AnalysisDropdown from 'features/serpAnalysis/components/AnalysesDropdown';
import useSerpAnalyses from 'features/serpAnalysis/state/useSerpAnalyses';
import { UserType } from 'features/users/types';

import { SubMenuList } from './types';

const PREFIX = 'ProjectActionsView';

const classes = {
  core: `${PREFIX}-core`,
  link: `${PREFIX}-link`,
  firstLevelAcc: `${PREFIX}-firstLevelAcc`,
  firstLevelLink: `${PREFIX}-firstLevelLink`,
  activeFirstLevelLink: `${PREFIX}-activeFirstLevelLink`,
  secondLevelLink: `${PREFIX}-secondLevelLink`,
  activeSecondLevelLink: `${PREFIX}-activeSecondLevelLink`,
  accordion: `${PREFIX}-accordion`,
  accordionSummary: `${PREFIX}-accordionSummary`,
  accordionSummaryActive: `${PREFIX}-accordionSummaryActive`,
  accDetail: `${PREFIX}-accDetail`,
  divider: `${PREFIX}-divider`,
  spanMenu: `${PREFIX}-spanMenu`,
  dropdownCtn: `${PREFIX}-dropdownCtn`,
};

const StyledStack = styled(Stack)<StackProps>(({ theme }) => ({
  [`&.${classes.core}`]: {
    margin: theme.spacing(4),
  },

  [`& .${classes.divider}`]: {
    border: 'none',
    borderTop: '1px solid #a1a5b7',
  },

  [`& .${classes.dropdownCtn}`]: {
    width: '100%',
    marginBottom: '1.2rem',
    height: 'fit-content',
  },
}));

const StyledAccordion = styled(
  Accordion,
  {
    shouldForwardProp: (prop) => prop !== 'isExpanded',
  },
)<{ isExpanded?: boolean }>(({ theme, isExpanded }) => ({

  [`& .${classes.link}`]: {
    color: 'white',
    textDecoration: 'none',
    display: 'block',
    cursor: 'pointer',
    width: '100%',
  },

  [`& .${classes.firstLevelLink}`]: {
    justifyContent: 'left',
  },

  [`& .${classes.activeFirstLevelLink}`]: {
    color: theme.palette.primary.light,
    justifyContent: 'left',
    fontSize: theme.fontSize.medium,
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '5px',
    width: '100%',
  },

  [`& .${classes.secondLevelLink}`]: {
    padding: '5px 5px 5px 35px',
    display: 'block',
    fontSize: theme.fontSize.medium,
    '&:hover': {
      textDecoration: 'none',
      color: theme.palette.primary.light,
      padding: '5px 5px 5px 35px',
      display: 'block',
      backgroundColor: theme.palette.secondary.main,
      borderRadius: '5px',
    },
  },

  [`& .${classes.activeSecondLevelLink}`]: {
    fontSize: theme.fontSize.medium,
    color: theme.palette.primary.light,
    padding: '5px 5px 5px 35px',
    display: 'block',
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '5px',
  },

  [`&.${classes.accordion}`]: {
    color: 'white',
    width: '250px',
    lineHeight: '1.6em',
    backgroundColor: theme.palette.primary.main,
    boxShadow: 'none',
  },

  [`& .${classes.accordionSummary}`]: {
    color: 'white',
    fontSize: theme.fontSize.medium,
    minHeight: '35px',
    padding: '0 5px 0 5px',
    borderRadius: '5px',
    marginBottom: '5px',
    '&:hover': {
      color: theme.palette.primary.light,
      backgroundColor: theme.palette.secondary.main,
      '& .MuiAccordionSummary-expandIconWrapper': {
        color: theme.palette.primary.light,
      },
      '& .Mui-expanded': {
        color: theme.palette.primary.light,
        '&.MuiAccordionSummary-expandIconWrapper': {
          color: theme.palette.primary.light,
        },
      },
    },
    '& .Mui-expanded': {
      color: isExpanded ? theme.palette.secondary.main : 'white',
      '&.MuiAccordionSummary-expandIconWrapper': {
        color: isExpanded ? theme.palette.secondary.main : 'white',
      },
    },
    '& .MuiAccordionSummary-expandIconWrapper': {
      color: 'white',
    },
    '& .MuiAccordionSummary-content': {
      margin: '0 !important',
    },
  },

  [`& .${classes.accordionSummaryActive}`]: {
    color: theme.palette.primary.light,
    backgroundColor: theme.palette.secondary.main,
    fontSize: theme.fontSize.medium,
    minHeight: '35px',
    borderRadius: '5px',
    padding: '0 5px 0 5px',
    marginBottom: '5px',
    height: '28px',
    '&.Mui-expanded': {
      color: theme.palette.secondary.main,
    },
    '& .MuiAccordionSummary-expandIconWrapper': {
      color: theme.palette.primary.light,
    },
    '& .MuiAccordionSummary-content': {
      margin: '0 !important',
    },
  },

  [`& .${classes.accDetail}`]: {
    padding: '0',
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
  },

  [`& .${classes.spanMenu}`]: {
    fontSize: theme.fontSize.medium,
    padding: '2px',
  },
}));

type ProjectActionViewProps = {
  menu: SubMenuList[],
  projectName: string,
  expanded: string | boolean,
  handleChange: Function,
  projectId: number,
  analysisId: number | undefined,
  scopeId: number,
  currentPage: string,
  currentUser?: UserType,
}

function ProjectActionsView(props: ProjectActionViewProps): JSX.Element {
  const {
    menu,
    projectName,
    expanded,
    handleChange,
    projectId,
    analysisId,
    scopeId,
    currentPage,
    currentUser,
  } = props;

  const { serpAnalyses: analyses } = useSerpAnalyses(
    { scopeId, doFetch: false },
  );

  return (
    <StyledStack
      alignItems="center"
      className={classes.core}
    >
      <Stack
        className={classes.dropdownCtn}
        justifyContent="space-between"
        gap={1}
      >
        <ProjectDropdown
          projectId={projectId}
        />
        {
          analyses.length > 0 && (
            <AnalysisDropdown
              projectId={projectId}
              analysisId={analysisId || 0}
              projectName={projectName}
              scopeId={scopeId}
            />
          )
        }

      </Stack>
      {menu
        .filter((m) => !(m.externalBlocked && currentUser?.user_type.is_external))
        .map((menuItem) => (menuItem.name ? (
          <StyledAccordion
            key={menuItem.name}
            disableGutters
            className={classes.accordion}
            expanded={(expanded === menuItem.name) || (menuItem.stayOpen === true)}
            onChange={menuItem.stayOpen ? undefined : handleChange(menuItem.name)}
            isExpanded={expanded === menuItem.name}
          >
            {menuItem.link
              ? (
                <NavLink
                  to={menuItem.link}
                  className={classes.link}
                  state={{ from: `${analysisId}` }}
                  end
                >
                  {({ isActive }) => (
                    <AccordionSummary
                      className={
                        (
                          isActive
                          || (
                            menuItem.link?.includes('results')
                            && ['results', 'compare'].includes(currentPage)
                          )
                        )
                          ? classes.accordionSummaryActive
                          : classes.accordionSummary
                      }
                      expandIcon={
                        menuItem.subMenu.length !== 0
                        && (
                          <ExpandMoreIcon />
                        )
                      }
                      aria-controls="panel1a-content"
                    >
                      <Stack
                        direction="row"
                        justifyContent="center"
                        spacing={1}
                        className={
                          isActive ? classes.activeFirstLevelLink : classes.firstLevelLink
                        }
                      >
                        {menuItem.icon}
                        <Typography
                          component="span"
                          className={
                            classes.spanMenu
                          }
                        >
                          {menuItem.name}
                        </Typography>
                      </Stack>
                    </AccordionSummary>
                  )}
                </NavLink>
              )
              : (
                <AccordionSummary
                  className={classes.accordionSummary}
                  expandIcon={
                    menuItem.subMenu.length !== 0
                    && !menuItem.stayOpen
                    && <ExpandMoreIcon />
                  }
                  aria-controls="panel1a-content"
                >
                  <Stack
                    direction="row"
                    justifyContent="center"
                    spacing={1}
                    className={classes.firstLevelAcc}
                  >
                    {menuItem.icon}
                    <Typography
                      component="span"
                      className={classes.spanMenu}
                    >
                      {menuItem.name}
                    </Typography>
                  </Stack>
                </AccordionSummary>
              )}
            {menuItem.subMenu.length !== 0 && (
              <AccordionDetails className={classes.accDetail}>
                {menuItem.subMenu
                  .filter((item) => !(item.externalBlocked && currentUser?.user_type.is_external))
                  .map((subMenuItem) => (subMenuItem.name ? (
                    <NavLink
                      key={subMenuItem.name}
                      to={subMenuItem.link}
                      className={classes.link}
                      end={!subMenuItem.includeSubLink}
                      state={{ from: `${analysisId}` }}
                    >
                      {({ isActive }) => (
                        <Typography
                          component="span"
                          className={
                            isActive ? classes.activeSecondLevelLink : classes.secondLevelLink
                          }
                        >
                          {subMenuItem.name}
                        </Typography>
                      )}
                    </NavLink>
                  )
                    : (
                      <Divider key={Math.random() * 1000} className={classes.divider} />
                    )
                  ))}
              </AccordionDetails>
            )}
          </StyledAccordion>
        )
          : (
            <Divider
              key={Math.random() * 1000}
              className={classes.divider}
              sx={{ width: 0.9, my: '5px' }}
            />
          )
        ))}
    </StyledStack>
  );
}

export default ProjectActionsView;
