import React from 'react';

import {
  Box,
  Button,
  Stack,
  Typography,
} from '@mui/material';
import img from 'assets/images/serp_physionomy_summary_img_small.jpg';

import globalTheme from 'common/theme';
import MainBox from 'components/layout/MainBox';
import TitleComponent from 'features/dataviz/components/generic/charts/common/Title';
import { DEVICE_TYPE } from 'features/deviceType/CONSTANTS';
import { Analysis } from 'features/serpAnalysis/types';
import useSerpAnalysisScrappings
  from 'features/serpAnalysisScrapping/state/useSerpAnalysisScrappings';
import { convertDate, DATE_NO_HOUR } from 'utils/formatDate';

type PhysionomySummaryProps = {
  analysis: Analysis | undefined,
  periodeBegin?: string | undefined,
  periodeEnd?: string | undefined,
  deviceTypeId?: number | undefined,
  searchEngine?: string | undefined,
  isComparison: boolean,
  scrappingIds?: number[] | undefined,
}

function PhysionomySummary (props: PhysionomySummaryProps) {
  const {
    analysis,
    isComparison,
    periodeBegin,
    periodeEnd,
    deviceTypeId,
    searchEngine,
    scrappingIds,
  } = props;

  const { scrappings } = useSerpAnalysisScrappings(analysis?.id ?? 0);

  const sortedScrapping = scrappingIds?.sort();
  const scrappingToCompareOne = scrappings.find(
    (scrap) => sortedScrapping?.[0] === scrap.id,
  );
  const scrappingToCompareTwo = scrappings.find(
    (scrap) => sortedScrapping?.[1] === scrap.id,
  );

  const hasTwoScrappings = scrappingToCompareOne && scrappingToCompareTwo;

  return (
    <MainBox>
      <Stack direction="row">
        <Box component="img" src={img} width={150} />
        <Stack
          direction="column"
          justifyContent="space-between"
          marginLeft={3}
          width="100%"
        >
          <TitleComponent title="Comprendre ce graphique" capitalLetter />
          {
            (isComparison && !hasTwoScrappings && (
              <Typography marginTop={2}>
                Vous visualisez
                <Typography
                  variant="bold"
                  color={globalTheme.palette.primary.light}
                  fontSize="1.1rem"
                >
                  {' '}
                  la part de voix de vos concurrents
                  {' '}
                </Typography>
                sur l&apos;étude des mots-clés
                <Typography variant="bold">
                  {` "${analysis?.label}" `}
                </Typography>
                dans les résultats de
                <Typography variant="bold">
                  {` ${searchEngine?.[0]?.toUpperCase()}${searchEngine?.slice(1)} `}
                  {deviceTypeId === DEVICE_TYPE.DESKTOP ? ' Desktop ' : ' Mobile '}
                </Typography>
                du
                <Typography variant="bold">
                  {` ${convertDate(
                    new Date(scrappingToCompareOne?.scrapping_date || ''),
                    DATE_NO_HOUR,
                  )
                  } `}
                </Typography>
              </Typography>
            ))
            || (isComparison && hasTwoScrappings && (
              <Typography marginTop={2}>
                Vous visualisez
                <Typography
                  variant="bold"
                  color={globalTheme.palette.primary.light}
                  fontSize="1.1rem"
                >
                  {' '}
                  la part de voix de vos concurrents
                  {' '}
                </Typography>
                sur l&apos;étude des mots-clés
                <Typography variant="bold">
                  {` "${analysis?.label}" `}
                </Typography>
                dans les résultats de
                <Typography variant="bold">
                  {` ${searchEngine?.[0]?.toUpperCase()}${searchEngine?.slice(1)} `}
                  {
                  scrappingToCompareOne.device_type_id === DEVICE_TYPE.DESKTOP
                    ? ' Desktop '
                    : ' Mobile '
                  }
                </Typography>
                du
                <Typography variant="bold">
                  {` ${convertDate(
                    new Date(scrappingToCompareOne.scrapping_date),
                    DATE_NO_HOUR,
                  )
                  } `}
                </Typography>
                et de
                <Typography variant="bold">
                  {` ${searchEngine?.[0]?.toUpperCase()}${searchEngine?.slice(1)} `}
                  {
                  scrappingToCompareTwo.device_type_id === DEVICE_TYPE.DESKTOP
                    ? ' Desktop '
                    : ' Mobile '
                  }
                </Typography>
                du
                <Typography variant="bold">
                  {` ${convertDate(
                    new Date(scrappingToCompareTwo.scrapping_date),
                    DATE_NO_HOUR,
                  )
                  } `}
                </Typography>
              </Typography>
            ))
          || (
            <Typography marginTop={2}>
              Vous visualisez
              <Typography
                variant="bold"
                color={globalTheme.palette.primary.light}
                fontSize="1.1rem"
              >
                {' '}
                la part de voix des différents espaces
                {' '}
              </Typography>
              attribués à l&apos;étude des mots-clés
              <Typography variant="bold">
                {` "${analysis?.label}" `}
              </Typography>
              dans les résultats de
              <Typography variant="bold">
                {` ${searchEngine?.[0]?.toUpperCase()}${searchEngine?.slice(1)} `}
                {deviceTypeId === DEVICE_TYPE.DESKTOP ? ' Desktop ' : ' Mobile '}
              </Typography>
              du
              <Typography variant="bold">
                {` ${convertDate(new Date(periodeBegin || ''), DATE_NO_HOUR)} `}
              </Typography>
              au
              <Typography variant="bold">
                {` ${convertDate(new Date(periodeEnd || ''), DATE_NO_HOUR)} `}
              </Typography>
              .
            </Typography>
          )
          }
          <Button
            variant="contained"
            disabled
            sx={{
              width: 'fit-content',
              alignSelf: 'flex-end',
            }}
          >
            Assistant IA
          </Button>
        </Stack>
      </Stack>
    </MainBox>
  );
}

export default PhysionomySummary;
