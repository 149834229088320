import React from 'react';

import { SelectInputProps } from '@mui/material/Select/SelectInput';
import { StandardCSSProperties } from '@mui/system';

import globalTheme from 'common/theme';
import DropDown from 'components/dropDown/DropDown';

import { RANKING_TYPE_GROUPS } from '../CONSTANTS';

type RankingTypeDropDownDropDownProps = {
  value: number | number[] | '',
  onChange: SelectInputProps<any>['onChange'],
  name?: string,
  multiple?: boolean,
  emptyOption?: string
  size?: 'small' | 'medium',
  margin?: StandardCSSProperties['margin'],
  height?: string,
  width?: string,
  fontSize?: keyof typeof globalTheme.fontSize,
  label?: string,
  className?: {
    select?: string,
  },
  displayEmptyInField?:boolean,
  displayEmptyInList?:boolean,
  disabled?: boolean,
  error?: boolean,
  errorText?: string,
};

function RankingTypeGroupDropDown(props: RankingTypeDropDownDropDownProps) {
  const {
    value,
    onChange,
    name,
    multiple = false,
    emptyOption,
    size,
    margin,
    fontSize,
    height,
    width,
    label,
    className,
    displayEmptyInField,
    displayEmptyInList,
    disabled,
    error,
    errorText,
  } = props;

  return (
    <DropDown
      name={name ?? 'actors'}
      fullWidth
      value={value}
      options={RANKING_TYPE_GROUPS}
      label={label}
      onChange={onChange}
      getOptionLabel={(rtg) => rtg.name}
      getOptionValue={(rtg) => rtg.id}
      multiple={multiple}
      emptyOption={emptyOption}
      size={size}
      fontSize={fontSize}
      margin={margin}
      height={height}
      width={width}
      className={className}
      displayEmptyInField={displayEmptyInField}
      displayEmptyInList={displayEmptyInList}
      disabled={disabled}
      data-testid="select-ranking-type-group-drop-down"
      error={error}
      errorText={errorText}
    />
  );
}

export default RankingTypeGroupDropDown;
