import React, { useRef, useEffect, useState } from 'react';

import CampaignIcon from '@mui/icons-material/Campaign';
import { Button, styled, ButtonProps } from '@mui/material';
import * as Sentry from '@sentry/react';

const PREFIX = 'SentryFeedbackButton';
const classes = {
  core: `${PREFIX}-core`,
};

const StyledButton = styled(Button)<ButtonProps>(({ theme }) => ({
  [`&.${classes.core}`]: {
    fontSize: theme.fontSize.xsmall,
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.light,
    width: 'fit-content',
    position: 'fixed',
    top: theme.spacing(2),
    right: theme.spacing(3),
    zIndex: 100000,
    borderRadius: '15px',
    boxShadow: '5px 5px 5px #00000020',
  },
}));

function SentryFeedbackButton() {
  const [feedback, setFeedback] = useState<any>();
  // Read `getFeedback` on the client only, to avoid hydration errors during server rendering
  useEffect(() => {
    setFeedback(Sentry.getFeedback());
  }, []);

  const buttonRef = useRef<any>();

  useEffect(() => {
    if (feedback) {
      const unsubscribe = feedback.attachTo(buttonRef.current);
      return unsubscribe;
    }
    return () => {};
  }, [feedback]);

  return (
    <StyledButton
      type="button"
      ref={buttonRef}
      id="feedback-button"
      className={classes.core}
      startIcon={<CampaignIcon />}
    >
      Signaler un bug
    </StyledButton>
  );
}

export default SentryFeedbackButton;
