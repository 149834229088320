import TabLinkType from 'components/link/types';
import { Project } from 'features/projects/types';
import { Analysis } from 'features/serpAnalysis/types';
import { generateAnalysisUrl, generateProjectBaseUrl } from 'utils/urlManipulation';

import { keywordsLabel, serpPhysionomyHistoryLabel, serpPhysionomyLabel } from './config';
import {
  SERP_PHYSIONOMY_HISTORY,
  SERP_PHYSIONOMY, KEYWORDS,
  SERP_ANALYSIS,
} from '../ROUTES';

function getPhysionomyNavigation(
  project: Project,
  analysis?: Analysis,
): TabLinkType[] {
  return [
    {
      label: 'Les opportunités de visibilité',
      path: `${
        generateAnalysisUrl(
          project.id,
          project.name,
          analysis?.id,
          analysis?.label,
          serpPhysionomyHistoryLabel,
          undefined,
        )}`,
      pattern: SERP_PHYSIONOMY_HISTORY,
    },
    {
      label: 'Les parts de voix de la concurrence',
      path: `${
        generateAnalysisUrl(
          project.id,
          project.name,
          analysis?.id ?? 0,
          analysis?.label ?? '',
          serpPhysionomyLabel,
          undefined,
        )}`,
      pattern: SERP_PHYSIONOMY,
    },
    {
      label: 'Les mots clés',
      path: `${
        generateAnalysisUrl(
          project.id,
          project.name,
          analysis?.id ?? 0,
          analysis?.label ?? '',
          keywordsLabel,
          undefined,
        )}`,
      pattern: KEYWORDS,
    },
    {
      label: 'La planification de vos analyses',
      path: `${generateProjectBaseUrl(project.id, project.name)}`,
      pattern: SERP_ANALYSIS,
    },
  ];
}

export default getPhysionomyNavigation;
