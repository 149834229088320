import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  Box,
  Grid2,
  Stack,
  Typography,
} from '@mui/material';

import { useAppSelector } from 'common/reduxHooks';
import globalTheme from 'common/theme';
import TabsLink from 'components/link/TabsLink';
import useActors from 'features/actors/state/useActors';
import DatavizContainer from 'features/dataviz/components/generic/DatavizContainer';
import { METRIC } from 'features/dataviz/CONSTANTS';
import datavizConfig from 'features/dataviz/datavizConfig';
import { DatavizFilters } from 'features/dataviz/types';
import { Project } from 'features/projects/types';
import { selectAnalysisById } from 'features/serpAnalysis/state/slice';
import useSerpAnalyses from 'features/serpAnalysis/state/useSerpAnalyses';
import { Analysis } from 'features/serpAnalysis/types';
import PhysionomyFiltersContainer
  from 'features/serpPhysionomy/components/PhysionomyFiltersContainer';
import PhysionomySummary from 'features/serpPhysionomy/components/PhysionomySummary';
import { PhysionomyFiltersForm } from 'features/serpPhysionomy/types';
import { generateAnalysisUrl, splitIdName } from 'utils/urlManipulation';

import getPhysionomyNavigation from './physionomyNavigation';
import { SERP_PHYSIONOMY } from '../ROUTES';
import SerpPhysionomyTitle from './SerpPhysionomyTitle';

type SerpPhysionomyProps = {
  project: Project,
}

const corespondanceRankingType = {
  organic: { feature: undefined },
  featured_snippet: { feature: 'fs' },
  people_also_ask: { feature: 'paa' },
  images: { feature: 'image' },
  video: { feature: 'video' },
  local_pack: { feature: 'local' },
};

const generateFeatureLink = (
  project: Project,
  analysis?: Analysis,
) => Object.keys(corespondanceRankingType).reduce((acc, cur) => {
  acc[cur] = generateAnalysisUrl(
    project.id,
    project.name,
    analysis?.id ?? 0,
    analysis?.label ?? '',
    corespondanceRankingType[cur as keyof typeof corespondanceRankingType].feature,
    undefined,
  );
  return acc;
}, {} as Record<string, string>);

function SerpPhysionomy(props: SerpPhysionomyProps) {
  const { project } = props;

  const { idNameAnalysis } = useParams();
  const { id: idAnalysis } = splitIdName(idNameAnalysis);

  const scopeId = project.scopes[0].id;

  const { isLoading, serpAnalyses: analyses } = useSerpAnalyses({ scopeId });
  const { actors } = useActors({ scopeId });

  const currentAnalysis = useMemo(() => {
    const sortedAnalyses = [...analyses].sort((a, b) => a.label.localeCompare(b.label));

    return idAnalysis || sortedAnalyses[0]?.id || '';
  }, [idAnalysis, analyses]);

  const serpAnalysis = useAppSelector((state) => (
    selectAnalysisById(state.serpAnalyses, (currentAnalysis))));

  const [datavizFilters, setDatavizFilters] = useState<DatavizFilters>({
    deviceTypeId: serpAnalysis?.device_type_id,
    rankMax: '50',
    bestRankMode: false,
    rankMin: '1',
    volMax: '',
    volMin: '',
    type: 'datavizFilters',
    actorIds: [],
  });
  const [showPercentage, setShowPercentage] = useState(false);
  const [showValue, setShowValue] = useState(false);
  const [isInformationsDisabled, setIsInformationsDisabled] = useState(true);
  const [rankingTypeIdsReturned, setRankingTypeIdsReturned] = useState<number[]>([]);
  const [hideRankingTypeIds, setHideRankingTypeIds] = useState<number[]>([]);

  const handleFiltersSubmit = (filters: PhysionomyFiltersForm) => {
    const scrappingIds: number[] = [];

    if (filters.periodeOne) scrappingIds.push(filters.periodeOne);
    if (filters.periodeTwo) scrappingIds.push(filters.periodeTwo);

    setDatavizFilters((prev) => ({
      ...prev,
      rankMax: filters.rankLimit.toString(),
      deviceTypeId: filters.deviceId,
      serpAnalysisScrappingIds: scrappingIds,
      actorIds: filters.actors,
    }));
    setShowPercentage(filters.showPercentage);
    setShowValue(filters.showValues);
  };

  const handleFiltersReset = () => {
    setIsInformationsDisabled(true);
    setHideRankingTypeIds([]);
    setRankingTypeIdsReturned([]);
  };

  const handleFiltersChanged = (filters: PhysionomyFiltersForm) => {
    setShowPercentage(filters.showPercentage);
    setShowValue(filters.showValues);
  };

  const handleDataFetch = (data: any[]) => {
    if (isInformationsDisabled) setIsInformationsDisabled(false);
    setRankingTypeIdsReturned(Array.from(new Set(data.map((a) => a.ranking_type_id))));
  };

  return (
    <Box>
      <Grid2 container columnSpacing={2} size={12}>
        <Grid2 size={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Typography variant="bold" sx={{ fontSize: globalTheme.fontSize.middlesmall }}>
            Que voulez-vous visualiser ?
          </Typography>
        </Grid2>
        <Grid2 size={10}>
          <TabsLink
            links={getPhysionomyNavigation(
              project,
              serpAnalysis,
            )}
            defaultValue={SERP_PHYSIONOMY}
          />
        </Grid2>
        <Grid2 size={2} id="physionomie-filters-container">
          <PhysionomyFiltersContainer
            scopeId={scopeId}
            serpAnalysis={serpAnalysis}
            rankingTypeIdsUsed={rankingTypeIdsReturned}
            hideRankingTypeIds={hideRankingTypeIds}
            setHideRankingTypeIds={setHideRankingTypeIds}
            isLoading={isLoading}
            onSubmit={handleFiltersSubmit}
            onFiltersChanged={handleFiltersChanged}
            onFiltersReset={handleFiltersReset}
            disabledInformations={isInformationsDisabled}
            featureLink={generateFeatureLink(project, serpAnalysis)}
          />
        </Grid2>
        <Grid2 size={10}>
          <Stack sx={{
            height: '100%',
            display: 'flex',
            direction: 'column',
            justifyContent: 'space-between',
          }}
          >
            <DatavizContainer
              datavizComp={datavizConfig[METRIC.count_group_by_ranking_type.label]}
              exportName="share_of_voice_competitors.csv"
              idScope={scopeId}
              periode=""
              title={<SerpPhysionomyTitle />}
              datavizFilters={datavizFilters}
              idSerpAnalysis={serpAnalysis?.id}
              actors={actors.filter((a) => datavizFilters.actorIds?.includes(a.id!))}
              containerAspect={0.9}
              height={
                isInformationsDisabled
                || rankingTypeIdsReturned.length === hideRankingTypeIds.length
                  ? '600px'
                  : '100%'
              }
              showPercentage={showPercentage}
              showValue={showValue}
              hideRankingTypeIds={hideRankingTypeIds}
              onDataFetch={handleDataFetch}
              project={project}
              analysis={serpAnalysis}
            />
            {
              (!isInformationsDisabled
                && rankingTypeIdsReturned.length !== hideRankingTypeIds.length) && (
                  <Stack marginTop={2}>
                    <PhysionomySummary
                      analysis={serpAnalysis}
                      isComparison
                      periodeBegin={datavizFilters.periodeBegin}
                      periodeEnd={datavizFilters.periodeEnd}
                      deviceTypeId={datavizFilters.deviceTypeId}
                      searchEngine={serpAnalysis?.search_engine.name}
                      scrappingIds={datavizFilters.serpAnalysisScrappingIds}
                    />
                  </Stack>
              )
            }
          </Stack>
        </Grid2>
      </Grid2>
    </Box>
  );
}

export default SerpPhysionomy;
