/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RankingTypeType, RankingTypeUpdatePayload } from '../types';

export interface State {
  isLoading: boolean;
  rankingTypes: RankingTypeType[];
  error: string;
}

const initialState: State = {
  isLoading: false,
  rankingTypes: [],
  error: '',
};

export const rankingTypeSlice = createSlice({
  name: 'rankingTypes',
  initialState,
  reducers: {
    fetchRankingTypes: (state: State) => {
      state.isLoading = true;
    },
    fetchRankingTypesSuccess: (state: State, action: PayloadAction<RankingTypeType[]>) => {
      state.rankingTypes = action.payload;
      state.isLoading = false;
    },
    fetchRankingTypesFailure: (state: State, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    updateRankingType: (state: State, action: PayloadAction<RankingTypeUpdatePayload>) => {
      state.isLoading = true;
    },
    updateRankingTypeSuccess: (state: State, action: PayloadAction<RankingTypeType>) => {
      const rankingTypeIndex = state.rankingTypes.findIndex((rt) => rt.id === action.payload.id);
      if (rankingTypeIndex >= 0) {
        state.rankingTypes[rankingTypeIndex] = action.payload;
      }

      state.isLoading = false;
    },
    updateRankingTypeFailure: (state: State, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchRankingTypes,
  fetchRankingTypesSuccess,
  fetchRankingTypesFailure,
  updateRankingType,
  updateRankingTypeSuccess,
  updateRankingTypeFailure,
} = rankingTypeSlice.actions;

export default rankingTypeSlice.reducer;
