import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import {
  call,
  put,
  takeEvery,
} from 'redux-saga/effects';

import ApiRankingTypes from './api';
import {
  fetchRankingTypes,
  fetchRankingTypesSuccess,
  fetchRankingTypesFailure,
  updateRankingType,
  updateRankingTypeSuccess,
  updateRankingTypeFailure,
} from './slice';
import { RankingTypeType, RankingTypeUpdatePayload } from '../types';

function* workFetchRankingTypes() {
  try {
    const response: AxiosResponse = yield call(ApiRankingTypes.fetchRankingTypes);
    const rankingTypes: RankingTypeType[] = response.data;
    yield put(fetchRankingTypesSuccess(rankingTypes));
  } catch (e: any) {
    yield put(fetchRankingTypesFailure(e.message));
  }
}
function* workUpdateRankingTypes(action: PayloadAction<RankingTypeUpdatePayload>) {
  try {
    const response: AxiosResponse<RankingTypeType> = yield call(
      ApiRankingTypes.updateRankingTypes,
      action.payload,
    );
    const rankingTypes = response.data;
    yield put(updateRankingTypeSuccess(rankingTypes));
  } catch (e: any) {
    yield put(updateRankingTypeFailure(e.message));
  }
}

export function* sagaFetchRankingTypes() {
  yield takeEvery(fetchRankingTypes, workFetchRankingTypes);
}

export function* sagaUpdateRankingTypes() {
  yield takeEvery(updateRankingType, workUpdateRankingTypes);
}
