import React from 'react';

import PrivateRoute from 'components/privateRoute/PrivateRoute';
import { CustomRouteObject } from 'pages/projects/types';

import ManageProjects from './projectAdmin/ManageProjects';
import ManageRankingType from './rankingTypeAdmin/ManageRankingType';
import {
  USER_ADMIN,
  EXTERNAL_USERS,
  MANAGE_PROJECTS,
  MANAGE_RANKING_TYPE,
} from './ROUTES';
import ExternalUsers from './userAdmin/ExternalUsers';
import UserAdmin from './userAdmin/UserAdmin';

const routes: CustomRouteObject[] = [
  {
    path: USER_ADMIN,
    element: <PrivateRoute element={<UserAdmin />} />,
    adminOnly: true,
  },
  {
    path: EXTERNAL_USERS,
    element: <PrivateRoute element={<ExternalUsers />} />,
    adminOnly: true,
    dircliOnly: true,
  },
  {
    path: MANAGE_PROJECTS,
    element: <PrivateRoute element={<ManageProjects />} />,
    adminOnly: true,
  },
  {
    path: MANAGE_RANKING_TYPE,
    element: <PrivateRoute element={<ManageRankingType />} />,
    adminOnly: true,
  },
];

export default routes;
