import React, { useState } from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Box, BoxProps, Button, Menu, styled,
} from '@mui/material';

import { RankingTypeType } from '../types';
import EditRankingType from './EditRankingType';

const PREFIX = 'ActorsDataGridActions';

const classes = {
  dropdown: `${PREFIX}-dropdown`,
  button: `${PREFIX}-button`,
};

const StyledBox = styled(Box)<BoxProps>(({ theme }) => ({
  [`& .${classes.dropdown}`]: {
    fontSize: theme.fontSize.xlarge,
  },

  [`& .${classes.button}`]: {
    color: '#737476',
    fontSize: theme.fontSize.medium,
    height: '30px',
    width: '115px',
    fontWeight: 'normal',
    display: 'flex',
    justifyContent: 'space-between',
    '&:hover': {
      color: '#737476',
      backgroundColor: '#e8e8e8',
    },
  },
}));

type RankingTypeDataGridActionsType = {
  rankingType: RankingTypeType
}

function RankingTypeDataGridActions(props: RankingTypeDataGridActionsType) {
  const { rankingType } = props;
  const [anchorMenu, setAnchorMenu] = useState(null);
  const openMenu = Boolean(anchorMenu);

  const handleOpenMenu = (event: any) => {
    setAnchorMenu(event.target);
  };
  const handleCloseMenu = () => {
    setAnchorMenu(null);
  };

  return (
    <StyledBox>
      <Button
        variant="contained"
        disableElevation
        onClick={handleOpenMenu}
        endIcon={<KeyboardArrowDownIcon className={classes.dropdown} />}
        size="small"
        className={classes.button}
        color="info"
        data-testid="ranking-type-datagrid-action-button"
      >
        Actions
      </Button>
      <Menu
        anchorEl={anchorMenu}
        open={openMenu}
        onClose={handleCloseMenu}
        id="menuActionsRankingType"
        data-testid="ranking-type-datagrid-action-menu"
        keepMounted
      >
        <EditRankingType
          rankingType={rankingType}
          handleCloseMenu={handleCloseMenu}
        />
      </Menu>
    </StyledBox>
  );
}

export default RankingTypeDataGridActions;
