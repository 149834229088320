import * as yup from 'yup';

import { UserType, CreateModifyExternalUserForm } from '../types';
import UsersUtils from '../UsersUtils';

export const initValuesCreate: CreateModifyExternalUserForm = {
  email: '',
  password: '',
  projectsEdit: [''],
  projectsConsult: [''],
};

export const initValuesModify = (user: UserType): CreateModifyExternalUserForm => ({
  email: user.email,
  password: '',
});

const hasValidValue = (arr: any[]) => (
  (arr.length === 1 && arr[0])
  || arr.length > 1
);

const validateProjectsFieldsHasValue = (projectsConsult: any[], projectsEdit: any[]) => {
  // At least one field must have something in it other than an empty string
  const editFieldIsValid = hasValidValue(projectsEdit);
  const consultFieldIsValid = hasValidValue(projectsConsult);

  return editFieldIsValid || consultFieldIsValid;
};

const validateProjectsFieldsHasDifferentValue = (projectsConsult: any[], projectsEdit: any[]) => {
  // a value cannot be in both fields

  const hasSharedValue = projectsConsult.some((projectId) => projectsEdit.includes(projectId));

  return !hasSharedValue;
};

export const validationSchemaCreateExternal = (userList: UserType[]) => yup.object().shape({
  email: yup.string().trim().required('Ce champ est obligatoire')
    .email('L\'email doit être une adresse email valide')
    .test(
      'no-duplicate-email',
      'Ce compte existe déjà',
      (value) => {
        const existingUser = userList.find((user) => user.email === value);
        return existingUser === undefined;
      },
    )
    .test(
      'no-pp-user',
      'Demandez à un administrateur pour créer un compte Première Place.',
      (value) => !UsersUtils.isUserPP(value),
    ),
  password: yup.string().trim()
    .min(8, 'Le mot de passe doit contenir au minimum 8 caractères')
    .max(60, 'Le mot de passe ne doit pas contenir plus de 60 caractères')
    .required('Ce champ est obligatoire'),
  projectsEdit: yup.array().test(
    'min-projects-edit',
    ' ',
    (code, context) => {
      const { projectsConsult } = context.parent;
      if (!code) return false;

      return validateProjectsFieldsHasValue(projectsConsult, code);
    },
  ).test(
    'fields-share-value',
    ' ',
    (code, context) => {
      const { projectsConsult } = context.parent;
      if (!code) return false;
      return validateProjectsFieldsHasDifferentValue(projectsConsult, code);
    },
  ),
  projectsConsult: yup.array().test(
    'min-projects-consult',
    'L\'utilisateur doit être relié à au moins un projet (consultable ou modifiable)',
    (code, context) => {
      const { projectsEdit } = context.parent;
      if (!code) return false;

      return validateProjectsFieldsHasValue(code, projectsEdit);
    },
  ).test(
    'fields-share-value',
    'Un projet ne peut pas être en même temps modifiable et consultable',
    (code, context) => {
      const { projectsEdit } = context.parent;

      if (!code) return false;

      return validateProjectsFieldsHasDifferentValue(code, projectsEdit);
    },
  ),
});

export const validationSchemaCreatePP = (
  userList: UserType[],
) => yup.object().shape({
  email: yup.string().trim().required('Ce champ est obligatoire')
    .email('L\'email doit être une adresse email valide')
    .test(
      'no-duplicate-email',
      'Ce compte existe déjà',
      (value) => {
        const existingUser = userList.find((user) => user.email === value);
        return existingUser === undefined;
      },
    )
    .test(
      'is-pp-user',
      'Veuillez entrer un compte Première Place valide',
      (value) => UsersUtils.isUserPP(value),
    ),
  password: yup.string().trim()
    .min(8, 'Le mot de passe doit contenir au minimum 8 caractères')
    .max(60, 'Le mot de passe ne doit pas contenir plus de 60 caractères')
    .required('Ce champ est obligatoire'),
});

export const validationSchemaModify = yup.object().shape({
  email: yup.string().trim()
    .required('Ce champ est obligatoire')
    .email('L\'email doit être une adresse email valide'),
  password: yup.string()
    .min(8, 'Le mot de passe doit contenir au minimum 8 caractères')
    .max(60, 'Le mot de passe ne doit pas contenir plus de 60 caractères'),
});
