import React, { useState } from 'react';

import { Box, Typography, MenuItem } from '@mui/material';

import { useAppDispatch } from 'common/reduxHooks';
import globalTheme from 'common/theme';
import Modal from 'components/modal/Modal';
import { reactivateProject } from 'features/projects/state/slice';

type ReactivateProjectProps = {
  id: number,
  handleCloseMenu: Function,
}

function ReactivateProject (props: ReactivateProjectProps) {
  const { id, handleCloseMenu } = props;
  const dispatch = useAppDispatch();
  const [openModal, setOpenModal] = useState<boolean>(false);

  const handleConfirm = () => {
    dispatch(reactivateProject(id));
    setOpenModal(false);
  };

  return (
    <Box>
      <MenuItem
        sx={{ fontSize: globalTheme.fontSize.middlesmall }}
        onClick={
          () => { if (!openModal) setOpenModal(true); handleCloseMenu(); }
        }
      >
        Désarchiver
      </MenuItem>
      <Modal
        title={(
          <Typography data-testid="modal-reactivate-project">
            <Typography component="span" variant="bold">Désarchiver</Typography>
            {' '}
            ce projet
          </Typography>
        )}
        actionConfirm={handleConfirm}
        actionCancel={() => setOpenModal(false)}
        isOpen={openModal}
      />
    </Box>
  );
}

export default ReactivateProject;
