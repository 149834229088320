import React from 'react';

import { SelectInputProps } from '@mui/material/Select/SelectInput';

import globalTheme from 'common/theme';
import DropDown from 'components/dropDown/DropDown';

type SearchEngineDropDownProps = {
  value: number,
  onChange: SelectInputProps<any>['onChange'],
  name?: string,
  size?: 'small' | 'medium',
  fontSize?: keyof typeof globalTheme.fontSize,
  label?: string,
  disabled?: boolean,
  options: {
    label: string,
    id: number
  }[]
}

function DeviceDropDown(props: SearchEngineDropDownProps) {
  const {
    value,
    onChange,
    name,
    size,
    fontSize,
    label = 'Appareil',
    disabled = false,
    options,
  } = props;

  return (
    <DropDown
      size={size}
      fontSize={fontSize}
      label={label}
      name={name}
      onChange={onChange}
      options={options}
      getOptionLabel={(se) => se.label}
      getOptionValue={(se) => se.id}
      value={value}
      disabled={disabled}
    />
  );
}

export default DeviceDropDown;
