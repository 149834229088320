import React from 'react';
import { Provider } from 'react-redux';

// eslint-disable-next-line import/order
import theme from 'common/theme';

import { CssBaseline } from '@mui/material';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import * as Sentry from '@sentry/react';
import { createRoot } from 'react-dom/client';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import App from './App';
import { axiosAuthInstance, setUpAxiosAuthInstance } from './common/axiosAuthInstance';
import { setupStore } from './common/store';
import reportWebVitals from './reportWebVitals';
import './index.css';

import 'moment/dist/locale/fr';

// If we are on the production or recette environnement, init sentry
if (['production', 'recette'].includes(import.meta.env.VITE_APP_ENVIRONMENT)) {
  Sentry.init({
    // eslint-disable-next-line max-len
    dsn: import.meta.env.VITE_SENTRY_DNS,
    environment: import.meta.env.VITE_APP_ENVIRONMENT ?? 'dev',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
      Sentry.feedbackIntegration({
        colorScheme: 'system',
        // localization
        autoInject: false,
        triggerLabel: 'Signaler un bug',
        triggerAriaLabel: 'Signaler un bug',
        formTitle: 'Signaler un bug',
        submitButtonLabel: 'Envoyer le rapport de bug',
        cancelButtonLabel: 'Annuler',
        confirmButtonLabel: 'Confirmer',
        addScreenshotButtonLabel: 'Ajouter une capture d\'écran',
        removeScreenshotButtonLabel: 'Supprimer la capture d\'écran',
        nameLabel: 'Nom',
        namePlaceholder: 'Votre nom',
        emailLabel: 'Email',
        emailPlaceholder: 'votre.email@exemple.org',
        isRequiredLabel: '(obligatoire)',
        messageLabel: 'Description',
        messagePlaceholder: 'Quel est le bug ? Que vous attendiez-vous à voir ?',
        successMessageText: 'Merci pour votre rapport !',
      }),
    ],
    attachStacktrace: true,
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: import.meta.env.VITE_SENTRY_PROPAGATION_TARGET
      ? [import.meta.env.VITE_SENTRY_PROPAGATION_TARGET]
      : undefined,
    // Session Replay
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
  });
}

const container = document.getElementById('root')!;
const root = createRoot(container);
const store = setupStore();
setUpAxiosAuthInstance(store, axiosAuthInstance);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Provider store={store}>
        <App />
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
