import React from 'react';

import {
  Button,
  CircularProgress,
  SelectChangeEvent,
  Stack,
  styled,
} from '@mui/material';

import DropDown from 'components/dropDown/DropDown';
import MainBox from 'components/layout/MainBox';
import ActorsDropDown from 'features/actors/components/ActorsDropDown';
import TitleComponent from 'features/dataviz/components/generic/charts/common/Title';
import SelectScrapping from 'features/serpAnalysisScrapping/components/SelectScrapping';
import useSerpAnalysisScrappings
  from 'features/serpAnalysisScrapping/state/useSerpAnalysisScrappings';
import { sortScrappingsByDate } from 'features/serpAnalysisScrapping/utils';

import organicLimit from '../CONSTANT';
import { PhysionomyFiltersForm } from '../types';
import getScrappingPeriodeExclude from '../utils';

const PREFIX = 'physionomy-filter-container';

const classes = {
  filterButton: `${PREFIX}-filterButton`,
  cancelButton: `${PREFIX}-cancelButton`,
};
const StyledStack = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'isFilter',
})<{ isFilter: boolean }>(({ theme, isFilter }) => ({
  [`& .${classes.filterButton}`]: {
    backgroundColor: isFilter ? theme.palette.secondary.main : theme.palette.primary.light,
    color: isFilter ? theme.palette.primary.light : 'white',
    '&:hover': {
      color: theme.palette.primary.light,
      backgroundColor: theme.palette.secondary.main,
    },
    '&:disabled': {
      backgroundColor: '#E0E0E0',
      color: '#A6A7AB',
    },
  },

  [`& .${classes.cancelButton}`]: {
    color: theme.palette.primary.light,
  },
}));

type PhysionomyFiltersProps = {
  scopeId: number,
  values: PhysionomyFiltersForm,
  onChange: (e: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<string>) => void,
  onSubmit: VoidFunction,
  isLoading: boolean,
  serpAnalysisId: number,
  resetQuery: VoidFunction,
  isFilter?: boolean,
}

const title = 'Paramètres du graphique';

function PhysionomyFilters(props: PhysionomyFiltersProps) {
  const {
    scopeId,
    values,
    onChange,
    onSubmit,
    isLoading,
    serpAnalysisId,
    resetQuery,
    isFilter = false,
  } = props;

  const { scrappings } = useSerpAnalysisScrappings(serpAnalysisId);

  if (isLoading) {
    return (
      <MainBox BoxProps={{ justifyItems: 'center' }}>
        <TitleComponent title={title} capitalLetter />
        <CircularProgress data-testid="physionomy-filters-spinner" />
      </MainBox>
    );
  }

  const sortedScrappings = sortScrappingsByDate(scrappings);

  const scrappingPeriodeOne = getScrappingPeriodeExclude(sortedScrappings, values.periodeTwo);
  const scrappingPeriodeTwo = getScrappingPeriodeExclude(sortedScrappings, values.periodeOne);

  return (
    <MainBox>
      <TitleComponent
        title={title}
        capitalLetter
        direction="column"
      />
      <Stack
        direction="column"
        spacing={2}
        my={2}
        data-testid="physionomy-filters-form"
      >
        <ActorsDropDown
          name="actors"
          scopeId={scopeId}
          value={values.actors}
          onChange={onChange}
          multiple
          size="small"
          fontSize="xsmall"
          label="Acteurs"
          disabled={!serpAnalysisId || !scrappings.length}
        />
        <SelectScrapping
          value={values.periodeOne ?? ''}
          onChange={onChange}
          name="periodeOne"
          size="small"
          fontSize="xsmall"
          scrappings={scrappingPeriodeOne}
          emptyOption="(aucune)"
          displayEmptyInList
          variant="outlined"
          fullWidth
          showDevice
          label="Analyse N°1"
          data-testid="select-scrapping-physionomy-1"
          disabled={!serpAnalysisId || !scrappings.length}
        />
        <SelectScrapping
          value={values.periodeTwo ?? ''}
          onChange={onChange}
          name="periodeTwo"
          size="small"
          fontSize="xsmall"
          scrappings={scrappingPeriodeTwo}
          emptyOption="(aucune)"
          displayEmptyInList
          variant="outlined"
          fullWidth
          showDevice
          label="Analyse N°2"
          data-testid="select-scrapping-physionomy-2"
          disabled={!serpAnalysisId || !scrappings.length}
        />
        <DropDown
          onChange={onChange}
          options={organicLimit}
          value={values.rankLimit}
          name="rankLimit"
          size="small"
          fontSize="xsmall"
          label="Limite organique"
          disabled={!serpAnalysisId || !scrappings.length}
        />
      </Stack>
      <StyledStack
        justifyContent="flex-end"
        alignItems="center"
        direction="row"
        spacing={2}
        isFilter={isFilter}
      >
        {isFilter && (
          <Button
            size="small"
            className={classes.cancelButton}
            onClick={resetQuery}
          >
            Réinitialiser
          </Button>
        )}
        <Button
          onClick={onSubmit}
          size="small"
          variant="contained"
          className={classes.filterButton}
          disabled={!serpAnalysisId || !scrappings.length}
        >
          Analyser
        </Button>
      </StyledStack>
    </MainBox>
  );
}

export default PhysionomyFilters;
