import React from 'react';

import CircleIcon from '@mui/icons-material/Circle';
import { Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';

import globalTheme from 'common/theme';
import StyledHeader from 'features/dataviz/components/generic/datagrid/text/HeaderDataGrid';

import RankingTypeDataGridActions from '../actions/RankingTypeDataGridActions';
import { RankingTypeType } from '../types';

const textStyle = { fontSize: globalTheme.fontSize.medium };

const createRankingTypeColumns = (): GridColDef<RankingTypeType>[] => ([
  {
    field: 'name',
    renderHeader: () => (
      <StyledHeader label="Nom" />
    ),
    renderCell: (params) => (
      <Typography sx={textStyle}>{params.value}</Typography>
    ),
    flex: 2,
    sortable: true,
  },
  {
    field: 'custom_name',
    renderHeader: () => (
      <StyledHeader label="Nom personnalisé" />
    ),
    renderCell: (params) => (
      <Typography sx={textStyle}>{params.value}</Typography>
    ),
    flex: 2,
    sortable: true,
  },
  {
    field: 'description',
    renderHeader: () => (
      <StyledHeader label="Description" />
    ),
    renderCell: (params) => (
      <Typography sx={textStyle}>{params.value}</Typography>
    ),
    flex: 4,
    sortable: true,
  },
  {
    field: 'dataviz_color',
    renderHeader: () => (
      <StyledHeader label="Couleur" />
    ),
    renderCell: (params) => (
      <CircleIcon
        sx={{
          color: params.value ?? '#000000',
        }}
      />
    ),
    align: 'center',
    headerAlign: 'center',
    sortable: false,
    width: 100,
  },
  {
    field: 'ranking_type_group',
    renderHeader: () => (
      <StyledHeader label="Type d’encart" />
    ),
    renderCell: (params) => (
      <Typography sx={textStyle}>{params.value}</Typography>
    ),
    valueGetter: (value, row) => row.ranking_type_group?.name ?? '',
    flex: 1,
    sortable: true,
    minWidth: 150,
  },
  {
    field: 'actions',
    headerName: '',
    sortable: false,
    align: 'right',
    renderCell: (params) => (
      <RankingTypeDataGridActions rankingType={params.row} />
    ),
    width: 130,
  },
]);

export default createRankingTypeColumns;
