import React, { useMemo } from 'react';

import FileCopyIcon from '@mui/icons-material/FileCopy';
import {
  CircularProgress,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';

import globalTheme from 'common/theme';
import MainBox from 'components/layout/MainBox';
import { ChartComponentProps } from 'features/dataviz/types';
import { formatByRankingTypeAndPeriodsDatas } from 'features/dataviz/utils/formatData';
import useRankingTypes from 'features/rankingTypes/state/useRankingTypes';

import EmptyGroupByTypeAndPeriods from './EmptyGroupByTypeAndPeriods';
import BarChartComponent from '../generic/charts/BarChart';
import TitleComponent from '../generic/charts/common/Title';

function GroupByRankingTypeAndPeriods(props: ChartComponentProps) {
  const {
    datas,
    isPageLoading,
    datavizFilters,
    showPercentage,
    showValue,
    hideRankingTypeIds,
    hideEmptyDays,
    isLoading,
    title,
    analysis,
    project,
    handleExport,
    isExportLoading,
  } = props;
  const filters = datavizFilters?.type === 'datavizFilters' ? datavizFilters : undefined;
  const { rankingTypes } = useRankingTypes();

  const baseDataKeys = rankingTypes.map((rt) => ({
    dataKey: rt.name,
    tooltipLabel: rt.custom_name,
    color: rt.dataviz_color,
    stackId: 1,
  }));

  const formatedDatas = useMemo(
    () => formatByRankingTypeAndPeriodsDatas(
      datas.data.values,
      baseDataKeys,
      hideRankingTypeIds,
      hideEmptyDays,
    ),
    [datas.data.values, hideRankingTypeIds, hideEmptyDays],
  );

  const filteredDataKeys = useMemo(
    () => {
      const flatDatasKeys = formatedDatas.map((a) => Object.keys(a)).flat();
      return baseDataKeys.filter((dataKey) => flatDatasKeys.includes(dataKey.dataKey));
    },
    [formatedDatas, baseDataKeys],
  );

  if (isLoading) {
    return (
      <MainBox BoxProps={{ textAlign: 'center', alignContent: 'center', height: '100%' }}>
        <Stack
          direction="column"
          alignItems="center"
        >
          <CircularProgress data-testid="physionomy-group-by-ranking-type-periods-spinner" />
          <Typography variant="bold" sx={{ fontSize: globalTheme.fontSize.middlesmall }}>
            Analyse en cours ...
          </Typography>
        </Stack>
      </MainBox>
    );
  }

  if (
    !filters
    || !datas.data.values
    || !datas.data.values.length
    || !formatedDatas.length
    || filteredDataKeys.length === 0
  ) {
    return (
      <EmptyGroupByTypeAndPeriods
        title={title}
        analysisName={analysis?.label}
        project={project}
        dataKeysCount={filteredDataKeys.length}
        hasData={formatedDatas.length > 0}
      />
    );
  }

  return (
    <MainBox
      data-testid="group-result-main-box"
      BoxProps={{
        alignItems: 'center',
        justifyContent: 'center',
        justifyItems: 'center',
        flex: 1,
      }}
    >
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        position="relative"
        marginBottom=".5rem"
        width="100%"
      >
        <TitleComponent title={title} />
        <IconButton
          sx={{
            color: globalTheme.palette.primary.light,
            position: 'absolute',
            right: 0,
          }}
          onClick={handleExport}
          disabled={isExportLoading}
          size="medium"
          data-testid="export-button"
        >
          {isExportLoading
            ? <CircularProgress size="24px" data-testid="export-spinner" />
            : (
              <Tooltip title="Exporter les données" arrow>
                <FileCopyIcon fontSize="inherit" />
              </Tooltip>
            )}
        </IconButton>
      </Stack>
      <BarChartComponent
        xAxisDatakey="periode"
        dataKeys={filteredDataKeys}
        datas={formatedDatas}
        isPageLoading={isPageLoading}
        title=""
        centerLabelName={showValue}
        percentLabel={showPercentage}
        showLegend
        withTooltip
        size="mediumbig"
      />
    </MainBox>
  );
}

export default GroupByRankingTypeAndPeriods;
