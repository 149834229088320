import { useEffect } from 'react';

import { useAppSelector, useAppDispatch } from 'common/reduxHooks';
import { RootState } from 'common/store';

import { fetchAnalysisScrapping } from './slice';

const useSerpAnalysisScrappings = (idAnalysis: number) => {
  const dispatch = useAppDispatch();

  const { isLoading, error, scrappings } = useAppSelector(
    (state: RootState) => state.serpAnalysisScrapping,
  );

  useEffect(() => {
    if (!isLoading && idAnalysis
      && scrappings.find((scrapping) => scrapping.serp_analysis_id === idAnalysis) === undefined) {
      dispatch(fetchAnalysisScrapping(idAnalysis));
    }
  }, [dispatch, fetchAnalysisScrapping, idAnalysis]);

  return {
    isLoading,
    error,
    scrappings,
  };
};

export default useSerpAnalysisScrappings;
