import React from 'react';

import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import DashboardIcon from '@mui/icons-material/Dashboard';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import GoogleIcon from '@mui/icons-material/Google';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PieChartIcon from '@mui/icons-material/PieChart';
import SettingsIcon from '@mui/icons-material/Settings';
import VisibilityIcon from '@mui/icons-material/Visibility';

import {
  featuredSnippetLabel,
  featuredSnippetName,
  peopleAlsoAskLabel,
  peopleAlsoAskName,
  organicName,
  localPackLabel,
  localPackName,
  videoLabel,
  videoName,
  imageLabel,
  imageName,
  rankingName,
  keywordsName,
  rankingLabel,
  keywordsLabel,
  ShareOfVoiceName,
  ShareOfVoiceLabel,
} from 'pages/projects/serpDetails/config';
import {
  generateAnalysisUrl,
  generatePerformanceUrl,
  generateProjectBaseUrl,
} from 'utils/urlManipulation';

import { ProjectMenuType, SubMenuList } from './types';

const iconWidth = 20;

const projectsMenu: ProjectMenuType = (
  projectId,
  projectName,
  analysis,
  query,
  currentPage,
) => {
  const analysisId = analysis?.id || '';
  const analysisName = analysis?.label || '';
  const newQuery = ['ranking', 'compare', 'results'].includes(currentPage) ? undefined : query;

  const projectBaseUrl = generateProjectBaseUrl(projectId, projectName);
  let rightDrawerMenu: SubMenuList[] = [];

  if (!analysis) {
    rightDrawerMenu = [
      {
        name: 'Physionomie des résultats',
        icon: <DashboardIcon sx={{ width: iconWidth }} />,
        link: `${generateAnalysisUrl(
          projectId,
          projectName,
          undefined,
          undefined,
          'results',
          undefined,
        )}`,
        subMenu: [],
      },
      {
        name: 'Visibilité globale',
        icon: <VisibilityIcon sx={{ width: iconWidth }} />,
        link: '',
        subMenu: [
          {
            name: 'Visibilité par ACTEUR',
            link: generatePerformanceUrl(projectId, projectName, newQuery, currentPage),
          },
          {
            name: 'Visibilité par URL',
            link: generatePerformanceUrl(projectId, projectName, newQuery, currentPage, 'by-url'),
          },
          {
            name: 'Visibilité par SERP',
            link: generatePerformanceUrl(projectId, projectName, newQuery, currentPage, 'by-serp'),
          },
          {
            name: 'Indexabilité des pages',
            link: generatePerformanceUrl(
              projectId,
              projectName,
              newQuery,
              currentPage,
              'url-indexability',
            ),
            externalBlocked: true,
          },
          {
            name: 'Looker Studio',
            link: generatePerformanceUrl(projectId, projectName, newQuery, currentPage, 'kpi'),
            externalBlocked: true,
          },
        ],
      },
      {
        name: '',
        subMenu: [],
        externalBlocked: true,
      },
      {
        name: 'Domaines suivis',
        icon: <PeopleAltIcon sx={{ width: iconWidth }} />,
        link: `${projectBaseUrl}setting/competitor`,
        subMenu: [],
        externalBlocked: true,
      },
      {
        name: 'Planification',
        icon: <AccessAlarmsIcon sx={{ width: iconWidth }} />,
        link: projectBaseUrl,
        subMenu: [],
      },
      {
        name: '',
        subMenu: [],
        externalBlocked: true,
      },
      {
        name: 'Paramètres',
        icon: <SettingsIcon sx={{ width: iconWidth }} />,
        link: `${projectBaseUrl}setting`,
        externalBlocked: true,
        subMenu: [
          {
            name: 'Importer des fichiers',
            link: `${projectBaseUrl}setting/upload`,
            externalBlocked: true,
          },
          {
            name: 'Catégoriser les URL',
            link: `${projectBaseUrl}setting/url`,
            includeSubLink: true,
            externalBlocked: true,
          },
          {
            name: 'Mesurer la notoriété',
            link: `${projectBaseUrl}setting/brand`,
            externalBlocked: true,
          },
          {
            name: 'Activer des données',
            link: `${projectBaseUrl}setting/option`,
            externalBlocked: true,
          },
        ],
      },
    ];

    return rightDrawerMenu;
  }
  rightDrawerMenu = [
    {
      name: 'Physionomie des résultats',
      icon: <DashboardIcon sx={{ width: iconWidth }} />,
      link: `${generateAnalysisUrl(
        projectId,
        projectName,
        analysisId,
        analysisName,
        'results',
        undefined,
      )}`,
      subMenu: [],
    },
    {
      name: 'Veille concurrentielle',
      icon: <GoogleIcon sx={{ width: iconWidth }} />,
      link: '',
      stayOpen: true,
      subMenu: [
        {
          name: organicName,
          link: `${
            generateAnalysisUrl(
              projectId,
              projectName,
              analysisId,
              analysisName,
              '',
              newQuery,
            )
          }`,
        },
        {
          name: featuredSnippetName,
          link: `${
            generateAnalysisUrl(
              projectId,
              projectName,
              analysisId,
              analysisName,
              featuredSnippetLabel,
              newQuery,
            )
          }`,
        },
        {
          name: peopleAlsoAskName,
          link: `${
            generateAnalysisUrl(
              projectId,
              projectName,
              analysisId,
              analysisName,
              peopleAlsoAskLabel,
              newQuery,
            )
          }`,
        },
        {
          name: imageName,
          link: `${
            generateAnalysisUrl(
              projectId,
              projectName,
              analysisId,
              analysisName,
              imageLabel,
              newQuery,
            )
          }`,
        },
        {
          name: videoName,
          link: `${
            generateAnalysisUrl(
              projectId,
              projectName,
              analysisId,
              analysisName,
              videoLabel,
              newQuery,
            )
          }`,
        },
        {
          name: localPackName,
          link: `${
            generateAnalysisUrl(
              projectId,
              projectName,
              analysisId,
              analysisName,
              localPackLabel,
              newQuery,
            )
          }`,
        },
      ],
    },
    {
      name: rankingName,
      icon: <AutoGraphIcon sx={{ width: iconWidth }} />,
      link: `${
        generateAnalysisUrl(
          projectId,
          projectName,
          analysisId,
          analysisName,
          rankingLabel,
          currentPage === 'ranking' ? query : undefined,
        )
      }`,
      subMenu: [],
    },
    {
      name: ShareOfVoiceName,
      icon: <PieChartIcon sx={{ width: iconWidth }} />,
      link: `${
        generateAnalysisUrl(
          projectId,
          projectName,
          analysisId,
          analysisName,
          ShareOfVoiceLabel,
          undefined,
        )
      }`,
      subMenu: [],
      externalBlocked: true,
    },
    {
      name: 'Visibilité globale',
      icon: <VisibilityIcon sx={{ width: iconWidth }} />,
      link: '',
      subMenu: [
        {
          name: 'Visibilité par ACTEUR',
          link: generatePerformanceUrl(projectId, projectName, newQuery, currentPage),
        },
        {
          name: 'Visibilité par URL',
          link: generatePerformanceUrl(projectId, projectName, newQuery, currentPage, 'by-url'),
        },
        {
          name: 'Visibilité par SERP',
          link: generatePerformanceUrl(projectId, projectName, newQuery, currentPage, 'by-serp'),
        },
        {
          name: 'Indexabilité des pages',
          link: generatePerformanceUrl(
            projectId,
            projectName,
            newQuery,
            currentPage,
            'url-indexability',
          ),
          externalBlocked: true,
        },
        {
          name: 'Looker Studio',
          link: generatePerformanceUrl(projectId, projectName, newQuery, currentPage, 'kpi'),
          externalBlocked: true,
        },

      ],
    },
    {
      name: '',
      subMenu: [],
    },
    {
      name: 'Domaines suivis',
      icon: <PeopleAltIcon sx={{ width: iconWidth }} />,
      link: `${projectBaseUrl}setting/competitor`,
      subMenu: [],
    },
    {
      name: keywordsName,
      icon: <FormatListBulletedIcon sx={{ width: iconWidth }} />,
      link: `${
        generateAnalysisUrl(
          projectId,
          projectName,
          analysisId,
          analysisName,
          keywordsLabel,
          newQuery,
        )
      }`,
      subMenu: [],
    },
    {
      name: 'Planification',
      icon: <AccessAlarmsIcon sx={{ width: iconWidth }} />,
      link: projectBaseUrl,
      subMenu: [],
    },
    {
      name: '',
      subMenu: [],
      externalBlocked: true,
    },
    {
      name: 'Paramètres',
      icon: <SettingsIcon sx={{ width: iconWidth }} />,
      link: `${projectBaseUrl}setting`,
      externalBlocked: true,
      subMenu: [
        {
          name: 'Importer des fichiers',
          link: `${projectBaseUrl}setting/upload`,
          externalBlocked: true,
        },
        {
          name: 'Catégoriser les URL',
          link: `${projectBaseUrl}setting/url`,
          includeSubLink: true,
          externalBlocked: true,
        },
        {
          name: 'Mesurer la notoriété',
          link: `${projectBaseUrl}setting/brand`,
          externalBlocked: true,
        },
        {
          name: 'Activer des données',
          link: `${projectBaseUrl}setting/option`,
          externalBlocked: true,
        },
      ],
    },
  ];

  return rightDrawerMenu;
};

export { projectsMenu, iconWidth };
