import React from 'react';

import DownloadIcon from '@mui/icons-material/Download';
import {
  Box, Button, BoxProps, styled, CircularProgress,
} from '@mui/material';

import { useAppDispatch, useAppSelector } from 'common/reduxHooks';
import smallSpinnerSize from 'components/loading/config';
import { BASE_DATAVIZ_REQUEST } from 'features/dataviz/CONSTANTS';
import { fetchExportData } from 'features/dataviz/state/slice';
import { DatavizComp, DatavizRequest } from 'features/dataviz/types';
import generateChartKey from 'features/dataviz/utils/chartKey';
import { ANALYSIS_STATUS } from 'features/serpAnalysis/CONSTANT';
import { Analysis } from 'features/serpAnalysis/types';
import formatProjectNameForUrl from 'utils/formatProjectNameForUrl';

const PREFIX = 'ExportKwButton';

const classes = {
  button: `${PREFIX}-button`,
  spinner: `${PREFIX}-spinner`,
  spinning: `${PREFIX}-spinning`,
};

const StyledBox = styled(Box)<BoxProps>(({ theme }) => ({
  [`& .${classes.button}`]: {
    color: theme.palette.primary.light,
    borderColor: theme.palette.primary.light,
  },

  [`& .${classes.spinner}`]: {
    color: theme.palette.primary.light,
  },

  [`& .${classes.spinning}`]: {
    backgroundColor: theme.palette.secondary.main,
    cursor: 'default',
  },

}));

type ExportKwPropsProps = {
  datavizComp: DatavizComp,
  isRecalculatingKPIs: boolean,
  analysis: Analysis,
  periode: string,
  rankingTypeIds: number[],
  scopeId?: number,
  scrappingId: number | undefined,
  deviceTypeId: number,
}

function ExportKwButton(props: ExportKwPropsProps): JSX.Element {
  const {
    datavizComp,
    isRecalculatingKPIs,
    analysis,
    periode,
    rankingTypeIds,
    scopeId,
    scrappingId,
    deviceTypeId,
  } = props;
  const dispatch = useAppDispatch();

  const chartKey = generateChartKey(
    datavizComp.label,
    rankingTypeIds,
    analysis.id,
    periode,
    scrappingId,
  );

  const isExportLoading: boolean | undefined = useAppSelector(
    (state) => state.dataviz.exports[chartKey]?.isLoading,
  );

  const analysisName = formatProjectNameForUrl(analysis.label);

  const handleExport = () => {
    const payloadTop100: DatavizRequest = {
      key: chartKey,
      exportName: `FULL-export-${analysisName}`,
      requests: datavizComp.metrics.map((met) => ({
        metric: met.metric,
        resource: met.resource,
        payload: {
          ...BASE_DATAVIZ_REQUEST,
          scope_id: scopeId ?? 0,
          serp_analysis_ids: [analysis.id],
          serp_analysis_scrapping_ids: scrappingId ? [scrappingId] : [],
          sep: 'semicolon',
          ranking_type_ids: rankingTypeIds,
          periode,
          device_type_ids: [deviceTypeId],
        },
      })),
    };
    dispatch(fetchExportData(payloadTop100));
  };

  return (
    <StyledBox>
      <Button
        onClick={() => handleExport()}
        size="small"
        variant="outlined"
        startIcon={<DownloadIcon />}
        disabled={
          (analysis.serp_analysis_status_id === ANALYSIS_STATUS.IN_PROGRESS)
          || isRecalculatingKPIs
          || isExportLoading
        }
        className={
          isExportLoading
            ? classes.spinning
            : classes.button
        }
        data-testid="export-keyword-button"
      >
        {
          isExportLoading
            ? (
              <CircularProgress
                className={classes.spinner}
                size={smallSpinnerSize}
              />
            )
            : 'TOP 50 organique'
        }
      </Button>
    </StyledBox>
  );
}

export default ExportKwButton;
