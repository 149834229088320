import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import {
  Box,
  Typography,
  styled,
  BoxProps,
  Radio,
  RadioGroup,
  FormControlLabel,
  Grid2 as Grid,
} from '@mui/material';
import moment from 'moment';

import MainBox from 'components/layout/MainBox';
import useActors from 'features/actors/state/useActors';
import TitleComponent from 'features/dataviz/components/generic/charts/common/Title';
import DatavizContainer from 'features/dataviz/components/generic/DatavizContainer';
import { METRIC } from 'features/dataviz/CONSTANTS';
import datavizConfig from 'features/dataviz/datavizConfig';
import ViewsList from 'features/kpiView/components/ViewsList';
import { KpiViewType } from 'features/kpiView/types';
import { Project } from 'features/projects/types';
import RankingFilters from 'features/serpAnalysis/components/ranking/RankingFilters';
import RankingGraphTitle from 'features/serpAnalysis/components/ranking/RankingGraphTitle';
import { Analysis, RankingEvolutionFilters } from 'features/serpAnalysis/types';
import { convertDate, DATE_NUMBERS_HOURS_SC } from 'utils/formatDate';
import serializeSearchParams from 'utils/querySerializer';

const PREFIX = 'RankingEvolution';

const classes = {
  title: `${PREFIX}-title`,
  blueTitle: `${PREFIX}-blueTitle`,
  perimeter: `${PREFIX}-perimeter`,
  radio: `${PREFIX}-radio`,
  radioLabel: `${PREFIX}-radioLabel`,
  viewsBox: `${PREFIX}-viewsBox`,
  chartBox: `${PREFIX}-chartBox`,
  greyText: `${PREFIX}-greyText`,
};

const StyledBox = styled(Box)<BoxProps>(({ theme }) => ({
  [`& .${classes.title}`]: {
    fontSize: theme.fontSize.medium,
    fontWeight: '700',
    textTransform: 'uppercase',
    display: 'inline',
  },

  [`& .${classes.blueTitle}`]: {
    fontSize: theme.fontSize.medium,
    fontWeight: '700',
    textTransform: 'uppercase',
    display: 'inline',
    color: theme.palette.primary.light,
  },

  [`& .${classes.perimeter}`]: {
    width: '60%',
    margin: '0 auto',
  },
  [`& .${classes.radio}`]: {
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  [`& .${classes.radioLabel}`]: {
    fontSize: theme.fontSize.xsmall,
  },
  [`& .${classes.viewsBox}`]: {
    height: '300px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  [`& .${classes.chartBox}`]: {
    height: '660px',
  },
  [`& .${classes.greyText}`]: {
    color: theme.palette.info.main,
    textAlign: 'center',
  },
}));

type RankingEvolutionProps = {
  scopeId: number,
  analysis?: Analysis,
  project?: Project,
  rankingFilters: RankingEvolutionFilters,
  intentList: Array<{
    id: number,
    label: string,
  }>,
  graphAspect?: number,
}

function RankingEvolution(props: RankingEvolutionProps) {
  const {
    scopeId,
    analysis,
    project,
    rankingFilters,
    intentList,
    graphAspect,
  } = props;

  const { actors } = useActors({ scopeId });
  const sortedActors = [...actors].sort((a, b) => a.name.localeCompare(b.name));
  const [selectedKpiView, setSelectedKpiView] = useState<KpiViewType>();
  const [searchParams, setSearchParams] = useSearchParams();
  const [filtered, setFiltered] = useState(searchParams.size > 0);

  const handleSubmit = (e: any, values: RankingEvolutionFilters) => {
    e.preventDefault();

    setSearchParams(serializeSearchParams({
      datavizMode: values.datavizMode,
      rankingTypeId: values.rankingTypeId,
      actorIds: values.actorIds,
      periodeBegin: convertDate(
        moment(values.periodeBegin).startOf('day').toDate(),
        DATE_NUMBERS_HOURS_SC,
      ),
      periodeEnd: convertDate(
        moment(values.periodeEnd).endOf('day').toDate(),
        DATE_NUMBERS_HOURS_SC,
      ),
      intentionIds: values.intentionIds,
      timeSeries: values.timeSeries,
      rankSliceIds: values.rankSliceIds,
      deviceTypeId: values.deviceTypeId,
      keywordIds: values.keywordIds,
    }));
    setFiltered(true);
  };

  const handleChange = (e: any) => {
    const { target: { value } } = e;
    searchParams.set('datavizMode', value);
    setSearchParams(searchParams);
  };

  return (
    <StyledBox>
      <Grid container spacing={2}>
        <Grid size={4}>
          <MainBox>
            <RankingFilters
              filters={rankingFilters}
              actors={sortedActors}
              handleSubmit={handleSubmit}
              intentList={intentList}
              analysis={analysis}
              selectedKpiView={selectedKpiView}
            />
          </MainBox>
        </Grid>
        <Grid size={8}>
          <MainBox>
            <Box className={classes.viewsBox}>
              <TitleComponent title="KPI enregistrés" capitalLetter />
              <ViewsList
                idAnalysis={analysis?.id || 0}
                idProject={project?.id || 0}
                actors={actors}
                intentList={intentList}
                scopeId={scopeId}
                analysisDeviceTypeId={analysis?.device_type_id || 2}
                onKpiViewSelected={setSelectedKpiView}
              />
            </Box>
          </MainBox>
        </Grid>
      </Grid>
      {filtered && (
        <>
          <MainBox>
            <Box className={classes.chartBox}>
              <DatavizContainer
                idScope={scopeId}
                datavizComp={
                  datavizConfig[METRIC.count_with_filters_group_by_actors_rank.label]
                }
                idSerpAnalysis={analysis?.id || 0}
                serpAnalysisStatus={analysis?.serp_analysis_status_id}
                periode=""
                title={(
                  <RankingGraphTitle
                    periodeBegin={rankingFilters.periodeBegin}
                    periodeEnd={rankingFilters.periodeEnd}
                    analysis={analysis}
                    deviceTypeId={rankingFilters.deviceTypeId}
                  />
                )}
                height="600px"
                project={project}
                analysis={analysis}
                datavizFilters={rankingFilters}
                rankingMode={rankingFilters.actorIds?.length}
                datavizMode={rankingFilters.datavizMode}
                rankingTypeIds={rankingFilters.rankingTypeId}
                yAxisLabel={
                  rankingFilters.datavizMode === 'rank'
                    ? 'Nombre de mots clés'
                    : 'Demande mensuelle'
                }
                containerAspect={graphAspect}
              />
            </Box>
            <Box>
              <Typography variant="body3" component="p" className={classes.greyText}>
                Seule la meilleure position d&apos;un mot clé est prise en compte
                dans le calcul des moyennes quotidiennes et mensuelles
              </Typography>
              <Typography variant="body3" component="p" className={classes.greyText}>
                Les moyennes sont dégradées lorsque le mot clé n&apos;est plus positionné (rang=51)
              </Typography>
            </Box>
            <RadioGroup
              defaultValue="2"
              value={rankingFilters.datavizMode}
              name="datavizMode"
              onChange={handleChange}
              className={classes.radio}
            >
              <FormControlLabel
                value="rank"
                control={<Radio />}
                label={<Typography className={classes.radioLabel}>Positionnement</Typography>}
              />
              <FormControlLabel
                value="volume"
                control={<Radio />}
                label={<Typography className={classes.radioLabel}>Demandes mensuelles</Typography>}
              />
            </RadioGroup>
          </MainBox>
          <MainBox>
            <DatavizContainer
              idScope={scopeId}
              datavizComp={
                datavizConfig[METRIC.detailed_datas_with_sa_filters.label]
              }
              idSerpAnalysis={analysis?.id || 0}
              serpAnalysisStatus={analysis?.serp_analysis_status_id}
              periode=""
              title="RAPPORT DE POSITIONNEMENT"
              project={project}
              analysis={analysis}
              datavizFilters={rankingFilters}
              rankingMode={rankingFilters.actorIds?.length}
              datavizMode={rankingFilters.datavizMode}
              rankingTypeIds={rankingFilters.rankingTypeId}
              containerAspect={graphAspect}
              searchEngineId={analysis?.search_engine_id}
              height="100%"
            />
          </MainBox>
        </>
      )}
    </StyledBox>
  );
}

export default RankingEvolution;
