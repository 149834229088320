import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Stack } from '@mui/material';
import { useFormik } from 'formik';

import { Analysis } from 'features/serpAnalysis/types';
import serializeSearchParams from 'utils/querySerializer';

import { initFormHistory } from './config';
import PhysionomyInformations from './PhysionomyInformations';
import { PhysionomyHistoryFiltersForm } from '../types';
import PhysionomyHistoryFilters from './PhysionomyHistoryFilters';

type PhysionomyHistoryFiltersContainerProps = {
  isLoading: boolean,
  serpAnalysis?: Analysis,
  rankingTypeIdsUsed: number[],
  hideRankingTypeIds: number[],
  setHideRankingTypeIds: (ids: number[]) => void,
  hideEmptyDays: boolean,
  setHideEmptyDays: (hideEmptyDays: boolean) => void,
  disabledInformations?: boolean,
  onSubmit: (filters: PhysionomyHistoryFiltersForm) => void,
  onFiltersChanged?: (filters: PhysionomyHistoryFiltersForm) => void,
  onFiltersReset: VoidFunction,
  featureLink?: Record<string, string>,
}

function PhysionomyHistoryFiltersContainer(props: PhysionomyHistoryFiltersContainerProps) {
  const {
    serpAnalysis,
    rankingTypeIdsUsed,
    hideRankingTypeIds,
    setHideRankingTypeIds,
    hideEmptyDays,
    setHideEmptyDays,
    isLoading,
    disabledInformations,
    onFiltersChanged,
    onSubmit,
    onFiltersReset,
    featureLink = {},
  } = props;
  const [searchParams, setSearchParams] = useSearchParams();

  const deviceId = Number(searchParams.get('deviceId')) || undefined;
  const rankLimit = Number(searchParams.get('rankLimit')) || undefined;
  const periode = searchParams.get('periode') || undefined;
  const showValues = searchParams.get('showValues')
    ? searchParams.get('showValues') === 'true'
    : true;
  const showPercentage = searchParams.get('showPercentage')
    ? searchParams.get('showPercentage') === 'true'
    : true;

  const handleSubmit = (values: PhysionomyHistoryFiltersForm) => {
    onSubmit(values);
    setSearchParams(serializeSearchParams(values));
  };

  const formik = useFormik<PhysionomyHistoryFiltersForm>({
    initialValues: initFormHistory(
      deviceId ?? serpAnalysis?.device_type_id,
      rankLimit,
      periode,
      showValues,
      showPercentage,
    ),
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    onFiltersChanged?.(formik.values);
  }, [formik.values]);

  const resetForm = () => {
    formik.resetForm();
    setSearchParams();
    onFiltersReset?.();
  };

  return (
    <Stack direction="column" spacing={2}>
      <PhysionomyHistoryFilters
        values={formik.values}
        onChange={formik.handleChange}
        isLoading={isLoading}
        analysis={serpAnalysis}
        onSubmit={formik.handleSubmit}
        isFilter={searchParams.size > 0}
        onPeriodsLoaded={(p) => formik.setFieldValue('periode', p)}
        onReset={() => resetForm()}
      />
      <PhysionomyInformations
        values={formik.values}
        rankingTypeIdsUsed={rankingTypeIdsUsed}
        hideRankingTypeIds={hideRankingTypeIds}
        setHideRankingTypeIds={setHideRankingTypeIds}
        hideEmptyDays={hideEmptyDays}
        setHideEmptyDays={setHideEmptyDays}
        onFieldValueUpdate={formik.setFieldValue}
        onBlur={formik.handleBlur}
        disabled={disabledInformations}
        featureLink={featureLink}
      />
    </Stack>
  );
}

export default PhysionomyHistoryFiltersContainer;
