import React from 'react';

import { Typography } from '@mui/material';
import moment from 'moment';

import globalTheme from 'common/theme';
import { convertDate, DATE_NUMBERS_HOURS } from 'utils/formatDate';

type PhysionomyTitleProps = {
  scrappingDate?: string,
  scrappingDevice?: string,
}

function PhysionomyGraphTitle(props: PhysionomyTitleProps) {
  const {
    scrappingDate,
    scrappingDevice,
  } = props;
  return (
    <Typography
      fontSize={globalTheme.fontSize.mediumLarge}
      color={globalTheme.palette.primary.light}
      variant="bold"
      data-testid="physionomy-graph-title-text"
    >
      {convertDate(
        moment(scrappingDate).toDate(),
        DATE_NUMBERS_HOURS,
      )}
      {' '}
      {scrappingDevice}
    </Typography>
  );
}

export default PhysionomyGraphTitle;
