import React from 'react';

import {
  Box, BoxProps, Button, styled, TextField,
} from '@mui/material';
import { FormikErrors, FormikHandlers, FormikTouched } from 'formik';
import { MuiColorInput } from 'mui-color-input';

import RankingTypeGroupDropDown from '../components/RankingTypeGroupDropDown';
import { UpdateRankingTypeForm } from '../types';

const PREFIX = 'ActorModal';

const classes = {
  core: `${PREFIX}-core`,
  text: `${PREFIX}-text`,
  text_link_like: `${PREFIX}-text-link-like`,
  bold: `${PREFIX}-bold`,
  input: `${PREFIX}-input`,
  inputContent: `${PREFIX}-inputContent`,
  checkContainer: `${PREFIX}-checkContainer`,
  colorPickerInput: `${PREFIX}-colorPickerInput`,
};

const StyledBox = styled(Box)<BoxProps<'form'>>(({ theme }) => ({
  [`&.${classes.core}`]: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3),
    paddingTop: 0,
    alignItems: 'center',
    width: '100%',
  },
  [`& .${classes.text}`]: {
    fontSize: theme.fontSize.medium,
  },
  [`& .${classes.bold}`]: {
    fontWeight: 'bold',
  },
  [`& .${classes.input}`]: {
    width: '100%',
  },
  [`& .${classes.inputContent}`]: {
    fontSize: theme.fontSize.medium,
  },
  [`& .${classes.checkContainer}`]: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  [`& .${classes.text_link_like}`]: {
    color: theme.palette.primary.light,
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    display: 'inline',
    margin: '0px',
    padding: '0px',
    fontSize: theme.fontSize.medium,
  },
  [`& .${classes.text_link_like}:hover`]: {
    backgroundColor: 'transparent',
    textDecoration: 'underline',
    textDecorationThickness: '2px',
  },
  [`& .${classes.colorPickerInput}`]: {
    fontSize: theme.fontSize.medium,
  },
}));

type RankingTypeModalProps = {
  values: UpdateRankingTypeForm,
  rankingTypeName: string,
  touched: FormikTouched<UpdateRankingTypeForm>,
  errors: FormikErrors<UpdateRankingTypeForm>,
  onBlur?: any,
  onChange: FormikHandlers['handleChange'],
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean
  ) => Promise<void | FormikErrors<UpdateRankingTypeForm>>,
}

function EditRankingTypeModal(props: RankingTypeModalProps) {
  const {
    values,
    rankingTypeName,
    touched,
    errors,
    onChange,
    onBlur,
    setFieldValue,
  } = props;
  return (
    <StyledBox
      className={classes.core}
    >
      <TextField
        margin="normal"
        id="name"
        label="Nom"
        type="text"
        name="name"
        variant="outlined"
        size="small"
        className={classes.input}
        slotProps={{
          input: {
            className: classes.inputContent,
            inputProps: { 'data-testid': 'ranking-type-modal-name-field' },
          },
        }}
        value={rankingTypeName}
        disabled
      />
      <TextField
        margin="normal"
        id="custom_name"
        label="Nom personnalisé"
        type="text"
        name="custom_name"
        variant="outlined"
        size="small"
        className={classes.input}
        slotProps={{
          input: {
            className: classes.inputContent,
            inputProps: { 'data-testid': 'ranking-type-modal-custom-name-field' },
          },
        }}
        value={values.custom_name}
        onChange={onChange}
        onBlur={onBlur}
        error={
          touched.custom_name
          && Boolean(errors.custom_name)
        }
        helperText={
          touched.custom_name
          && errors.custom_name
        }
      />
      <TextField
        margin="normal"
        id="description"
        label="Description"
        type="text"
        name="description"
        variant="outlined"
        size="small"
        className={classes.input}
        slotProps={{
          input: {
            className: classes.inputContent,
            inputProps: { 'data-testid': 'ranking-type-modal-description-field' },
          },
        }}
        value={values.description}
        onChange={onChange}
        onBlur={onBlur}
        error={
          touched.description
          && Boolean(errors.description)
        }
        helperText={
          touched.description
          && errors.description
        }
      />
      <RankingTypeGroupDropDown
        value={values.ranking_type_group_id ?? ''}
        onChange={onChange}
        name="ranking_type_group_id"
        label="Groupe d'encart de SERP"
        size="small"
        fontSize="medium"
        margin="10px 0 0 0"
        error={touched.ranking_type_group_id && !!errors.ranking_type_group_id}
        errorText={
          (touched.ranking_type_group_id && errors.ranking_type_group_id)
            ? errors.ranking_type_group_id
            : undefined
        }
      />
      <Box
        alignItems="start"
        sx={{ width: 1 }}
        data-testid="ranking-type-modal-color-picker"
      >
        <MuiColorInput
          value={values.dataviz_color ?? '#000000'}
          onChange={(value) => setFieldValue('dataviz_color', value, true)}
          fallbackValue="#000000"
          format="hex"
          name="datavizColor"
          isAlphaHidden
          fullWidth
          size="small"
          label="Couleur"
          margin="normal"
          slotProps={{
            input: {
              className: classes.colorPickerInput,
              inputProps: { 'data-testid': 'ranking-type-modal-color-input' },
            },
          }}
          error={
            touched.dataviz_color
            && Boolean(errors.dataviz_color)
          }
          helperText={
            touched.dataviz_color
            && errors.dataviz_color
          }
        />
        <Button
          variant="text"
          disableRipple
          className={classes.text_link_like}
          onClick={() => setFieldValue('dataviz_color', '#000000', true)}
          data-testid="ranking-type-modal-default-color-button"
        >
          Couleur par défaut
        </Button>
      </Box>
    </StyledBox>
  );
}

export default EditRankingTypeModal;
