import React, { useState } from 'react';

import {
  Box, Typography, styled, BoxProps,
} from '@mui/material';
import {
  DataGrid, GridCellParams, GridColDef, GridRowIdGetter, GridSlots, GridTreeNode,
} from '@mui/x-data-grid';

import CheckBoxDataGrid from 'components/checkbox/CheckBoxDataGrid';
import LinearLoadingOverlay from 'components/loading/LinearLoadingOverlay';
import { SerpAnalysisKeyword } from 'features/keywords/types';
import { KeywordsList } from 'features/serpAnalysis/components/serpDetails/types';

import DataGridActionButtons from './DataGridActionButtons';
import GridCustomToolbar from './GridCustomToolBar';

export type AnalysisDetailsDataGridProps = {
  handleInputChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>,
  rows: KeywordsList | SerpAnalysisKeyword[],
  handleExport?: React.MouseEventHandler<HTMLElement> | undefined,
  columnData: GridColDef[],
  isLoading: boolean | undefined,
  columnsToHide?: { [key: string]: boolean },
  title?: string | JSX.Element,
  handleSelect?: Function,
  children?: JSX.Element,
  isSelectActivated?: boolean,
  showToolbar?: boolean,
  isExportLoading?: boolean | undefined,
  getRowId?: GridRowIdGetter<any> | undefined,
  getCellClassName?: ((params: GridCellParams<any, any, any, GridTreeNode>) => string) | undefined,
  disableRowSelectionOnClick?: boolean,
}

const PREFIX = 'AnalysisDetailsDataGrid';

const classes = {
  title: `${PREFIX}-title`,
};

const StyledBox = styled(Box)<BoxProps>(({ theme }) => ({
  [`& .${classes.title}`]: {
    textAlign: 'center',
    fontSize: theme.fontSize.medium,
    fontWeight: 'bold',
  },
}));

function AnalysisDetailsDataGrid(props: AnalysisDetailsDataGridProps): JSX.Element {
  const {
    handleInputChange,
    rows,
    handleExport,
    columnData,
    columnsToHide = {},
    isLoading,
    title = '',
    handleSelect,
    children,
    isExportLoading,
    isSelectActivated = false,
    showToolbar = true,
    getRowId = (row: any) => row.tmpId,
    getCellClassName,
    disableRowSelectionOnClick = true,
  } = props;
  const [columnButtonEl, setColumnButtonEl] = useState<HTMLButtonElement | null>(null);

  const toolbarSlotProps = {
    setColumnButtonEl,
  } as any;

  return (
    <StyledBox>
      <Typography className={classes.title}>
        {title}
      </Typography>
      {(
        children
      ) || (
        <DataGridActionButtons
          handleExport={handleExport}
          handleInputChange={handleInputChange}
          isLoading={isExportLoading}
        />
      )}
      <DataGrid
        getRowId={getRowId}
        getCellClassName={getCellClassName}
        density="compact"
        disableRowSelectionOnClick={disableRowSelectionOnClick}
        disableColumnMenu
        autoHeight
        disableVirtualization
        checkboxSelection={isSelectActivated}
        getRowHeight={() => 'auto'}
        rows={rows}
        columns={columnData}
        slots={{
          loadingOverlay: LinearLoadingOverlay,
          toolbar: showToolbar ? GridCustomToolbar as unknown as GridSlots['toolbar'] : undefined,
          baseCheckbox: CheckBoxDataGrid,
        }}
        initialState={{
          columns: {
            columnVisibilityModel: {
              ...columnsToHide,
            },
          },
        }}
        localeText={{
          noRowsLabel: 'Aucun enregistrement trouvé.',
          MuiTablePagination: {
            labelDisplayedRows: ({ from, to, count }) => `${from} - ${to} sur ${count}`,
          },
          toolbarColumns: 'Colonnes',
          toolbarColumnsLabel: 'Sélectionnez vos colonnes',
          columnsManagementSearchTitle: 'Nom de la colonne',
          columnsManagementShowHideAllText: 'Tout sélectionner/désélectionner',
          columnsManagementReset: '',
        }}
        slotProps={{
          pagination: {
            labelRowsPerPage: ('Nombre par page :'),
          },
          toolbar: toolbarSlotProps,
          panel: {
            anchorEl: columnButtonEl,
            placement: 'bottom-end',
          },
        }}
        onRowSelectionModelChange={(keywordsSelected) => {
          if (handleSelect) handleSelect(keywordsSelected);
        }}
        loading={isLoading}
        sx={{ marginTop: 0 }}
      />
    </StyledBox>
  );
}

export default AnalysisDetailsDataGrid;
