import React from 'react';

import {
  Box,
  BoxProps,
  styled,
  TextField,
} from '@mui/material';
import { FormikProps, FormikProvider } from 'formik';

import SelectProjectsDropdown from 'features/projects/components/SelectProjectsDropdown';

import { CreateModifyExternalUserForm } from '../types';

const PREFIX = 'CreateModifyExternalUserModal';

const classes = {
  box: `${PREFIX}-box`,
  input: `${PREFIX}-input`,
};

const StyledBox = styled(Box)<BoxProps<'form'>>(() => ({
  [`&.${classes.box}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    paddingTop: '2%',
  },
  [`&.${classes.input}`]: {
    marginBottom: '10px',
    border: '1px solid red',
    backgroundColor: 'blue',
    width: '50%',
  },
}));

type CreateModifyExternalUserModalProps = {
  formik: FormikProps<CreateModifyExternalUserForm>,
  isModif: boolean,
  isPP?: boolean,
}

function CreateModifyExternalUserModal (props: CreateModifyExternalUserModalProps) {
  const { formik, isModif, isPP = false } = props;

  return (
    <StyledBox
      data-testid="modal-manage-user-project"
      className={classes.box}
      gap={3}
    >
      <FormikProvider value={formik}>
        <TextField
          name="email"
          label="Saisir l'email de l'utilisateur"
          type="email"
          size="small"
          variant="outlined"
          fullWidth
          className={classes.input}
          value={formik.values.email}
          data-testid="user-email-text-field"
          onChange={formik.handleChange}
          error={formik.touched.email && !!formik.errors.email}
          helperText={formik.touched.email && formik.errors.email}
          slotProps={{
            input: {
              autoComplete: 'username',
              className: classes.input,
            },
          }}
        />
        <TextField
          name="password"
          label="Saisir le mot de passe de l'utilisateur"
          type="password"
          size="small"
          variant="outlined"
          fullWidth
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.touched.password && !!formik.errors.password}
          helperText={formik.touched.password && formik.errors.password}
          data-testid="user-password-text-field"
          slotProps={{
            input: {
              autoComplete: 'password',
              className: classes.input,
            },
          }}
        />
        {
          !isModif && !isPP && (
            <>
              <SelectProjectsDropdown
                value={formik.values.projectsConsult || []}
                onChange={formik.handleChange}
                name="projectsConsult"
                label="Projet(s) consultable(s)"
                size="small"
                multiple
                error={
                  Boolean(formik.errors.projectsConsult)
                  && Boolean(formik.touched.projectsConsult)
                }
                displayGlobalCheckbox={false}
                emptyOption="Sélectionner un ou plusieurs projets"
                errorText={
                  (formik.touched.projectsConsult && formik.errors.projectsConsult)
                    ? formik.errors.projectsConsult
                    : ''
                }
              />
              <SelectProjectsDropdown
                value={formik.values.projectsEdit || []}
                onChange={formik.handleChange}
                name="projectsEdit"
                label="Projet(s) modifiable(s)"
                size="small"
                multiple
                error={
                  Boolean(formik.errors.projectsEdit)
                  && Boolean(formik.touched.projectsEdit)
                }
                displayGlobalCheckbox={false}
                emptyOption="Sélectionner un ou plusieurs projets"
                errorText={
                  (formik.touched.projectsEdit && formik.errors.projectsEdit)
                    ? formik.errors.projectsEdit
                    : ''
                }
              />
            </>
          )
        }
      </FormikProvider>
    </StyledBox>
  );
}

export default CreateModifyExternalUserModal;
