import React from 'react';

import FileCopyIcon from '@mui/icons-material/FileCopy';
import {
  CircularProgress,
  Divider,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';

import globalTheme from 'common/theme';
import MainBox from 'components/layout/MainBox';
import { ChartComponentProps } from 'features/dataviz/types';
import useRankingTypes from 'features/rankingTypes/state/useRankingTypes';
import useSerpAnalysisScrappings
  from 'features/serpAnalysisScrapping/state/useSerpAnalysisScrappings';
import PhysionomyGraphTitle from 'features/serpPhysionomy/components/PhysionomyGraphTitle';

import EmptyGroupByRankingType from './EmptyGroupByRankingType';
import GroupResultsByRankingTypeGraph from './GroupResultsByRankingTypeGraph';
import TitleComponent from '../generic/charts/common/Title';

function GroupByRankingType(props: ChartComponentProps) {
  const {
    datas,
    isPageLoading,
    actors,
    datavizFilters,
    analysis,
    showPercentage,
    showValue,
    hideRankingTypeIds,
    isLoading,
    containerAspect,
    title,
    project,
    handleExport,
    isExportLoading,
  } = props;
  const filters = datavizFilters?.type === 'datavizFilters' ? datavizFilters : undefined;

  const { scrappings } = useSerpAnalysisScrappings(analysis?.id ?? 0);
  const { rankingTypes } = useRankingTypes();

  const sortedScrapping = filters?.serpAnalysisScrappingIds?.sort();
  const scrappingToCompareOne = scrappings.find(
    (scrap) => sortedScrapping?.[0] === scrap.id,
  );
  const scrappingToCompareTwo = scrappings.find(
    (scrap) => sortedScrapping?.[1] === scrap.id,
  );

  const scrappingIds = filters?.serpAnalysisScrappingIds;

  if (isLoading) {
    return (
      <MainBox BoxProps={{ textAlign: 'center', alignContent: 'center', height: '400px' }}>
        <Stack
          direction="column"
          alignItems="center"
        >
          <CircularProgress data-testid="physionomy-group-by-ranking-type-spinner" />
          <Typography variant="bold" sx={{ fontSize: globalTheme.fontSize.middlesmall }}>
            Analyse en cours ...
          </Typography>
        </Stack>
      </MainBox>
    );
  }

  if (!filters || !scrappingIds || !actors || !datas.data.values) {
    return (
      <EmptyGroupByRankingType
        title={title}
        analysisName={analysis?.label}
        project={project}
        dataKeysCount={1}
        hasData
      />
    );
  }

  const dataSetOne = datas.data.values.filter(
    (data) => data.scrapping_id === scrappingToCompareOne?.id,
  );
  const dataSetTwo = datas.data.values.filter(
    (data) => data.scrapping_id === scrappingToCompareTwo?.id,
  );

  // List all ranking types to complete missing ones
  const rankingTypeNames: string[] = [
    ...new Set(datas.data.values
      .filter((rt) => (hideRankingTypeIds
        ? !hideRankingTypeIds.includes(rt.ranking_type_id)
        : true
      ))
      .map((data) => (
        rankingTypes.find(
          (rt) => rt.id === data.ranking_type_id,
        )?.custom_name || data.ranking_type_name
      ))),
  ].sort((a, b) => a.localeCompare(b));

  if (
    rankingTypes.length === 0
    || datas.data.values.length === 0
    || rankingTypeNames.length === 0
  ) {
    return (
      <EmptyGroupByRankingType
        title={title}
        analysisName={analysis?.label}
        project={project}
        dataKeysCount={rankingTypeNames.length}
        hasData={datas.data.values.length > 0}
      />
    );
  }

  // List all actors to complete missing ones
  const actorNames: string[] = actors.map((actor) => actor.name);

  return (
    <MainBox
      BoxProps={{ justifyContent: 'center', height: '100%' }}
      data-testid="group-result-main-box"
    >
      <Stack direction="column" gap={3} mb="16px" height="100%">
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          position="relative"
        >
          <TitleComponent title={title} justifyContent="center" />
          <IconButton
            sx={{
              color: globalTheme.palette.primary.light,
              position: 'absolute',
              right: 0,
            }}
            onClick={handleExport}
            disabled={isExportLoading}
            size="medium"
          >
            {isExportLoading
              ? <CircularProgress size="24px" data-testid="export-spinner" />
              : (
                <Tooltip title="Exporter les données" arrow>
                  <FileCopyIcon fontSize="inherit" data-testid="export-button" />
                </Tooltip>
              )}
          </IconButton>
        </Stack>
        <Stack
          direction="row"
          width="100%"
          justifyContent="space-between"
          height="100%"
          divider={(
            <Divider
              orientation="vertical"
              flexItem
            />
          )}
        >
          <GroupResultsByRankingTypeGraph
            actors={actors}
            datas={dataSetOne}
            isPageLoading={isPageLoading}
            actorNames={actorNames}
            rankingTypeNames={rankingTypeNames}
            rankingTypes={rankingTypes}
            showValue={showValue}
            showPercentage={showPercentage}
            containerAspect={containerAspect}
            title={(
              <PhysionomyGraphTitle
                scrappingDate={scrappingToCompareOne?.scrapping_date}
                scrappingDevice={scrappingToCompareOne?.device_type.name}
              />
            )}
          />
          <GroupResultsByRankingTypeGraph
            actors={actors}
            datas={dataSetTwo}
            isPageLoading={isPageLoading}
            actorNames={actorNames}
            rankingTypeNames={rankingTypeNames}
            rankingTypes={rankingTypes}
            showValue={showValue}
            showPercentage={showPercentage}
            containerAspect={containerAspect}
            title={(
              <PhysionomyGraphTitle
                scrappingDate={scrappingToCompareTwo?.scrapping_date}
                scrappingDevice={scrappingToCompareTwo?.device_type.name}
              />
            )}
          />
        </Stack>
      </Stack>
    </MainBox>
  );
}

export default GroupByRankingType;
