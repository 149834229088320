import React from 'react';

import { Box, Stack, Typography } from '@mui/material';
import pictoVidePhysionomy from 'assets/images/picto_vide_physionomy.jpg';

function EmptyGraphGroupByRankingType() {
  return (
    <Stack
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
      }}
      height="100%"
      data-testid="empty-graph-group-by-type"
      padding={5}
    >
      <Box
        component="img"
        src={pictoVidePhysionomy}
        alt="contenu dataviz vide"
        sx={{
          width: '70%',
          height: 'auto',
        }}
      />
      <Typography variant="bold">
        Sélectionnez une deuxième analyse pour comparer les parts de voix
      </Typography>
    </Stack>
  );
}

export default EmptyGraphGroupByRankingType;
