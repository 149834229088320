import React from 'react';

import { Typography } from '@mui/material';

import globalTheme from 'common/theme';
import TitleComponent from 'features/dataviz/components/generic/charts/common/Title';

function SerpPhysionomyTitle() {
  return (
    <TitleComponent
      title={(
        <Typography fontSize={globalTheme.fontSize.xlarge}>
          <Typography
            color={globalTheme.palette.primary.light}
            variant="bold"
            data-testid="physionomy-title-text"
            sx={{ fontSize: '26px' }}
          >
            Augmentez votre part de voix
          </Typography>
          {' '}
          pour distancer vos concurrents
        </Typography>
      )}
      justifyContent="center"
    />
  );
}

export default SerpPhysionomyTitle;
