import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { SelectChangeEvent } from '@mui/material';

import DropDown from 'components/dropDown/DropDown';
import useSerpAnalyses from 'features/serpAnalysis/state/useSerpAnalyses';
import {
  generateAnalysisUrl,
  generateProjectBaseUrl,
} from 'utils/urlManipulation';

interface AnalysisDropdownProps {
  projectId: number,
  analysisId: number,
  projectName: string,
  scopeId: number,
}
// Dropdown de la sidebar
function AnalysisDropdown (props: AnalysisDropdownProps) {
  const {
    projectId,
    projectName,
    analysisId,
    scopeId,
  } = props;

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { idNameAnalysis } = useParams();

  const { serpAnalyses: analyses } = useSerpAnalyses(
    { scopeId, doFetch: false },
  );

  const sortedAnalyses = [...analyses].sort((a, b) => a.label.localeCompare(b.label));

  const [analysisSelected, setAnalysis] = useState<number | string>(
    analysisId || sortedAnalyses[0]?.id || '',
  );

  useEffect(() => {
    setAnalysis(analysisId || sortedAnalyses[0]?.id || '');
  }, [analysisId, sortedAnalyses]);

  const onChange = (e: SelectChangeEvent<any>) => {
    const { target: { value } } = e;

    if (value === 0) {
      navigate(`${generateProjectBaseUrl(projectId, projectName)}results`);
    }

    const analysis = sortedAnalyses.find((anls) => anls.id === value);

    if (!analysis) return;

    const queryUpdated = searchParams;
    if (queryUpdated.has('searchIntentId')) {
      queryUpdated.set('searchIntentId', '0');
    }
    if (queryUpdated.has('idScrapping')) {
      queryUpdated.set('idScrapping', '');
    }

    const url = generateAnalysisUrl(
      projectId,
      projectName,
      analysis.id,
      analysis.label,
      'results',
      undefined,
    );

    setAnalysis(analysis.id);
    if (idNameAnalysis || analysisSelected) {
      navigate(`${url}`);
    } else {
      navigate('#', { state: { from: analysis.id } });
    }
  };

  const analysesWithEmpty: any[] = [
    {
      id: 0,
      label: 'Sélectionnez une analyse',
    },
    ...sortedAnalyses,
  ];

  return (
    <DropDown<typeof sortedAnalyses[number]>
      value={analysisSelected || ''}
      label=""
      onChange={onChange}
      options={analysesWithEmpty}
      getOptionLabel={(analysis) => (
        analysis.id
          ? `${analysis.label} (${analysis.keywords_count})`
          : analysis.label
      )}
      getOptionValue={(analysis) => analysis.id}
      name="analysis"
      displayEmptyInField
      displayEmptyInList
      fullWidth
      height="2rem"
      data-testid="select-analysis-drop-down"
    />
  );
}

export default AnalysisDropdown;
