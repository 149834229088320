import React, { useState } from 'react';

import { MenuItem, Typography } from '@mui/material';
import { FormikHelpers, useFormik } from 'formik';

import { useAppDispatch } from 'common/reduxHooks';
import globalTheme from 'common/theme';
import Modal from 'components/modal/Modal';

import { UpdateRankingTypeForm, RankingTypeType } from '../types';
import EditRankingTypeModal from './EditRankingTypeModal';
import { editRankingTypeValidationSchema, initialValuesEditRt } from './setupConfig';
import { updateRankingType } from '../state/slice';

type EditRankingTypeType = {
  rankingType: RankingTypeType,
  handleCloseMenu: Function,
}

function EditRankingType(props: EditRankingTypeType) {
  const { handleCloseMenu, rankingType } = props;

  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleSubmit = (
    values: UpdateRankingTypeForm,
    formikHelpers: FormikHelpers<UpdateRankingTypeForm>,
  ) => {
    dispatch(updateRankingType({
      id: rankingType.id,
      rankingTypeUpdate: {
        custom_name: values.custom_name?.trim() ?? undefined,
        description: values.description?.trim() ?? undefined,
        ranking_type_group_id: values.ranking_type_group_id,
        dataviz_color: values.dataviz_color,
      },
    }));
    formikHelpers.resetForm();
    setIsOpen(false);
  };

  const formik = useFormik<UpdateRankingTypeForm>({
    initialValues: initialValuesEditRt(rankingType),
    onSubmit: handleSubmit,
    validationSchema: editRankingTypeValidationSchema,
  });

  return (
    <>
      <MenuItem
        sx={{ fontSize: globalTheme.fontSize.middlesmall }}
        onClick={
          () => { setIsOpen(true); handleCloseMenu(); }
        }
        data-testid="edit-ranking-type-button"
      >
        Personnaliser
      </MenuItem>
      <Modal
        title={(
          <Typography>
            <Typography component="span" variant="bold">Personnaliser</Typography>
            {' '}
            votre encart de SERP
          </Typography>
        )}
        actionConfirm={formik.handleSubmit}
        actionCancel={() => {
          setIsOpen(false);
          formik.resetForm();
        }}
        isOpen={isOpen}
        dataTestId="edit-ranking-type-modal"
      >
        <EditRankingTypeModal
          values={formik.values}
          rankingTypeName={rankingType.name}
          touched={formik.touched}
          errors={formik.errors}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          setFieldValue={formik.setFieldValue}
        />
      </Modal>
    </>
  );
}

export default EditRankingType;
