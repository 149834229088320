import React, { useState } from 'react';

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
  Checkbox,
  FormControlLabel,
  Link,
  SelectChangeEvent,
  Stack,
  StackProps,
  styled,
  Tooltip,
  Typography,
} from '@mui/material';

import DropDown from 'components/dropDown/DropDown';
import MainBox from 'components/layout/MainBox';
import TitleComponent from 'features/dataviz/components/generic/charts/common/Title';
import RankingTypeGroupDropDown from 'features/rankingTypes/components/RankingTypeGroupDropDown';
import useRankingTypes from 'features/rankingTypes/state/useRankingTypes';

import { PhysionomyFiltersForm, PhysionomyHistoryFiltersForm } from '../types';

const PREFIX = 'ActorsDataGrid';

const classes = {
  icon: `${PREFIX}-icon`,
};

const StyledStack = styled(Stack)<StackProps>(() => ({
  [`& .${classes.icon}`]: {
    fontSize: '1rem',
  },
}));

type PhysionomyFiltersProps = {
  values: PhysionomyFiltersForm | PhysionomyHistoryFiltersForm,
  rankingTypeIdsUsed: number[],
  hideRankingTypeIds: number[],
  setHideRankingTypeIds: (ids: number[]) => void,
  hideEmptyDays?: boolean,
  setHideEmptyDays?: (hideEmptyDays: boolean) => void,
  disabled?: boolean,
  onFieldValueUpdate: (field: 'showPercentage' | 'showValues', value: any) => void,
  onBlur?: (e: React.FocusEvent<any>) => void,
  featureLink?: Record<string, string>,
}

function PhysionomyInformations(props: PhysionomyFiltersProps) {
  const {
    values,
    rankingTypeIdsUsed,
    hideRankingTypeIds,
    setHideRankingTypeIds,
    hideEmptyDays,
    setHideEmptyDays,
    disabled,
    onFieldValueUpdate,
    onBlur,
    featureLink = {},
  } = props;
  const [rankingTypeGroupId, setRankingTypeGroupId] = useState<number | ''>('');

  const { rankingTypes } = useRankingTypes();

  const handleDropDownChange = (event: SelectChangeEvent<string>) => {
    const barValues = event.target.value.split(',');

    const showPercentage = barValues[0] === 'true';
    const showValues = barValues[1] === 'true';

    onFieldValueUpdate('showPercentage', showPercentage);
    onFieldValueUpdate('showValues', showValues);
  };

  const onRankingTypeGroupChanged = (value: number | '') => {
    setRankingTypeGroupId(value);

    if (Number.isInteger(value)) {
      setHideRankingTypeIds(rankingTypes
        .filter((rt) => rt.ranking_type_group_id !== value)
        .map((rt) => rt.id));
    } else {
      setHideRankingTypeIds([]);
    }
  };

  const onRankingTypeChanged = (rankingTypeId: number) => {
    if (hideRankingTypeIds.includes(rankingTypeId)) {
      setHideRankingTypeIds(hideRankingTypeIds.filter((id) => id !== rankingTypeId));
    } else {
      setHideRankingTypeIds([...hideRankingTypeIds, rankingTypeId]);
    }
  };

  return (
    <MainBox>
      <TitleComponent
        title="filtres"
        capitalLetter
        direction="column"
      />
      <Stack direction="column" spacing={1} sx={{ mt: 2 }}>
        <DropDown
          value={`${values.showPercentage},${values.showValues}`}
          onChange={handleDropDownChange}
          options={[
            { value: 'false,false', label: 'Masquer les valeurs' },
            { value: 'false,true', label: 'Afficher les valeurs' },
            { value: 'true,true', label: 'Afficher les valeurs (%)' },
          ]}
          size="small"
          fontSize="xsmall"
          disabled={disabled}
          data-testid="select-show-values-drop-down"
        />
        {hideEmptyDays !== undefined && !!setHideEmptyDays && (
          <DropDown
            value={`${hideEmptyDays}`}
            label="Jours vides"
            onChange={(event) => setHideEmptyDays?.(event.target.value === 'true')}
            options={[
              { value: 'false', label: 'Afficher' },
              { value: 'true', label: 'Ne pas afficher' },
            ]}
            size="small"
            fontSize="xsmall"
            disabled={disabled}
            data-testid="select-hide-empty-days-drop-down"
          />
        )}
        <RankingTypeGroupDropDown
          value={rankingTypeGroupId}
          label="Fonctionnalités"
          emptyOption="Toutes les fonctionnalités"
          displayEmptyInList
          onChange={(e) => onRankingTypeGroupChanged(e.target.value)}
          fontSize="xsmall"
          disabled={disabled}
          size="small"
          data-testid="select-ranking-type-group-drop-down"
        />
      </Stack>
      <StyledStack direction="column" mx={0.5}>
        {[...rankingTypes]
          .sort(
            (a, b) => (a.custom_name ?? a.name).localeCompare(b.custom_name ?? b.name),
          )
          .filter((rt) => rankingTypeIdsUsed.includes(rt.id)).map((rt) => (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-start"
              key={`physionomy-ranking-type-${rt.id}-formcontrol`}
            >
              <FormControlLabel
                sx={{ mb: '-5px', mr: '5px' }}
                control={<Checkbox size="small" />}
                label={(
                  <Typography variant="body3">
                    {rt.custom_name ?? rt.name}
                  </Typography>
                )}
                name={`hide-${rt.name}`}
                checked={hideRankingTypeIds.find((r) => r === rt.id) === undefined}
                onChange={() => onRankingTypeChanged(rt.id)}
                onBlur={onBlur}
                disabled={disabled}
                data-testid={`physionomy-ranking-type-${rt.id}-formcontrol`}
              />
              <Tooltip title={rt.description} arrow>
                <HelpOutlineIcon color="info" className={classes.icon} />
              </Tooltip>
              {Object.keys(featureLink).includes(rt.name) && (
                <Link
                  href={featureLink[rt.name]}
                  sx={{ alignItems: 'center', display: 'flex', ml: 0.5 }}
                >
                  <OpenInNewIcon color="primary" className={classes.icon} />
                </Link>
              )}
            </Stack>
          ))}
      </StyledStack>
    </MainBox>
  );
}

export default PhysionomyInformations;
