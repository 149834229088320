import React, { useState } from 'react';

import { Box, Stack, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';

import useDocumentTitle from 'common/documentTitleHook';
import AddViewModalSimple from 'features/kpiView/components/AddViewModalSimple';
import { KPI_VIEW_EXPORT, KPI_VIEW_TYPE } from 'features/kpiView/CONSTANTS';
import createColumnsBenchmark from 'features/kpiView/list/columns';
import ViewDataGrid from 'features/kpiView/list/ViewDataGrid';
import useKpiViewByType from 'features/kpiView/state/useKpiViewByType';
import { KpiViewCreateUpdate, KpiViewInlineEditType, KpiViewType } from 'features/kpiView/types';
import PerformanceFiltersBlock from 'features/performance/components/PerformanceFiltersBlock';
import { PerformanceFiltersType } from 'features/performance/types';
import withPerformanceFilters from 'features/performance/utils/withPerformanceFilters';
import { Project } from 'features/projects/types';
import { generatePerformanceUrl } from 'utils/urlManipulation';

type PerformancePerActorProps = {
  project?: Project,
};

function LookerStudio ({ project }: PerformancePerActorProps) {
  const scopeId = project?.scopes[0].id ?? 0;
  const baseUrl = generatePerformanceUrl(
    project?.id ?? 0,
    project?.name ?? '',
    undefined,
    undefined,
    'kpi',
  );

  useDocumentTitle(`${project?.name} : export des performances SEO | benhur`);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editedRowValue, setEditedRowValue] = useState<KpiViewInlineEditType>({
    start_date: '',
    id: 0,
  });
  const [isAggregateMode, setIsAggregateMode] = useState(true);

  const page = 'kpi';

  const {
    performanceFilters,
    defaultFilters,
    handleFiltersUpdate,
    resetFilters,
    hasFirstLoadedFilters,
    isFullyLoaded,
  } = withPerformanceFilters(scopeId, page);

  const { kpiViews, isLoading } = useKpiViewByType(
    scopeId,
    [KPI_VIEW_TYPE.benchmark.id, KPI_VIEW_TYPE.benchmarkLight.id],
  );

  const getNewKpiViewValues = (): KpiViewCreateUpdate => ({
    label: 'export benchmark performance',
    time_serie: 'month',
    start_date: performanceFilters.periodeBegin,
    scope_id: scopeId,
    kpi_view_export_id: KPI_VIEW_EXPORT.withExport.id,
    kpi_view_export_status_id: 1,
    kpi_view_type_id: isAggregateMode
      ? KPI_VIEW_TYPE.benchmarkLight.id
      : KPI_VIEW_TYPE.benchmark.id,
    best_rank: !!performanceFilters.bestRankMode,
    category_group_id: performanceFilters.categoryGroupId,
    include_uncategorized: performanceFilters.categoryIds?.find((id) => id === 0) === 0
      || performanceFilters.includeUncategorized,
    kpi_view_categories: performanceFilters.categoryIds
      ?.filter((id) => id !== 0)
      .map((id) => ({ category_id: id }))
      ?? [],
    device_type_id: performanceFilters.deviceTypeId,
    kpi_view_serp_analyses: performanceFilters.serpAnalysisIds?.map((id) => ({
      serp_analysis_id: id,
    })) ?? [],
    kpi_view_actors: performanceFilters.actorIds?.map((id) => ({ actor_id: id })) ?? [],
    kpi_view_keywords: performanceFilters.keywordIds?.map((id) => ({ keyword_id: id })) ?? [],
    kpi_view_sa_rank_slices: performanceFilters.rankSliceIds?.map(
      (id) => ({ sa_rank_slice_id: id }),
    ) ?? [],
    kpi_view_sa_keyword_intentions: performanceFilters.intentionIds?.map(
      (id) => ({ sa_keyword_intention_id: id }),
    ) ?? [],
    kpi_view_ranking_types: performanceFilters.rankingTypeId?.map(
      (id) => ({ ranking_type_id: id }),
    ) ?? [],
  });

  const handleSaveFilters = (values: PerformanceFiltersType) => {
    handleFiltersUpdate(values);
    setIsAggregateMode(values.aggregateMode);
    setIsModalOpen(true);
  };

  const handleSetEditRowValue = (kpiViewId: number) => {
    const editedRow = kpiViews.find((kpiView) => kpiView.id === kpiViewId);

    if (editedRow) {
      setEditedRowValue({
        start_date: editedRow.start_date,
        id: editedRow.id,
      });
    } else {
      setEditedRowValue({
        ...editedRowValue,
        id: 0,
      });
    }
  };

  const handleUpdateEditRowValue = ({ key, value }: any) => {
    setEditedRowValue((previous: any) => ({
      ...previous,
      [key]: value,
    }));
  };

  const columns: GridColDef<KpiViewType>[] = createColumnsBenchmark(
    project?.id || 0,
    scopeId,
    editedRowValue,
    handleSetEditRowValue,
    handleUpdateEditRowValue,
  );

  return (
    <Box>
      <Typography variant="h1">
        Envoyez les données de performances SEO vers Looker Studio
      </Typography>
      {isFullyLoaded && hasFirstLoadedFilters && (
        <Stack spacing={2}>
          <div>
            <PerformanceFiltersBlock
              onFiltersChange={handleSaveFilters}
              defaultFilters={defaultFilters}
              resetFilters={resetFilters}
              baseUrl={baseUrl}
              scopeId={scopeId ?? 0}
              page={page}
              updateQuery={false}
            />
            <AddViewModalSimple
              scopeId={scopeId}
              typeId={KPI_VIEW_TYPE.benchmark.id}
              title={(
                <Typography>
                  <Typography component="span" variant="bold">
                    Exporter
                    {' '}
                  </Typography>
                  vers Locker Studio
                </Typography>
              )}
              newView={getNewKpiViewValues()}
              isOpen={isModalOpen}
              onClose={() => setIsModalOpen(false)}
            />
          </div>
          <ViewDataGrid
            kpiViews={kpiViews}
            isLoading={isLoading}
            columns={columns}
          />
        </Stack>
      )}
    </Box>
  );
}

export default LookerStudio;
