import React from 'react';

import {
  Box,
  Typography,
} from '@mui/material';
import { SelectInputProps } from '@mui/material/Select/SelectInput';

import globalTheme from 'common/theme';
import DropDown from 'components/dropDown/DropDown';
import { DATE_NUMBERS_HOURS, convertDate } from 'utils/formatDate';

import SerpAnalysisScrapping from '../types';

interface SelectScrappingProps {
  value: number | number[] | '',
  onChange: SelectInputProps<any>['onChange'],
  name?: string,
  multiple?: boolean,
  emptyOption?: string
  displayEmptyInList?: boolean,
  size?: 'small' | 'medium',
  height?: string,
  width?: string,
  fontSize?: keyof typeof globalTheme.fontSize,
  className?: {
    [key: string]: string,
  },
  scrappings: SerpAnalysisScrapping[],
  variant: 'filled' | 'outlined' | 'standard',
  fullWidth?: boolean,
  label?: string,
  bigLabel?: boolean,
  showDevice?: boolean,
  'data-testid'?: string,
  disabled?: boolean,
}

function SelectScrapping (props: SelectScrappingProps) {
  const {
    value,
    onChange,
    name,
    multiple = false,
    emptyOption,
    displayEmptyInList,
    size,
    fontSize,
    height,
    width,
    className,
    scrappings,
    variant,
    fullWidth,
    label,
    bigLabel,
    showDevice,
    disabled = false,
    'data-testid': dataTestId = 'select-actor-drop-down',
  } = props;

  return (
    <Box>
      {bigLabel && (
        <Typography id="listAnalyseId-label" className={className?.title}>
          {scrappings.length !== 0 ? 'Analyse' : 'Aucune analyse'}
        </Typography>
      )}
      <DropDown
        name={name ?? 'actors'}
        value={value}
        options={scrappings}
        onChange={onChange}
        getOptionLabel={
          (scrapping) => (
            `${convertDate(new Date(scrapping.scrapping_date), DATE_NUMBERS_HOURS)}
            ${showDevice ? `- ${scrapping.device_type.name}` : ''}`
          )
        }
        getOptionValue={(scrapping) => scrapping.id ?? 0}
        multiple={multiple}
        emptyOption={emptyOption}
        displayEmptyInList={displayEmptyInList}
        label={label}
        size={size}
        fontSize={fontSize}
        height={height}
        width={width}
        className={className}
        data-testid={dataTestId}
        variant={variant}
        fullWidth={fullWidth}
        disabled={disabled}
      />
    </Box>
  );
}

export default SelectScrapping;
