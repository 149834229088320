import React from 'react';

import { DataGrid } from '@mui/x-data-grid';

import LinearLoadingOverlay from 'components/loading/LinearLoadingOverlay';

import createRankingTypeColumns from './columns';
import useRankingTypes from '../state/useRankingTypes';

function EditRankingTypeDataGrid() {
  const { isLoading, rankingTypes } = useRankingTypes();

  const columns = createRankingTypeColumns();

  return (
    <DataGrid
      rows={rankingTypes}
      columns={columns}
      loading={isLoading}
      pageSizeOptions={[25, 50, 100]}
      getRowHeight={() => 50}
      disableRowSelectionOnClick
      disableColumnMenu
      slotProps={{
        pagination: {
          labelRowsPerPage: ('Nombre par page :'),
        },
      }}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 100,
          },
        },
        sorting: {
          sortModel: [{ field: 'name', sort: 'asc' }],
        },
      }}
      slots={{
        loadingOverlay: LinearLoadingOverlay,
      }}
      sx={{ margin: 0 }}
    />
  );
}

export default EditRankingTypeDataGrid;
