import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import {
  call,
  put,
  takeEvery,
} from 'redux-saga/effects';

import router from 'router/RouterConfig';

import ApiProjects from './api';
import {
  fetchProjects,
  fetchProjectsSuccess,
  fetchProjectsFailure,
  createProjectSuccess,
  createProject,
  createProjectFailure,
  archiveProjectFailure,
  archiveProjectSuccess,
  archiveProject,
  updateProject,
  updateProjectSuccess,
  updateProjectFailure,
  updateUsers,
  updateUsersSuccess,
  updateUsersFailure,
  addUser,
  addUserSuccess,
  addUserFailure,
  removeUser,
  removeUserSuccess,
  removeUserFailure,
  creditProject,
  creditProjectSuccess,
  creditProjectFailure,
  updateProjectManagerAndDircliSuccess,
  updateProjectManagerAndDircliFailure,
  updateProjectManagerAndDircli,
  reactivateProject,
  reactivateProjectSuccess,
  reactivateProjectFailure,
  purgeProject,
  purgeProjectSuccess,
  purgeProjectFailure,
} from './slice';
import {
  Project,
  ProjectCreatePayload,
  ProjectCreditPayload,
  ProjectPurgePayload,
  ProjectUpdateManagerAndDircliPayload,
  ProjectUpdatePayload,
  ProjectUserUpdatePayload,
  UserProject,
} from '../types';

export function* workFetchProjects(action: PayloadAction<boolean | undefined>) {
  const isActive = action.payload === undefined ? true : action.payload;
  try {
    const response: AxiosResponse = yield call(
      ApiProjects.fetchProjects,
      isActive,
    );
    const projects: Project[] = response.data;
    yield put(fetchProjectsSuccess({ projects, isActive }));
  } catch (e: any) {
    yield put(fetchProjectsFailure(e.message));
  }
}

export function* workCreateProject(action: PayloadAction<ProjectCreatePayload>) {
  try {
    const response: AxiosResponse = yield call(
      ApiProjects.createProject,
      action.payload.payload,
    );
    const project = response.data;
    yield put(createProjectSuccess(project));
    router.navigate(action.payload.callbackSuccessUrl);
  } catch (e: any) {
    yield put(createProjectFailure(e.message));
  }
}

export function* workUpdateProject(action: PayloadAction<ProjectUpdatePayload>) {
  try {
    const response: AxiosResponse = yield call(
      ApiProjects.updateProject,
      action.payload.projectId,
      action.payload.payload,
    );
    const project = response.data;
    yield put(updateProjectSuccess(project));
  } catch (e: any) {
    yield put(updateProjectFailure(e.message));
  }
}

export function* workArchiveProject(action: PayloadAction<number>) {
  try {
    const response: AxiosResponse = yield call(ApiProjects.archiveProject, action.payload);
    const project: Project = response.data;
    yield put(archiveProjectSuccess(project.id));
  } catch (e: any) {
    yield put(archiveProjectFailure(e.message));
  }
}

export function* workUpdateProjectUsers(action: PayloadAction<ProjectUserUpdatePayload>) {
  try {
    const response: AxiosResponse = yield call(
      ApiProjects.updateProjectUsers,
      action.payload.projectId,
      action.payload.payload,
    );
    const users = response.data;
    const successPayload = {
      id: action.payload.projectId,
      users,
    };
    yield put(updateUsersSuccess(successPayload));
  } catch (e: any) {
    yield put(updateUsersFailure(e.message));
  }
}

export function* workAddUser(action: PayloadAction<UserProject>) {
  try {
    const response: AxiosResponse = yield call(
      ApiProjects.addUserToProject,
      action.payload,
    );
    const user = response.data;
    const successPayload = {
      id: action.payload.project_id,
      users: [user],
    };
    yield put(addUserSuccess(successPayload));
  } catch (e: any) {
    yield put(addUserFailure(e.message));
  }
}

export function* workRemoveUser(action: PayloadAction<UserProject>) {
  try {
    const response: AxiosResponse = yield call(
      ApiProjects.removeUserFromProject,
      action.payload,
    );

    const users = response.data;
    const successPayload = {
      projectId: action.payload.project_id,
      payload: [users],
    };

    yield put(removeUserSuccess(successPayload));
  } catch (e: any) {
    yield put(removeUserFailure(e.message));
  }
}

export function* workCreditProject(action: PayloadAction<ProjectCreditPayload>) {
  try {
    const response: AxiosResponse = yield call(
      ApiProjects.creditProject,
      action.payload.id,
      action.payload.payload,
    );
    const payload = {
      id: action.payload.id,
      payload: response.data,
    };
    yield put(creditProjectSuccess(payload));
  } catch (e: any) {
    yield put(creditProjectFailure(e.message));
  }
}

export function* workUpdateProjectManagerAndDircli(
  action: PayloadAction<ProjectUpdateManagerAndDircliPayload>,
) {
  try {
    const response: AxiosResponse = yield call(
      ApiProjects.updateProject,
      action.payload.projectId,
      action.payload.payload,
    );
    const project = response.data;
    yield put(updateProjectManagerAndDircliSuccess(project));
  } catch (e: any) {
    yield put(updateProjectManagerAndDircliFailure(e.message));
  }
}

export function* workReactivateProject(
  action: PayloadAction<number>,
) {
  try {
    const response: AxiosResponse = yield call(
      ApiProjects.reactivateProject,
      action.payload,
    );
    const project = response.data;
    yield put(reactivateProjectSuccess(project));
  } catch (e: any) {
    yield put(reactivateProjectFailure(e.message));
  }
}

export function* workPurgeProject(action: PayloadAction<ProjectPurgePayload>) {
  try {
    yield call(ApiProjects.purgeProject, action.payload);
    yield put(purgeProjectSuccess());
  } catch (e: any) {
    yield put(purgeProjectFailure(e.message));
  }
}

export function* sagaFetchProjects() {
  yield takeEvery(fetchProjects, workFetchProjects);
}

export function* sagaCreateProject() {
  yield takeEvery(createProject, workCreateProject);
}

export function* sagaUpdateProject() {
  yield takeEvery(updateProject, workUpdateProject);
}

export function* sagaUpdateProjectManagerAndDircli() {
  yield takeEvery(updateProjectManagerAndDircli, workUpdateProjectManagerAndDircli);
}

export function* sagaArchiveProject() {
  yield takeEvery(archiveProject, workArchiveProject);
}

export function* sagaProjectUpdateUsers() {
  yield takeEvery(updateUsers.type, workUpdateProjectUsers);
}

export function* sagaProjectAddUser() {
  yield takeEvery(addUser.type, workAddUser);
}

export function* sagaProjectRemoveUser() {
  yield takeEvery(removeUser.type, workRemoveUser);
}

export function* sagaCreditProject() {
  yield takeEvery(creditProject, workCreditProject);
}

export function* sagaReactivateProject() {
  yield takeEvery(reactivateProject, workReactivateProject);
}

export function* sagaPurgeProject() {
  yield takeEvery(purgeProject.type, workPurgeProject);
}
