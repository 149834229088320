import React from 'react';

import EditIcon from '@mui/icons-material/Edit';
import FolderOffIcon from '@mui/icons-material/FolderOff';
import GroupIcon from '@mui/icons-material/Group';
import LockOpenIcon from '@mui/icons-material/LockOpen';

import {
  EXTERNAL_USERS,
  MANAGE_PROJECTS,
  USER_ADMIN,
  MANAGE_RANKING_TYPE,
} from 'pages/admin/ROUTES';

import AdminMenuView from './AdminMenuView';
import UserMenu from './types';

function AdminMenu() {
  const iconWidth = 20;
  const adminMenu: UserMenu[] = [
    {
      name: 'Gestion des utilisateurs',
      icon: <GroupIcon sx={{ width: iconWidth }} />,
      link: USER_ADMIN,
      blockedDircli: true,
    },
    {
      name: 'Accès des clients',
      icon: <LockOpenIcon sx={{ width: iconWidth }} />,
      link: EXTERNAL_USERS,
    },
    {
      name: 'Gestion des projets',
      icon: <FolderOffIcon sx={{ width: iconWidth }} />,
      link: MANAGE_PROJECTS,
    },
    {
      name: 'Gestion des types d\'encarts',
      icon: <EditIcon sx={{ width: iconWidth }} />,
      link: MANAGE_RANKING_TYPE,
    },
  ];

  return (
    <AdminMenuView
      menuList={adminMenu}
    />
  );
}

export default AdminMenu;
