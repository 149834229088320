import React, { useState } from 'react';

import {
  Box,
  BoxProps,
  styled,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

import { useAppSelector } from 'common/reduxHooks';
import CheckBoxDataGrid from 'components/checkbox/CheckBoxDataGrid';
import { Project } from 'features/projects/types';
import USER_TYPES from 'features/users/CONSTANTS';
import useInputSearch from 'utils/useInputSearch';

import createProjectColumns from './columns';
import ProjectsDatagridToolbar from './ProjectsDatagridToolbar';
import { selectProjectByStatus } from '../state/slice';

const PREFIX = 'ProjectsDataGrid';

const classes = {
  labels: `${PREFIX}-labels`,
  text: `${PREFIX}-text`,
  icon: `${PREFIX}-icon`,
};

const StyledBox = styled(Box)<BoxProps>(({ theme }) => ({
  [`& .${classes.labels}`]: {
    color: theme.palette.info.main,
    fontSize: theme.fontSize.medium,
  },
  [`& .${classes.text}`]: {
    fontSize: theme.fontSize.medium,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  [`& .${classes.icon}`]: {
    fontSize: theme.fontSize.xlarge,
    marginLeft: '5px',
    color: theme.palette.primary.light,
  },
}));

function ProjectsDataGrid({ isActive = true }: { isActive: boolean }) {
  const { isLoading: loadingProjects } = useAppSelector(
    (state) => state.projects,
  );

  const projects = useAppSelector((state) => selectProjectByStatus(state.projects, isActive));

  const currentUser = useAppSelector((state) => state.authentication.currentUser);

  const sortedProjects = [...projects];
  sortedProjects?.sort((a, b) => a.name.localeCompare(b.name));

  const [showUserProjects, setShowUserProjects] = useState<boolean>(
    currentUser?.user_type_id !== USER_TYPES.admin.id,
  );

  const handleFilter = (allProjects: Project[]) => {
    let filteredProjects = allProjects;
    if (showUserProjects) {
      filteredProjects = filteredProjects.filter(
        (prj) => (prj.project_manager?.id === currentUser?.id)
        || (prj.project_dircli?.id === currentUser?.id)
        || (prj.users.find((user) => user.id === currentUser?.id)),
      );
    }
    return filteredProjects;
  };

  const selectRows = (inputSearch: string) => handleFilter(projects).filter(
    (prj) => (prj.name.toLowerCase().includes(inputSearch.trim().toLowerCase())),
  );

  const {
    handleInputChange,
    filterRows,
  } = useInputSearch(handleFilter(projects), selectRows);

  const columns = createProjectColumns(currentUser, classes, isActive);

  return (
    <StyledBox>
      <ProjectsDatagridToolbar
        handleInputChange={handleInputChange}
        setShowUserProjects={setShowUserProjects}
        showUserProjects={showUserProjects}
        isActive={isActive}
      />
      <DataGrid
        hideFooterSelectedRowCount
        disableRowSelectionOnClick
        disableColumnMenu
        autoHeight
        disableVirtualization
        getRowHeight={() => 50}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 50,
            },
          },
          sorting: {
            sortModel: [{ field: 'name', sort: 'asc' }],
          },
        }}
        slots={{
          baseCheckbox: CheckBoxDataGrid,
        }}
        pageSizeOptions={[25, 50, 100]}
        rows={filterRows()}
        columns={columns}
        slotProps={{
          pagination: {
            labelRowsPerPage: ('Nombre par page :'),
          },
        }}
        localeText={{
          noRowsLabel:
            `Vous n'avez encore créé aucun projet.
            Cliquez sur le bouton "Créer un projet" pour commencer.`,
        }}
        loading={loadingProjects}
      />
    </StyledBox>
  );
}

export default ProjectsDataGrid;
