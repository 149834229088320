import React, { MouseEventHandler, useState } from 'react';

import {
  Box,
  styled,
  Grid2 as Grid,
  Grid2Props as GridProps,
} from '@mui/material';

import MainBox from 'components/layout/MainBox';
import BarChartComponent from 'features/dataviz/components/generic/charts/BarChart';
import SeoPerformanceTooltipComponent
  from 'features/dataviz/components/generic/charts/common/SeoPerformanceTooltip';
import TitleComponent from 'features/dataviz/components/generic/charts/common/Title';
import PerformanceLegend from 'features/dataviz/components/generic/charts/legend/PerformanceLegend';
import { DataType } from 'features/dataviz/utils/types';
import { GRAPH_TYPES, METRICS } from 'features/performance/CONSTANT';

import PerformanceChartOptions from './PerformanceChartOptions';
import { DataInfos } from '../../types';

const PREFIX = 'seo-performance-bar-chart';

const classes = {
  container: `${PREFIX}-container`,
  button: `${PREFIX}-button`,
  legendCtn: `${PREFIX}-legendCtn`,
};

const StyledGrid = styled(Grid)<GridProps>(({ theme }) => ({
  [`& .${classes.container}`]: {
    height: '350px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  [`& .${classes.button}`]: {
    marginLeft: theme.spacing(1),
  },
  [`& .${classes.legendCtn}`]: {
    height: '310px',
  },
}));

interface SeoPerformanceBarChartProps {
  datas: DataInfos,
  /*
   * return value from `formatByActorsNotoriety` or `formatByActorsData` function
   */
  formattedData: any[],
  title: string | JSX.Element,
  link: string | undefined,
  isPageLoading: boolean | undefined,
  isLoading: boolean | undefined,
  size: string | undefined,
  isChartWithoutDatas?: Function,
  setBarColor: (actor: any) => string,
  performanceMetric: string,
  generateDatakeys: Function,
  graphType: keyof typeof GRAPH_TYPES,
  missingValues?: string[],
  handleExport: MouseEventHandler<HTMLElement> | Function | undefined,
  isExportLoading: boolean | undefined,
  keySubject: keyof DataType,
  noData?: boolean,
}

function SeoPerformanceBarChart(props: SeoPerformanceBarChartProps) {
  const {
    datas,
    formattedData,
    title,
    link,
    isPageLoading,
    isLoading,
    size,
    isChartWithoutDatas,
    setBarColor,
    performanceMetric,
    generateDatakeys,
    graphType,
    missingValues,
    handleExport,
    isExportLoading,
    keySubject,
    noData = false,
  } = props;

  const [chartOptions, setChartOptions] = useState({
    labels: false,
    percents: false,
  });

  const handleChange = (e: any) => {
    setChartOptions({
      ...chartOptions,
      [e.target.name]: e.target.checked,
    });
  };

  let paddedValues = [];
  if (datas.data.values && !noData) {
    paddedValues = formattedData.sort((v1, v2) => v1[keySubject].localeCompare(v2[keySubject]));

    while (paddedValues && paddedValues.length < 5) {
      paddedValues.push(undefined);
    }
  }

  const dataKeys = generateDatakeys(formattedData);

  const getLabel = (plurial: string) => (
    performanceMetric === METRICS[1] && `demande${plurial}`
  )
  || (
    performanceMetric === METRICS[2] && `visite${plurial} estimée${plurial}`
  )
  || `position${plurial}`;

  const labels = getLabel('s');

  return (
    <StyledGrid container spacing={2}>
      <Grid size={8}>
        <MainBox>
          <Box className={classes.container}>
            <TitleComponent title={title} />
            {
            datas.data.values && datas.data.values.length > 0
            && (
              <BarChartComponent
                datas={paddedValues}
                title=""
                link={link}
                dataKeys={dataKeys}
                xAxisDatakey={keySubject}
                setBarColor={setBarColor}
                isLoading={isLoading}
                isPageLoading={isPageLoading}
                tooltipContent={(
                  <SeoPerformanceTooltipComponent
                    datavizMode={performanceMetric}
                    percentLabel={chartOptions.percents}
                  />
                )}
                size={size}
                withTooltip
                dataObject={datas}
                isChartWithoutDatas={isChartWithoutDatas}
                showLegend
                legendAngle={0}
                centerLabelName={chartOptions.labels}
                percentLabel={chartOptions.percents}
                truncateTickLabel={false}
              />
            )
            }
          </Box>
        </MainBox>
      </Grid>
      <Grid size={4}>
        <MainBox>
          <Box className={classes.legendCtn}>
            <PerformanceLegend
              datas={datas}
              noData={noData}
              missingValues={missingValues}
              title="CLASSEMENT"
              isPageLoading={isPageLoading}
              isLoading={isLoading}
              setBarColor={setBarColor}
              label={getLabel('')}
              labelPlural={labels}
              performanceMetric={performanceMetric}
              graphType={graphType}
              keySubject={keySubject}
            />
          </Box>
          <PerformanceChartOptions
            values={chartOptions}
            handleChange={handleChange}
            handleExport={handleExport}
            isExportLoading={isExportLoading}
          />
        </MainBox>
      </Grid>
    </StyledGrid>
  );
}

export default SeoPerformanceBarChart;
