import { RankingEvolutionFilters } from 'features/serpAnalysis/types';

import { DatavizFilters } from '../types';

const generateChartKey = (
  label: string,
  rankingTypeIds: number[],
  idSerpAnalysis: number,
  periode: string,
  scrappingId?: number | undefined,
  datavizFilters?: DatavizFilters | RankingEvolutionFilters | undefined,
) => {
  let optionalFilters;

  switch (datavizFilters?.type) {
    case 'datavizFilters':
      optionalFilters = (
        datavizFilters.rankMax
        + datavizFilters.rankMin
        + datavizFilters.searchIntentId
        + datavizFilters.sitesCount
        + datavizFilters.volMax
        + datavizFilters.volMin
        + datavizFilters.bestRankMode
        + datavizFilters.deviceTypeId
        + datavizFilters.serpAnalysisScrappingIds
        + datavizFilters.actorIds
        + datavizFilters.periodeBegin
        + datavizFilters.periodeEnd
      );
      break;
    case 'rankingEvolutionFilters':
      optionalFilters = (
        datavizFilters.rankingTypeId.join('')
        + (datavizFilters.actorIds && datavizFilters.actorIds.join(''))
        + datavizFilters.periodeBegin
        + datavizFilters.periodeEnd
        + datavizFilters.intentionIds.join(',')
        + datavizFilters.timeSeries
        + datavizFilters.rankSliceIds.join('')
        + datavizFilters.deviceTypeId
        + datavizFilters.keywordIds.join(',')
        + (datavizFilters.categoryIds && datavizFilters.categoryIds.join(','))
        + (datavizFilters.serpAnalysisIds && datavizFilters.serpAnalysisIds.join(''))
        + datavizFilters.bestRankMode
        + datavizFilters.includeUncategorized
      );
      break;
    default:
      optionalFilters = '';
  }

  return (
    label
        + rankingTypeIds.toString()
        + idSerpAnalysis
        + periode
        + (scrappingId ?? '')
        + optionalFilters
  );
};

export default generateChartKey;
