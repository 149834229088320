import React, { useEffect, useState } from 'react';

import DownloadIcon from '@mui/icons-material/Download';
import { Box, Button, Typography } from '@mui/material';

import Modal from 'components/modal/Modal';

type UrlsUrlsByActorDownloadButtonProps = {
  onClick: React.MouseEventHandler<HTMLElement> | Function | undefined,
  isExportLoading: boolean | undefined,
  disabled?: boolean,
}

function UrlsByActorDownloadButton(props: UrlsUrlsByActorDownloadButtonProps) {
  const {
    onClick,
    isExportLoading,
    disabled = false,
  } = props;

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [isPostDownload, setPostDownload] = useState<boolean>(false);

  const handleConfirm = (e: any) => {
    if (onClick) onClick(e);
    setPostDownload(true);
  };

  useEffect(() => {
    if (isPostDownload && !isExportLoading) {
      setOpenModal(false);
      setPostDownload(false);
    }
  }, [isExportLoading, isPostDownload]);

  return (
    <Box>
      <Button
        size="small"
        variant="contained"
        startIcon={<DownloadIcon />}
        disabled={disabled}
        onClick={() => { if (!openModal) setOpenModal(true); }}
        data-testid="urls-by-actor-download-button"
      >
        Télécharger
      </Button>
      <Modal
        title={(
          <Typography>
            <Typography component="span" variant="bold">Télécharger</Typography>
            {' '}
            toutes les URL du projet
          </Typography>
        )}
        actionConfirm={handleConfirm}
        actionCancel={() => { setOpenModal(false); setPostDownload(false); }}
        isOpen={openModal}
        dataTestId="export-urls-by-actor-modal"
        displayLoading={isExportLoading}
        isProcessLoading={isExportLoading}
      />
    </Box>
  );
}

export default UrlsByActorDownloadButton;
