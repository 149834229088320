import React, { useState, useEffect } from 'react';

import { Box, Typography, MenuItem } from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';

import { useAppDispatch } from 'common/reduxHooks';
import globalTheme from 'common/theme';
import Modal from 'components/modal/Modal';
import { purgeProject } from 'features/projects/state/slice';

import { ProjectPurgePayload } from '../types';

type PurgeProjectProps = {
  id: number,
  handleCloseMenu: Function,
}

function PurgeProject (props: PurgeProjectProps) {
  const { id, handleCloseMenu } = props;
  const dispatch = useAppDispatch();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [date, setDate] = useState<string | undefined>(moment().format());

  const handleConfirm = () => {
    const payload: ProjectPurgePayload = {
      id,
      target_date: date || '',
    };
    dispatch(purgeProject(payload));
    setOpenModal(false);
  };

  useEffect(() => {
    setDate(moment().format());
  }, [openModal]);

  return (
    <Box>
      <MenuItem
        sx={{ fontSize: globalTheme.fontSize.middlesmall }}
        onClick={
          () => { if (!openModal) setOpenModal(true); handleCloseMenu(); }
        }
      >
        Purger
      </MenuItem>
      <Modal
        title={(
          <Typography data-testid="modal-purge-project">
            <Typography component="span" variant="bold">Purger les données</Typography>
            {' '}
            de ce projet
          </Typography>
        )}
        actionConfirm={handleConfirm}
        actionCancel={() => setOpenModal(false)}
        isOpen={openModal}
      >
        <LocalizationProvider
          dateAdapter={AdapterMoment}
          adapterLocale={moment.locale('fr')}
        >
          <DateTimePicker
            label="Données antérieures au"
            value={moment(date)}
            onChange={(value) => setDate(value?.format())}
            format="DD/MM/YYYY HH:mm"
            maxDateTime={moment()}
            ampm={false}
            sx={{ width: '50%' }}
            slotProps={{
              textField: {
                sx: { marginY: 1, width: 1 },
                size: 'small',
                label: 'Données antérieures au',
                error: false,
                InputProps: {
                  sx: {
                    fontSize: globalTheme.fontSize.medium,
                  },
                },
              },
              layout: {
                sx: {
                  '.MuiMultiSectionDigitalClock-root': {
                    width: '144px',
                    '.MuiMultiSectionDigitalClockSection-root': {
                      width: '70px',
                    },
                    '.MuiMenuItem-root': {
                      width: '65px',
                      fontSize: globalTheme.fontSize.xsmall,
                    },
                  },
                },
              },
            }}
            data-testid="purge-modal-datepicker"
          />
        </LocalizationProvider>
      </Modal>
    </Box>
  );
}

export default PurgeProject;
