import React from 'react';
import { NavLink } from 'react-router-dom';

import {
  Box,
  Stack,
  Typography,
} from '@mui/material';
import pictoVideXL from 'assets/images/picto_vide.jpg';

import globalTheme from 'common/theme';
import MainBox from 'components/layout/MainBox';
import { Project } from 'features/projects/types';
import { ANALYSIS_STATUS } from 'features/serpAnalysis/CONSTANT';
import { generateProjectBaseUrl } from 'utils/urlManipulation';

import TitleComponent from '../generic/charts/common/Title';

type EmptyGroupByTypeAndPeriodsProps = {
  title: string | JSX.Element,
  analysisName?: string,
  project: Project | undefined,
  hasData: boolean,
  dataKeysCount: number,
}

function EmptyGroupByTypeAndPeriods(props: EmptyGroupByTypeAndPeriodsProps) {
  const {
    title,
    analysisName,
    project,
    hasData,
    dataKeysCount,
  } = props;

  const hasScrappings = project?.project_analyses.find(
    (anl) => anl.serp_analysis_status_id === ANALYSIS_STATUS.SUCCESS,
  ) !== undefined;

  return (
    <MainBox
      BoxProps={{
        textAlign: 'center',
        alignContent: 'center',
        height: '100%',
        position: 'relative',
      }}
      data-testid="empty-group-by-type-and-periods"
    >
      <Stack
        sx={{ height: '100%' }}
        alignItems="center"
        justifyContent="space-between"
      >
        <Box sx={{ marginBottom: '.5rem' }}>
          <TitleComponent title={title} />
        </Box>
        <Box
          component="img"
          src={pictoVideXL}
          alt="contenu dataviz vide"
          sx={{
            maxHeight: '320px',
            height: '60%',
            width: 'auto',
          }}
        />
        { ((!analysisName || !hasScrappings) && (
        <Typography
          data-testid="physionomy-history-no-data-help-text"
          fontSize={globalTheme.fontSize.mediumLarge}
        >
          Pour identifier
          <Typography
            component="span"
            color={globalTheme.palette.primary.light}
            variant="bold"
            sx={{ fontSize: '1.1rem' }}
          >
            {' '}
            des opportunités de visibilité
          </Typography>
          {' '}
          , vous devez
          {' '}
          <NavLink
            to={
              generateProjectBaseUrl(project?.id || '', project?.name || '')
            }
            style={{
              color: globalTheme.palette.primary.main,
              textDecoration: 'underline',
            }}
          >
            planifier
          </NavLink>
          {' '}
          votre première analyse.
        </Typography>
        )) || ((hasData && dataKeysCount === 0) && (
          <Typography
            data-testid="physionomy-history-no-data-help-text"
            fontSize={globalTheme.fontSize.mediumLarge}
          >
            Les paramètres que vous avez choisis n&apos;ont généré aucun résultat.
          </Typography>
        )) || (
          <Typography
            data-testid="physionomy-history-no-data-help-text"
            fontSize={globalTheme.fontSize.mediumLarge}
          >
            Pour identifier
            <Typography
              component="span"
              color={globalTheme.palette.primary.light}
              variant="bold"
              sx={{ fontSize: '1.1rem' }}
            >
              {' '}
              les opportunités de visibilité
            </Typography>
            {' '}
            de l&apos;étude des mots-clés
            <Typography
              component="span"
              variant="bold"
            >
              {' '}
              “
              {analysisName}
              ”
            </Typography>
            , lancez une analyse via le formulaire ci-contre.
          </Typography>
        )}
      </Stack>
    </MainBox>
  );
}

export default EmptyGroupByTypeAndPeriods;
