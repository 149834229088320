import { axiosAuthInstance } from 'common/axiosAuthInstance';

import { RankingTypeType, RankingTypeUpdatePayload } from '../types';

export default class ApiRankingTypes {
  static fetchRankingTypes() {
    return axiosAuthInstance.get<never, RankingTypeType[]>('ranking_types/');
  }

  static updateRankingTypes(payload: RankingTypeUpdatePayload) {
    return axiosAuthInstance.put<RankingTypeType>(
      `ranking_types/${payload.id}`,
      payload.rankingTypeUpdate,
    );
  }
}
