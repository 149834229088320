import * as yup from 'yup';

import { UpdateRankingTypeForm, RankingTypeType } from '../types';

export const initialValuesEditRt = (rankingType: RankingTypeType): UpdateRankingTypeForm => ({
  custom_name: rankingType.custom_name ?? '',
  description: rankingType.description ?? '',
  dataviz_color: rankingType.dataviz_color ?? undefined,
  ranking_type_group_id: rankingType.ranking_type_group_id ?? undefined,
});

export const editRankingTypeValidationSchema = () => yup.object().shape({
  custom_name: yup
    .string()
    .trim()
    .required('Ce champ est obligatoire')
    .max(50, '50 caractères maximum'),
  description: yup
    .string()
    .trim()
    .required('Ce champ est obligatoire')
    .max(250, '250 caractères maximum'),
  dataviz_color: yup
    .string()
    .required('Ce champ est obligatoire')
    .max(15, 'Code couleur non valide'),
  ranking_type_group_id: yup.number().required('Ce champ est obligatoire'),
});
