import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Grid2, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import moment from 'moment';

import { useAppSelector } from 'common/reduxHooks';
import globalTheme from 'common/theme';
import TabsLink from 'components/link/TabsLink';
import DatavizContainer from 'features/dataviz/components/generic/DatavizContainer';
import { METRIC } from 'features/dataviz/CONSTANTS';
import datavizConfig from 'features/dataviz/datavizConfig';
import { DatavizFilters } from 'features/dataviz/types';
import { Project } from 'features/projects/types';
import useRankingTypes from 'features/rankingTypes/state/useRankingTypes';
import { selectAnalysisById } from 'features/serpAnalysis/state/slice';
import useSerpAnalyses from 'features/serpAnalysis/state/useSerpAnalyses';
import { Analysis } from 'features/serpAnalysis/types';
import PhysionomyHistoryFiltersContainer
  from 'features/serpPhysionomy/components/PhysionomyHistoryFiltersContainer';
import PhysionomyHistoryTitle from 'features/serpPhysionomy/components/PhysionomyHistoryTitle';
import PhysionomySummary from 'features/serpPhysionomy/components/PhysionomySummary';
import { PhysionomyHistoryFiltersForm } from 'features/serpPhysionomy/types';
import { convertDate, DATE_NUMBERS_HOURS_SC } from 'utils/formatDate';
import { generateAnalysisUrl, splitIdName } from 'utils/urlManipulation';

import getPhysionomyNavigation from './physionomyNavigation';
import { SERP_PHYSIONOMY_HISTORY } from '../ROUTES';

type SerpPhysionomyHistoryProps = {
  project: Project,
}

const corespondanceRankingType = {
  organic: { feature: undefined },
  featured_snippet: { feature: 'fs' },
  people_also_ask: { feature: 'paa' },
  images: { feature: 'image' },
  video: { feature: 'video' },
  local_pack: { feature: 'local' },
};

const generateFeatureLink = (
  project: Project,
  analysis?: Analysis,
) => Object.keys(corespondanceRankingType).reduce((acc, cur) => {
  acc[cur] = generateAnalysisUrl(
    project.id,
    project.name,
    analysis?.id ?? 0,
    analysis?.label ?? '',
    corespondanceRankingType[cur as keyof typeof corespondanceRankingType].feature,
    undefined,
  );
  return acc;
}, {} as Record<string, string>);

function SerpPhysionomyHistory(props: SerpPhysionomyHistoryProps) {
  const { project } = props;

  const { idNameAnalysis } = useParams();
  const { id: idAnalysis } = splitIdName(idNameAnalysis);

  const scopeId = project.scopes[0].id;

  const { isLoading, serpAnalyses: analyses } = useSerpAnalyses({ scopeId });
  const { rankingTypes } = useRankingTypes();

  const currentAnalysis = useMemo(() => {
    const sortedAnalyses = [...analyses].sort((a, b) => a.label.localeCompare(b.label));

    return idAnalysis || sortedAnalyses[0]?.id || '';
  }, [idAnalysis, analyses]);

  const serpAnalysis = useAppSelector((state) => (
    selectAnalysisById(state.serpAnalyses, (currentAnalysis))));

  const defaultDatavizFilters: DatavizFilters = {
    deviceTypeId: serpAnalysis?.device_type_id ?? 1,
    bestRankMode: false,
    rankMin: '1',
    rankMax: '20',
    periodeBegin: undefined,
    periodeEnd: undefined,
    timeSeries: 'day',
    type: 'datavizFilters',
    volMax: '',
    volMin: '',
  };

  const [datavizFilters, setDatavizFilters] = useState<DatavizFilters>(defaultDatavizFilters);

  const [showPercentage, setShowPercentage] = useState(false);
  const [showValue, setShowValue] = useState(false);
  const [isInformationsDisabled, setIsInformationsDisabled] = useState(true);
  const [rankingTypeIdsUsed, setRankingTypeIdsUsed] = useState<number[]>([]);
  const [hideRankingTypeIds, setHideRankingTypeIds] = useState<number[]>([]);
  const [hideEmptyDays, setHideEmptyDays] = useState(false);

  const handleFiltersSubmit = (filters: PhysionomyHistoryFiltersForm) => {
    const scrappingIds: number[] = [];

    setDatavizFilters((prev) => ({
      ...prev,
      rankMax: filters.rankLimit.toString(),
      deviceTypeId: filters.deviceId,
      serpAnalysisScrappingIds: scrappingIds,
      periodeBegin: convertDate(
        moment(filters.periode).startOf('month').toDate(),
        DATE_NUMBERS_HOURS_SC,
      ),
      periodeEnd: convertDate(
        moment(filters.periode).endOf('month').toDate(),
        DATE_NUMBERS_HOURS_SC,
      ),
    }));
    setShowPercentage(filters.showPercentage);
    setShowValue(filters.showValues);
  };

  const handleFiltersChanged = (filters: PhysionomyHistoryFiltersForm) => {
    setShowPercentage(filters.showPercentage);
    setShowValue(filters.showValues);
  };

  const handleDataFetch = (data: any[]) => {
    if (isInformationsDisabled) setIsInformationsDisabled(false);
    setRankingTypeIdsUsed(
      rankingTypes
        .filter((rt) => data.find((d) => d.ranking_type_id === rt.id && d.count > 0))
        .map((rt) => rt.id),
    );
  };

  const onReset = () => {
    setIsInformationsDisabled(true);
    setDatavizFilters(defaultDatavizFilters);
    setRankingTypeIdsUsed([]);
    setHideRankingTypeIds([]);
    setHideEmptyDays(false);
  };

  useEffect(() => {
    onReset();
  }, [serpAnalysis, project]);

  return (
    <Box>
      <Grid2 container columnSpacing={2} size={12}>
        <Grid2 size={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Typography variant="bold" sx={{ fontSize: globalTheme.fontSize.middlesmall }}>
            Que voulez-vous visualiser ?
          </Typography>
        </Grid2>
        <Grid2 size={10}>
          <TabsLink
            links={getPhysionomyNavigation(
              project,
              serpAnalysis,
            )}
            defaultValue={SERP_PHYSIONOMY_HISTORY}
          />
        </Grid2>
        <Grid2 size={2} id="physionomie-filters-container">
          <PhysionomyHistoryFiltersContainer
            serpAnalysis={serpAnalysis}
            rankingTypeIdsUsed={rankingTypeIdsUsed}
            hideRankingTypeIds={hideRankingTypeIds}
            setHideRankingTypeIds={setHideRankingTypeIds}
            hideEmptyDays={hideEmptyDays}
            setHideEmptyDays={setHideEmptyDays}
            isLoading={isLoading}
            onSubmit={handleFiltersSubmit}
            onFiltersChanged={handleFiltersChanged}
            disabledInformations={isInformationsDisabled}
            onFiltersReset={onReset}
            featureLink={generateFeatureLink(project, serpAnalysis)}
          />
        </Grid2>
        <Grid2 size={10}>
          <Stack>
            <DatavizContainer
              datavizComp={datavizConfig[METRIC.count_group_by_ranking_type_and_periods.label]}
              exportName="share_of_voice_results.csv"
              idScope={scopeId}
              periode=""
              title={(
                <PhysionomyHistoryTitle />
              )}
              datavizFilters={datavizFilters}
              idSerpAnalysis={serpAnalysis?.id}
              analysis={serpAnalysis}
              containerAspect={1}
              height={
                isInformationsDisabled || rankingTypeIdsUsed.length === hideRankingTypeIds.length
                  ? '600px'
                  : '100%'
              }
              showPercentage={showPercentage}
              showValue={showValue}
              hideRankingTypeIds={hideRankingTypeIds}
              hideEmptyDays={hideEmptyDays}
              onDataFetch={handleDataFetch}
              project={project}
            />
            {
              (!isInformationsDisabled
              && rankingTypeIdsUsed.length !== hideRankingTypeIds.length) && (
                <PhysionomySummary
                  analysis={serpAnalysis}
                  isComparison={false}
                  periodeBegin={datavizFilters.periodeBegin}
                  periodeEnd={datavizFilters.periodeEnd}
                  deviceTypeId={datavizFilters.deviceTypeId}
                  searchEngine={serpAnalysis?.search_engine.name}
                />
              )
            }
          </Stack>
        </Grid2>
      </Grid2>
    </Box>
  );
}

export default SerpPhysionomyHistory;
