import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useAppSelector } from 'common/reduxHooks';
import { BASE_DATAVIZ_REQUEST, METRIC, RESSOURCE } from 'features/dataviz/CONSTANTS';
import {
  fetchChartData, fetchExportData, selectDatasByKey, selectExportByKey,
} from 'features/dataviz/state/slice';
import {
  DataInfos,
  DatavizComponentProps,
  DatavizRequest,
} from 'features/dataviz/types';
import generateChartKey from 'features/dataviz/utils/chartKey';
import { serpAnalysisDetailsRouting } from 'pages/projects/serpDetails/config';

import getDatavizRequestPayload from '../utils/datavizRequestPayload';
import formatExportName from '../utils/formatExportName';

function useFetchAnalysis(props: DatavizComponentProps) {
  const {
    idScope,
    datavizComp,
    rankingTypeIds = [],
    idSerpAnalysis,
    periode,
    analysis,
    project,
    scrappingId,
    datavizFilters,
    exportName,
    onDataFetch,
  } = props;

  const [chartKey, setChartKey] = useState<string>('');
  const [exportKey, setExportKey] = useState<string>('');

  const dispatch = useDispatch();

  useEffect(() => {
    setChartKey(generateChartKey(
      datavizComp.label,
      rankingTypeIds,
      idSerpAnalysis || 0,
      periode,
      scrappingId,
      datavizFilters,
    ));
  }, [
    datavizComp.label,
    rankingTypeIds,
    idSerpAnalysis,
    periode,
    scrappingId,
    datavizFilters,
  ]);

  const chartData = useAppSelector((state) => (
    selectDatasByKey(state.dataviz, chartKey)));

  useEffect(() => {
    setExportKey(generateChartKey(
      datavizComp.exportLabel || '',
      rankingTypeIds,
      idSerpAnalysis || 0,
      '',
      scrappingId,
      datavizFilters,
    ));
  }, [
    datavizComp.exportLabel,
    rankingTypeIds,
    idSerpAnalysis || 0,
    scrappingId,
    datavizFilters,
  ]);

  const currentExport = useAppSelector((state) => (
    selectExportByKey(state.dataviz, exportKey)));

  const featureLabel = serpAnalysisDetailsRouting.find((rankingType) => (
    rankingType.type === rankingTypeIds[0]
  ))?.exportLabel;

  const handleExport = () => {
    const exportRequestValues = datavizComp.export
      ? [datavizComp.export]
      : datavizComp.metrics;

    const payload: DatavizRequest = {
      key: exportKey,
      exportName: formatExportName(
        datavizComp.export,
        datavizFilters,
        project,
        featureLabel,
        analysis?.label,
        exportName,
      ),
      requests: exportRequestValues.map(
        (metric) => ({
          resource: metric.resource,
          metric: metric.metric,
          payload: {
            ...BASE_DATAVIZ_REQUEST,
            scope_id: idScope,
            ranking_type_ids: rankingTypeIds,
            serp_analysis_ids: idSerpAnalysis ? [idSerpAnalysis] : [],
            sep: 'semicolon',
          },
        }),
      ),
    };

    payload.requests = getDatavizRequestPayload(
      payload.requests,
      true,
      periode,
      datavizFilters,
    );

    dispatch(fetchExportData(payload));
  };

  useEffect(() => {
    /*
       * If there is no data for this key, we launch the action
       * to call the API
    */
    if (
      !chartData && chartKey && (
        idSerpAnalysis
        || datavizComp.metrics.find(
          (m) => m.resource === RESSOURCE.seo_performance,
        ) || ([
          METRIC.count_group_by_ranking_type_and_periods.label,
          METRIC.count_group_by_ranking_type.label].includes(datavizComp.label))
      )
      && idScope
    ) {
      const payload: DatavizRequest = {
        key: chartKey,
        requests: datavizComp.metrics.map(
          (metric) => ({
            resource: metric.resource,
            metric: metric.metric,
            payload: {
              ...BASE_DATAVIZ_REQUEST,
              scope_id: idScope,
              ranking_type_ids: rankingTypeIds,
              serp_analysis_ids: idSerpAnalysis ? [idSerpAnalysis] : [],
              serp_analysis_scrapping_ids: scrappingId ? [scrappingId] : [],
              periode: periode === '' ? undefined : periode,
            },
          }),
        ),
      };

      payload.requests = getDatavizRequestPayload(
        payload.requests,
        false,
        periode,
        datavizFilters,
      );

      dispatch(fetchChartData(payload));
    }
  }, [
    fetchChartData,
    idScope,
    datavizComp.label,
    chartKey,
    chartData,
    datavizFilters,
  ]);

  useEffect(() => {
    if (chartData?.data?.values?.length) onDataFetch?.(chartData.data.values);
  }, [chartData]);

  const isChartWithoutDatas = (dataSet: DataInfos) => {
    if (!dataSet?.data?.values) return false; // Means data is still loading
    if (dataSet.data?.values && dataSet.data.values[0] !== undefined) {
      return !(
        Object.values(dataSet.data.values[0]).some((value) => value !== null)
      );
    }
    return true;
  };
  const showChart = (chartData
  && (
    (chartData.data?.values
      && !chartData.error
      && !(isChartWithoutDatas(chartData)))
      || (chartData
        && datavizComp.label === METRIC.count_by_ranking_type_group_by_domain.label)
      || (chartData
        && datavizComp.label === METRIC.get_all_by_actors.label)
      || (chartData
        && datavizComp.label === METRIC.get_all_by_ranking_type.label)
      || (chartData
        && datavizComp.label === METRIC.get_all_by_url_categories.label)
        || (chartData
      && datavizComp.label === METRIC.count_group_by_ranking_type.label)
        || (chartData
      && datavizComp.label === METRIC.count_group_by_ranking_type_and_periods.label)
  ));

  return {
    chartData,
    showChart,
    chartKey,
    exportKey,
    currentExport,
    handleExport,
    isChartWithoutDatas,
    isMonthly: datavizFilters?.type === 'rankingEvolutionFilters'
      && datavizFilters?.timeSeries === 'month',
  };
}

export default useFetchAnalysis;
