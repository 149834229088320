import React, { useState } from 'react';

import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  StackProps,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { FormikProps, useFormik } from 'formik';
import moment from 'moment';

import { useAppSelector, useAppDispatch } from 'common/reduxHooks';
import globalTheme from 'common/theme';
import Modal from 'components/modal/Modal';
import { ActorType } from 'features/actors/types';
import { DEVICE_TYPE } from 'features/deviceType/CONSTANTS';
import KeywordAutocomplete from 'features/keywords/components/KeywordAutocomplete';
import { SerpAnalysisKeyword } from 'features/keywords/types';
import RankingTypesDropDown from 'features/rankingTypes/components/RankingTypesDropDown';
import { BENCHMARK_ONLY_RANKING_TYPES } from 'features/rankingTypes/CONSTANTS';
import { RANK_SLICES } from 'features/rankSlice/CONSTANT';
import { selectSerpAnalysesByScopeId } from 'features/serpAnalysis/state/slice';

import {
  getActorNameFromActorId,
  getIntentNameFromIntentId,
  getSliceNameFromSliceId,
} from './utils';
import { KPI_VIEW_TYPE } from '../../CONSTANTS';
import KpiView from '../../KpiView';
import { ModifyKpiViewFormType, KpiViewCreateUpdate, KpiViewType } from '../../types';
import { initValuesModifyView, validationSchemaModifyView } from '../FormConfig/FormModifyView';

const PREFIX = 'ModifyKpiView';

const classes = {
  columnFilter: `${PREFIX}-columnFilter`,
  select: `${PREFIX}-select`,
  inputSelectMultiple: `${PREFIX}-inputSelectMultiple`,
  mediumSelect: `${PREFIX}-mediumSelect`,
  inputLabel: `${PREFIX}-inputLabel`,
  radioGroup: `${PREFIX}-radioGroup`,
  adjustRadio: `${PREFIX}-adjustRadio`,
  adjustRadio2: `${PREFIX}-adjustRadio2`,
};

const StyledStack = styled(Stack)<StackProps>(({ theme }) => ({
  [`& .${classes.select}`]: {
    height: '2rem',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },

  [`& .${classes.inputSelectMultiple}`]: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },

  [`& .${classes.mediumSelect}`]: {
    height: '2rem',
    fontSize: theme.fontSize.xsmall,
  },

  [`& .${classes.columnFilter}`]: {
    flex: 1,
  },

  [`& .${classes.radioGroup}`]: {
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '300px',
    height: '2rem',
  },

  [`& .${classes.adjustRadio}`]: {
    marginRight: '11.3%',
  },

  [`& .${classes.adjustRadio2}`]: {
    marginRight: '15.7%',
  },
}));

type ModifyViewProps = {
  analysisId: number,
  scopeId: number,
  kpiView: KpiViewType,
  analysisDeviceTypeId: number,
  actors: ActorType[],
  intentList: Array<{
    id: number,
    label: string,
  }>,
  handleCloseMenu: Function,
  isCurrentUserExternal?: boolean,
  disabled?: boolean
}

export default function ModifyViewModal (props: ModifyViewProps): JSX.Element {
  const {
    analysisId,
    scopeId,
    kpiView,
    analysisDeviceTypeId,
    actors,
    intentList,
    handleCloseMenu,
    isCurrentUserExternal = false,
    disabled,
  } = props;
  const [openModal, setOpenModal] = useState<boolean>(false);
  const kpiViewObj = kpiView && new KpiView(kpiView);
  const dispatch = useAppDispatch();

  const serpAnalyses = useAppSelector((state) => (
    selectSerpAnalysesByScopeId(state.serpAnalyses, scopeId)));

  const handleSubmit = (values: ModifyKpiViewFormType) => {
    const newKpiView: KpiViewCreateUpdate = {
      label: values.viewName,
      time_serie: values.timeSerie,
      start_date: values.startDate,
      scope_id: scopeId,
      kpi_view_export_id: (values.export && !isCurrentUserExternal) ? 2 : 1,
      kpi_view_export_status_id: 1,
      kpi_view_type_id: kpiView.kpi_view_type_id,
      best_rank: false,
      kpi_view_categories: [],
      device_type_id: values.deviceType,
      kpi_view_serp_analyses: values.serpAnalysisIds.map((id) => ({
        serp_analysis_id: id,
      })),
      kpi_view_actors: (
        values.actors.map((id: number) => ({
          actor_id: id,
        }))
      ),
      kpi_view_keywords: (
        values.serpAnalysisIds.length <= 1
          ? values.keywords.map((id) => ({
            keyword_id: id,
          }))
          : []
      ),
      kpi_view_sa_rank_slices: (
        values.ranks.map((id) => ({
          sa_rank_slice_id: id,
        }))
      ),
      kpi_view_sa_keyword_intentions: (
        values.serpAnalysisIds.length <= 1
          ? values.searchIntentions.map((id) => ({
            sa_keyword_intention_id: id,
          }))
          : []
      ),
      kpi_view_ranking_types: (
        (
          values.rankingTypes.length > 0
        )
          ? values.rankingTypes.map((id) => ({
            ranking_type_id: id,
          }))
          : [{
            ranking_type_id: 1,
          }]
      ),
    };
    kpiViewObj.update(dispatch, newKpiView);
    setOpenModal(false);
  };

  const formik: FormikProps<ModifyKpiViewFormType> = useFormik<ModifyKpiViewFormType>({
    enableReinitialize: true,
    initialValues: initValuesModifyView(kpiView),
    validationSchema: validationSchemaModifyView,
    onSubmit: handleSubmit,
  });

  const handleChangeSelectMultiple = (e: any, selected: Array<any>, allOpt: Array<any>) => {
    const { target: { name, value } } = e;
    if (value[value.length - 1] === 'all') {
      formik.setValues(
        {
          ...formik.values,
          [name]: selected.length === allOpt.length ? [] : allOpt,
        },
      );
      return;
    }
    formik.setValues(
      {
        ...formik.values,
        [name]: value,
      },
    );
  };

  const handleChangeAutocomplete = (
    name: string,
    value: (SerpAnalysisKeyword | string)[],
    selected: SerpAnalysisKeyword[],
    allOpt: SerpAnalysisKeyword[],
  ) => {
    if (typeof value[value.length - 1] === 'string') {
      formik.setValues(
        {
          ...formik.values,
          [name]: selected.length === allOpt.length
            ? []
            : allOpt.map((keyword) => keyword.keyword_id),
        },
      );
    } else {
      formik.setValues({
        ...formik.values,
        [name]: value.map((val) => (
          typeof val === 'string'
            ? 'all'
            : val.keyword_id
        )),
      });
    }
  };

  const handleChangeRankingType = (e: any) => {
    const { target: { value } } = e;

    formik.setValues({
      ...formik.values,
      rankingTypes: value,
    });
  };

  return (
    <Box>
      <MenuItem
        onClick={() => { setOpenModal(true); handleCloseMenu(); }}
        disabled={disabled}
      >
        <Typography variant="body2">
          Modifier la vue
        </Typography>
      </MenuItem>
      <Modal
        title={(
          <Typography>
            <Typography component="span" variant="bold">
              Modifier
            </Typography>
            {' '}
            le paramétrage de la vue
          </Typography>
        )}
        actionConfirm={() => {
          formik.handleSubmit();
        }}
        actionCancel={() => setOpenModal(false)}
        isOpen={openModal}
      >
        <StyledStack
          sx={{ padding: '5px', width: '100%' }}
          component="form"
          key={`formUpdateView${kpiView.id}`}
          direction="column"
          spacing={3}
          data-testid="modal-modify-kpi-view-ranking"
        >
          <FormControl>
            <TextField
              label="Nom de la vue"
              type="text"
              name="viewName"
              variant="outlined"
              size="small"
              sx={{
                fontSize: globalTheme.fontSize.xsmall,
              }}
              slotProps={{
                input: {
                  sx: {
                    fontSize: globalTheme.fontSize.xsmall,
                  },
                },
                inputLabel: {
                  sx: {
                    fontSize: globalTheme.fontSize.xsmall,
                  },
                },
              }}
              value={formik.values.viewName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.viewName
                && Boolean(formik.errors.viewName)
              }
              helperText={
                formik.touched.viewName
                && formik.errors.viewName
              }
            />
          </FormControl>
          <FormControl>
            <InputLabel id="addView-serpAnalysis-label" shrink>Catégorie(s)</InputLabel>
            <Select
              multiple
              value={formik.values.serpAnalysisIds}
              onChange={
                (e) => (
                  Array.isArray(e.target.value)
                  && e.target.value.includes(analysisId)
                  && formik.handleChange(e)
                )
              }
              size="small"
              labelId="addView-serpAnalysis-label"
              data-testid="addView-serpAnalysis-select"
              label="Catégorie(s)"
              name="serpAnalysisIds"
              notched
              renderValue={
                (saIds) => serpAnalyses.filter((sa) => (
                  saIds.find((id) => sa.id === id)
                )).map((sa) => (
                  <Chip
                    size="small"
                    key={`chip${sa.id}`}
                    label={sa.label}
                    sx={{ marginRight: '5px' }}
                  />
                ))
              }
            >
              {serpAnalyses.map((serpAnalysis) => (
                <MenuItem
                  key={serpAnalysis.id}
                  value={serpAnalysis.id}
                  dense
                  disableGutters
                  sx={{ height: '1rem' }}
                >
                  <Checkbox
                    checked={formik.values.serpAnalysisIds?.includes(serpAnalysis.id || 0)}
                    size="small"
                    disabled={serpAnalysis.id === analysisId}
                  />
                  <Typography
                    variant="body2"
                    component="span"
                    sx={{ fontSize: globalTheme.fontSize.xsmall }}
                  >
                    {serpAnalysis.label}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>
              {
                formik.values.serpAnalysisIds.length > 1
                && (
                  <Typography variant="bold">
                    Attention : Les filtres de mots clés et d&apos;intentions de recherche
                    ne sont pas sauvegardés lorsque vous choisissez plusieurs catégories
                  </Typography>
                )
              }
            </FormHelperText>
          </FormControl>
          <Stack direction="row" spacing={2}>
            <FormControl sx={{ flex: 1 }}>
              <LocalizationProvider
                dateAdapter={AdapterMoment}
                adapterLocale={moment.locale('fr')}
              >
                <DatePicker
                  label="Démarrage"
                  value={moment(formik.values.startDate)}
                  onChange={
                    (value) => formik.setFieldValue(
                      'startDate',
                      value && moment(value).isValid() ? value.format() : moment().format(),
                    )
                  }
                  format="DD/MM/YYYY"
                  slotProps={{
                    textField: {
                      size: 'small',
                      label: 'Démarrage',
                      error: false,
                      InputProps: {
                        sx: {
                          fontSize: globalTheme.fontSize.xsmall,
                        },
                      },
                      value: moment(formik.values.startDate),
                    },
                  }}
                />
              </LocalizationProvider>
            </FormControl>
            <FormControl
              sx={{ flex: 1 }}
              disabled={kpiView.kpi_view_type_id === KPI_VIEW_TYPE.serpTracking.id}
            >
              <InputLabel id="addView-timeSerie-label" shrink>Vue</InputLabel>
              <Select
                value={formik.values.timeSerie}
                onChange={formik.handleChange}
                size="small"
                labelId="addView-timeSerie-label"
                label="Vue"
                name="timeSerie"
                notched
                sx={{
                  fontSize: globalTheme.fontSize.xsmall,
                }}
              >
                <MenuItem
                  key="month"
                  value="month"
                  dense
                  sx={{ height: '1rem' }}
                >
                  <Typography
                    variant="body2"
                    component="span"
                    sx={{
                      fontSize: globalTheme.fontSize.xsmall,
                    }}
                  >
                    Mensuelle
                  </Typography>
                </MenuItem>
                <MenuItem
                  key="day"
                  value="day"
                  dense
                  sx={{ height: '1rem' }}
                >
                  <Typography
                    variant="body2"
                    component="span"
                    sx={{
                      fontSize: globalTheme.fontSize.xsmall,
                    }}
                  >
                    Quotidienne
                  </Typography>
                </MenuItem>
              </Select>
            </FormControl>
          </Stack>
          <Stack direction="row" spacing={2}>
            <FormControl className={classes.columnFilter}>
              <KeywordAutocomplete
                disabled={
                  formik.values.serpAnalysisIds.length > 1
                  || kpiView.kpi_view_type_id === KPI_VIEW_TYPE.serpTracking.id
                }
                handleChange={
                  (
                    e: any,
                    value: any,
                    selected: any[],
                    allOpt: any[],
                  ) => {
                    handleChangeAutocomplete(
                      'keywords',
                      value,
                      selected,
                      allOpt,
                    );
                  }
                }
                name="keywords"
                label="Mots clés"
                serpAnalysisId={analysisId}
                keywordIdsSelected={formik.values.keywords ?? []}
              />
            </FormControl>
            <FormControl className={classes.columnFilter}>
              <InputLabel id="select-actors-label" shrink>Acteurs</InputLabel>
              <Select
                multiple
                value={formik.values.actors}
                onChange={
                  (e) => handleChangeSelectMultiple(
                    e,
                    formik.values.actors,
                    actors.map((rs) => rs.id),
                  )
                }
                size="small"
                labelId="select-actors-label"
                label="Acteurs"
                name="actors"
                notched
                className={classes.select}
                renderValue={
                  (selected) => (
                    <Typography
                      variant="body2"
                      className={classes.inputSelectMultiple}
                      sx={{ fontSize: globalTheme.fontSize.xsmall, paddingTop: '.2rem' }}
                    >
                      {getActorNameFromActorId(selected, actors).join(', ')}
                    </Typography>
                  )
                }
              >
                <MenuItem
                  key={-1}
                  dense
                  value="all"
                  disableGutters
                  sx={{ height: '1rem' }}
                >
                  <Checkbox
                    checked={
                      formik.values.actors.length === actors.length
                    }
                    indeterminate={
                      formik.values.actors.length > 0
                      && formik.values.actors.length < actors.length
                    }
                    size="small"
                  />
                  <Typography
                    variant="body2"
                    component="span"
                    sx={{ fontSize: globalTheme.fontSize.xsmall }}
                  >
                    Tous / Aucun
                  </Typography>
                </MenuItem>
                {actors.map((actor) => (
                  <MenuItem
                    key={actor.id}
                    value={actor.id}
                    dense
                    disableGutters
                    sx={{ height: '1rem' }}
                  >
                    <Checkbox
                      checked={formik.values.actors?.includes(actor.id || 0)}
                      size="small"
                    />
                    <Typography
                      variant="body2"
                      component="span"
                      sx={{ fontSize: globalTheme.fontSize.xsmall }}
                    >
                      {actor.name}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
          <Stack
            spacing={2}
            direction="row"
          >
            <FormControl
              className={classes.columnFilter}
              disabled={
                formik.values.serpAnalysisIds.length > 1
                || kpiView.kpi_view_type_id === KPI_VIEW_TYPE.serpTracking.id
              }
            >
              <InputLabel
                id="select-intention-label"
                size="small"
                className={classes.inputLabel}
                shrink
              >
                Intentions de recherche
              </InputLabel>
              <Select
                value={
                  formik.values.searchIntentions
                }
                onChange={
                  (e) => handleChangeSelectMultiple(
                    e,
                    formik.values.searchIntentions,
                    intentList.map((rs) => rs.id),
                  )
                }
                labelId="select-intention-label"
                label="Intentions de recherche"
                name="searchIntentions"
                notched
                className={classes.select}
                inputProps={{ sx: { paddingTop: '.8rem' } }}
                multiple
                renderValue={
                  (selected) => (
                    <Typography
                      variant="body2"
                      className={classes.inputSelectMultiple}
                      sx={{ fontSize: globalTheme.fontSize.xsmall, paddingTop: '.2rem' }}
                    >
                      {getIntentNameFromIntentId(selected, intentList).join(', ')}
                    </Typography>
                  )
                }
              >
                <MenuItem
                  key={-1}
                  dense
                  value="all"
                  disableGutters
                  sx={{ height: '1rem' }}
                >
                  <Checkbox
                    checked={
                      formik.values.searchIntentions.length === intentList.length
                    }
                    indeterminate={
                      formik.values.searchIntentions.length > 0
                      && formik.values.searchIntentions.length < intentList.length
                    }
                    size="small"
                  />
                  <Typography
                    variant="body2"
                    component="span"
                    sx={{ fontSize: globalTheme.fontSize.xsmall }}
                  >
                    Tous / Aucun
                  </Typography>
                </MenuItem>
                {intentList.map((intent) => (
                  <MenuItem
                    value={intent.id}
                    key={intent.id}
                    dense
                    sx={{ height: '1rem' }}
                    disableGutters
                  >
                    <Checkbox
                      checked={
                        formik.values.searchIntentions?.includes(intent.id || 0)
                      }
                      size="small"
                    />
                    <Typography
                      variant="body2"
                      component="span"
                      sx={{ fontSize: globalTheme.fontSize.xsmall }}
                    >
                      {intent.label}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl
              className={classes.columnFilter}
              disabled={kpiView.kpi_view_type_id === KPI_VIEW_TYPE.serpTracking.id}
            >
              <InputLabel id="select-rankSlice-label" shrink>Rang(s)</InputLabel>
              <Select
                multiple
                value={formik.values.ranks}
                onChange={
                  (e) => handleChangeSelectMultiple(
                    e,
                    formik.values.ranks,
                    RANK_SLICES.map((rs) => rs.id),
                  )
                }
                size="small"
                labelId="select-rankSlice-label"
                label="Rang(s)"
                name="ranks"
                notched
                className={classes.mediumSelect}
                renderValue={
                  (selected) => (
                    <Typography
                      variant="body2"
                      className={classes.inputSelectMultiple}
                      sx={{ fontSize: globalTheme.fontSize.xsmall }}
                    >
                      {getSliceNameFromSliceId(selected, RANK_SLICES).join(', ')}
                    </Typography>
                  )
                }
              >
                <MenuItem
                  key={-1}
                  dense
                  value="all"
                  disableGutters
                  sx={{ height: '1rem' }}
                >
                  <Checkbox
                    checked={
                      formik.values.ranks.length === RANK_SLICES.length
                    }
                    indeterminate={
                      formik.values.ranks.length > 0
                      && formik.values.ranks.length < RANK_SLICES.length
                    }
                    size="small"
                  />
                  <Typography
                    variant="body2"
                    component="span"
                    sx={{ fontSize: globalTheme.fontSize.xsmall }}
                  >
                    Tous / Aucun
                  </Typography>
                </MenuItem>
                {RANK_SLICES.map((rankSlice) => (
                  <MenuItem
                    key={rankSlice.id}
                    value={rankSlice.id}
                    dense
                    disableGutters
                    sx={{ height: '1rem' }}
                  >
                    <Checkbox
                      checked={formik.values.ranks?.includes(rankSlice.id || 0)}
                      size="small"
                    />
                    <Typography
                      variant="body2"
                      component="span"
                      sx={{ fontSize: globalTheme.fontSize.xsmall }}
                    >
                      {rankSlice.label}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
          <Box>
            <Box>
              <RankingTypesDropDown
                value={formik.values.rankingTypes}
                name="rankingTypes"
                onChange={handleChangeRankingType}
                label="Type de résultat"
                size="small"
                height="2rem"
                fontSize="xsmall"
                optionsFilter={BENCHMARK_ONLY_RANKING_TYPES} // Filter out the types you don't want
                disabled={kpiView.kpi_view_type_id === KPI_VIEW_TYPE.serpTracking.id}
              />
            </Box>
            <FormControl>
              <RadioGroup
                defaultValue={
                  analysisDeviceTypeId === DEVICE_TYPE.DESKTOP
                    ? DEVICE_TYPE.DESKTOP
                    : DEVICE_TYPE.MOBILE
                  }
                value={formik.values.deviceType}
                name="deviceType"
                onChange={formik.handleChange}
                className={classes.radioGroup}
              >
                <FormControlLabel
                  value={DEVICE_TYPE.MOBILE}
                  className={classes.adjustRadio}
                  control={<Radio />}
                  label={<Typography variant="body2">SERP mobile</Typography>}
                  disabled={analysisDeviceTypeId === DEVICE_TYPE.DESKTOP}
                />
                <FormControlLabel
                  value={DEVICE_TYPE.DESKTOP}
                  control={<Radio />}
                  label={<Typography variant="body2">SERP desktop</Typography>}
                  disabled={analysisDeviceTypeId === DEVICE_TYPE.MOBILE}
                />
              </RadioGroup>
            </FormControl>
            {
              !isCurrentUserExternal && (
                <FormControl>
                  <FormControlLabel
                    control={<Checkbox checked={formik.values.export} />}
                    label={(
                      <Typography variant="body2">
                        Exporter les données pour looker studio
                      </Typography>
                    )}
                    name="export"
                    onChange={formik.handleChange}
                    disabled={kpiView.kpi_view_type_id === KPI_VIEW_TYPE.serpTracking.id}
                    data-testid="modifyView-checkbox-export"
                  />
                </FormControl>
              )
            }
          </Box>
        </StyledStack>
      </Modal>
    </Box>
  );
}
