import * as React from 'react';
import { Link } from 'react-router-dom';

import {
  Box, Drawer, Stack, styled,
} from '@mui/material';

import { PROJECTS } from 'router/CONSTANTS';

import { leftDrawerWidth, rightDrawerWidth } from '../config';

const PREFIX = 'RightDrawer';

const classes = {
  drawer: `${PREFIX}-drawer`,
  logo: `${PREFIX}-logo`,
  main: `${PREFIX}-main`,
};

const StyledBox = styled(
  Box,
  { shouldForwardProp: (prop) => prop !== 'open' },
)<{ open?: boolean; }>(({ theme, open }) => ({
  [`& .${classes.drawer}`]: {
    '& .MuiDrawer-paper': {
      zIndex: 0,
      backgroundColor: theme.palette.primary.main,
      borderRadius: '15px',
      color: 'white',
      padding: theme.spacing(1),
      width: rightDrawerWidth,
      left: leftDrawerWidth,
      overflow: 'hidden',
      height: '100vh',
    },
  },

  [`& .${classes.logo}`]: {
    width: '90px',
    marginBottom: '10px',
  },

  [`& .${classes.main}`]: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: rightDrawerWidth,
    }),
  },
}));

type RightDrawerProps = {
  isOpen: boolean,
  toggleDrawer: Function,
  menu: React.ReactNode,
}

export default function RightDrawerView(props: RightDrawerProps): JSX.Element {
  const {
    isOpen, toggleDrawer, menu,
  } = props;

  return (
    <StyledBox open={isOpen}>
      <Drawer
        anchor="left"
        variant="persistent"
        open={isOpen}
        onClose={toggleDrawer(false)}
        sx={{ left: leftDrawerWidth }}
        className={classes.drawer}
      >
        <Stack alignItems="center">
          <Link to={PROJECTS}>
            <img
              title="Benhur"
              src="/logo_benhur.png"
              alt="logo benhur"
              loading="lazy"
              className={classes.logo}
            />
          </Link>
          {menu}
        </Stack>
      </Drawer>
      <Box className={classes.main} />
    </StyledBox>
  );
}
