import React from 'react';
import { NavLink } from 'react-router-dom';

import {
  styled, Stack, Typography, Box, StackProps,
} from '@mui/material';
import { ResponsiveStyleValue } from '@mui/system';
import { Property } from 'csstype';

import globalTheme from 'common/theme';

interface TitleProps {
  title: string | JSX.Element,
  link?: string,
  capitalLetter?: boolean,
  direction?: StackProps['direction'],
  justifyContent?: ResponsiveStyleValue<Property.JustifyContent | readonly string[] | undefined>,
  fontSize?: keyof typeof globalTheme.fontSize
}

const PREFIX = 'ChartTitle';

const classes = {
  title: `${PREFIX}-title`,
  link: `${PREFIX}-link`,
  details: `${PREFIX}-details`,
};

const StyledBox = styled(Stack, {
  shouldForwardProp: (prop: string) => !['capitalLetter', 'styleFontSize'].includes(prop),
})<{
  capitalLetter?: boolean,
  styleFontSize?: keyof typeof globalTheme.fontSize,
}>(({ capitalLetter, styleFontSize, theme }) => ({
  [`& .${classes.title}`]: {
    fontSize: styleFontSize ? theme.fontSize[styleFontSize] : theme.fontSize.medium,
    fontWeight: '700',
    textTransform: capitalLetter ? 'uppercase' : 'none',
  },

  [`& .${classes.link}`]: {
    display: 'flex',
  },

  [`& .${classes.details}`]: {
    fontSize: theme.fontSize.xsmall,
  },
}));

function TitleComponent(props: TitleProps) {
  const {
    title,
    link,
    capitalLetter = false,
    direction = 'row',
    justifyContent,
    fontSize,
  } = props;
  return (
    <StyledBox
      direction={direction}
      alignItems="center"
      spacing={1}
      capitalLetter={capitalLetter}
      justifyContent={justifyContent}
      styleFontSize={fontSize}
    >
      {(typeof title === 'string')
        ? (
          <Typography className={classes.title}>
            {title}
          </Typography>
        )
        : (
          <Box className={classes.title}>
            {title}
          </Box>
        )}
      {link && (
        <NavLink to={link} className={classes.link} data-testid="link">
          <Typography className={classes.details}>(voir les détails)</Typography>
        </NavLink>
      )}
    </StyledBox>
  );
}

export default TitleComponent;
