import { RankingTypeGroupType } from './types';

export const BENCHMARK_ONLY_RANKING_TYPES = [44, 45, 46, 47, 48, 49, 50, 51, 52, 53];

export const RANKING_TYPE_GROUPS: RankingTypeGroupType[] = [
  {
    id: 1,
    name: 'zero-click',
    active: true,
    insertion_date: '',
  },
  {
    id: 2,
    name: 'liens internes',
    active: true,
    insertion_date: '',
  },
  {
    id: 3,
    name: 'liens externes',
    active: true,
    insertion_date: '',
  },
];
