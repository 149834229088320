import React from 'react';

import { Typography } from '@mui/material';

import globalTheme from 'common/theme';
import TitleComponent from 'features/dataviz/components/generic/charts/common/Title';

function PhysionomyHistoryTitle() {
  return (
    <TitleComponent
      title={(
        <Typography
          data-testid="physionomy-title-text"
          fontSize="24px"
        >
          <Typography
            component="span"
            color={globalTheme.palette.primary.light}
            variant="bold"
            sx={{ fontSize: '26px' }}
          >
            Captez l’attention de vos prospects
          </Typography>
          {' '}
          dans tous les espaces digitaux
        </Typography>
  )}
      justifyContent="center"
    />
  );
}

export default PhysionomyHistoryTitle;
