import React, { useEffect } from 'react';
import { useParams, useLocation, useSearchParams } from 'react-router-dom';

import { Box, BoxProps, styled } from '@mui/material';

import { useAppDispatch, useAppSelector } from 'common/reduxHooks';
import { fetchProjects, selectProjectById } from 'features/projects/state/slice';
import { selectAnalysisById } from 'features/serpAnalysis/state/slice';
import useSerpAnalyses from 'features/serpAnalysis/state/useSerpAnalyses';
import {
  splitIdName,
} from 'utils/urlManipulation';

import { projectsMenu } from './config';
import ProjectActions from './ProjectActions';
import ProjectChoice from './ProjectChoice';

const PREFIX = 'ProjectMenu';

const classes = {
  scrollBox: `${PREFIX}-scrollBox`,
};

const StyledBox = styled(Box)<BoxProps>(({ theme }) => ({
  [`&.${classes.scrollBox}`]: {
    overflowY: 'scroll',
    maxHeight: '90vh',
    '&::-webkit-scrollbar': {
      background: theme.palette.primary.main,
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.info.light,
    },
  },
}));

export default function ProjectMenu(): JSX.Element {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();

  const location = useLocation();
  const currentPage = location.pathname.split('/').slice(-1)[0];

  const { activeProjects: projects, isLoading } = useAppSelector(
    (state) => state.projects,
  );

  const { currentUser } = useAppSelector(
    (state) => state.authentication,
  );

  useEffect(() => {
    if (!isLoading && projects.length === 0) {
      dispatch(fetchProjects());
    }
  }, [dispatch, fetchProjects]);

  const { idNameProject, idNameAnalysis } = useParams();
  const { id: idProject } = splitIdName(idNameProject);
  const { id: idAnalysis } = splitIdName(idNameAnalysis);

  const project = useAppSelector((state) => (
    selectProjectById(state.projects, idProject)));

  const analysis = useAppSelector((state) => (
    selectAnalysisById(state.serpAnalyses, idAnalysis)));

  const { state: locState } = useLocation();
  const previousAnalysis = useAppSelector((state) => (
    selectAnalysisById(state.serpAnalyses, parseInt(locState?.from, 10))));

  const { serpAnalyses: analyses } = useSerpAnalyses(
    { scopeId: project?.scopes[0].id || 0, doFetch: true },
  );

  const sortedAnalyses = [...analyses];
  sortedAnalyses?.sort((a, b) => a.label.localeCompare(b.label));

  return (
    <StyledBox className={classes.scrollBox}>
      {!project && currentUser && !currentUser.user_type.is_external && (
        <ProjectChoice />
      )}
      {projects && !isLoading && project && currentUser && (
        <ProjectActions
          menu={
            projectsMenu(
              project.id,
              project.name,
              (analysis || previousAnalysis || sortedAnalyses[0]),
              searchParams,
              currentPage,
            )
          }
          projectName={project.name}
          projectId={project.id}
          analysisId={analysis?.id || previousAnalysis?.id || 0}
          scopeId={project.scopes[0].id}
          currentPage={currentPage}
        />
      )}
    </StyledBox>
  );
}
