import React, { useEffect, useState } from 'react';

import {
  Button,
  CircularProgress,
  SelectChangeEvent,
  Stack,
  styled,
} from '@mui/material';
import moment from 'moment';

import DropDown from 'components/dropDown/DropDown';
import { DefaultDropDownItemsType } from 'components/dropDown/type';
import MainBox from 'components/layout/MainBox';
import TitleComponent from 'features/dataviz/components/generic/charts/common/Title';
import DeviceDropDown from 'features/deviceType/components/DeviceDropDown';
import { DEVICE_TYPE, devicesListWithoutDevicesCombination } from 'features/deviceType/CONSTANTS';
import { Analysis } from 'features/serpAnalysis/types';
import useSerpAnalysisScrappings
  from 'features/serpAnalysisScrapping/state/useSerpAnalysisScrappings';
import { sortScrappingsByDate } from 'features/serpAnalysisScrapping/utils';
import { convertDate, DATE_ENGLISH, DATE_FULL_NAME_MONTH_YEAR } from 'utils/formatDate';

import organicLimit from '../CONSTANT';
import { PhysionomyHistoryFiltersForm } from '../types';

const PREFIX = 'physionomy-history-filter-container';

const classes = {
  filterButton: `${PREFIX}-filterButton`,
  cancelButton: `${PREFIX}-cancelButton`,
};
const StyledStack = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'isFilter',
})<{ isFilter: boolean }>(({ theme, isFilter }) => ({
  [`& .${classes.filterButton}`]: {
    backgroundColor: isFilter ? theme.palette.secondary.main : theme.palette.primary.light,
    color: isFilter ? theme.palette.primary.light : 'white',
    '&:hover': {
      color: theme.palette.primary.light,
      backgroundColor: theme.palette.secondary.main,
    },
    '&:disabled': {
      backgroundColor: '#E0E0E0',
      color: '#A6A7AB',
    },
  },

  [`& .${classes.cancelButton}`]: {
    color: theme.palette.primary.light,
  },
}));

type PhysionomyHistoryFiltersProps = {
  values: PhysionomyHistoryFiltersForm,
  onChange: (e: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<string>) => void,
  onSubmit: VoidFunction,
  onPeriodsLoaded?: (periods: string) => void,
  isLoading: boolean,
  isFilter?: boolean,
  onReset: Function,
  analysis: Analysis | undefined,
}

const title = 'Paramètres du graphique';

function PhysionomyHistoryFilters(props: PhysionomyHistoryFiltersProps) {
  const {
    values,
    onChange,
    onSubmit,
    onPeriodsLoaded,
    isLoading,
    isFilter = false,
    onReset,
    analysis,
  } = props;

  const [formatedPeriods, setFormatedPeriods] = useState<DefaultDropDownItemsType[]>([]);

  const {
    scrappings,
    isLoading: isSerpAnalysisLoading,
  } = useSerpAnalysisScrappings(analysis?.id || 0);

  useEffect(() => {
    const scrappingsSorted = sortScrappingsByDate(scrappings)
      .filter((scrapping) => scrapping.device_type_id === values.deviceId);

    let tempFormatedPeriods: DefaultDropDownItemsType[] = [];
    if (isSerpAnalysisLoading) tempFormatedPeriods = [];
    else if (scrappingsSorted.length) {
      tempFormatedPeriods = scrappingsSorted.map(({ scrapping_date }) => ({
        label: convertDate(
          moment(scrapping_date).startOf('month').toDate(),
          DATE_FULL_NAME_MONTH_YEAR,
        ),
        value: convertDate(moment(scrapping_date).utc().startOf('month').toDate(), DATE_ENGLISH),
      })).reduce((acc: { dates: Set<unknown>, result: DefaultDropDownItemsType[] }, current) => {
        // Utiliser un Set pour dédoublonner les dates
        if (!acc.dates.has(current.value)) {
          acc.dates.add(current.value);
          acc.result.push(current);
        }
        return acc;
      }, { dates: new Set(), result: [] }).result;
    } else {
      const defaultPeriods = convertDate(moment().startOf('month').toDate(), DATE_ENGLISH);
      tempFormatedPeriods = [{
        label: convertDate(moment(defaultPeriods).toDate(), DATE_FULL_NAME_MONTH_YEAR),
        value: defaultPeriods,
      }];
    }

    setFormatedPeriods(tempFormatedPeriods);
    if (typeof tempFormatedPeriods[0]?.value === 'string') {
      onPeriodsLoaded?.(tempFormatedPeriods[0]?.value);
    }
  }, [scrappings, values.deviceId]);

  if (isLoading) {
    return (
      <MainBox BoxProps={{ justifyItems: 'center' }}>
        <TitleComponent title={title} capitalLetter />
        <CircularProgress data-testid="physionomy-filters-spinner" />
      </MainBox>
    );
  }

  const deviceOptions = devicesListWithoutDevicesCombination.filter((dt) => {
    if (analysis?.device_type_id === DEVICE_TYPE.DESKTOP_MOBILE) {
      return [DEVICE_TYPE.DESKTOP, DEVICE_TYPE.MOBILE].includes(dt.id);
    }
    return dt.id === analysis?.device_type_id;
  });

  return (
    <MainBox>
      <TitleComponent
        title={title}
        capitalLetter
        direction="column"
      />
      <Stack
        direction="column"
        spacing={2}
        my={2}
        data-testid="physionomy-filters-form"
      >
        <DeviceDropDown
          name="deviceId"
          onChange={onChange}
          value={values.deviceId}
          size="small"
          fontSize="xsmall"
          options={deviceOptions}
          disabled={!analysis?.id || !scrappings.length}
        />
        <DropDown
          onChange={onChange}
          options={organicLimit}
          value={values.rankLimit}
          name="rankLimit"
          size="small"
          fontSize="xsmall"
          label="Limite organique"
          disabled={!analysis?.id || !scrappings.length}
        />
        <DropDown
          onChange={onChange}
          options={formatedPeriods}
          value={values.periode ?? ''}
          name="periode"
          size="small"
          fontSize="xsmall"
          label="Mois"
          disabled={!analysis?.id || !scrappings.length}
        />
      </Stack>
      <StyledStack
        justifyContent="flex-end"
        alignItems="center"
        direction="row"
        spacing={2}
        isFilter={isFilter}
      >
        {isFilter && (
          <Button
            size="small"
            className={classes.cancelButton}
            onClick={() => onReset()}
          >
            Réinitialiser
          </Button>
        )}
        <Button
          onClick={onSubmit}
          size="small"
          variant="contained"
          className={classes.filterButton}
          disabled={!analysis?.id || !scrappings.length}
        >
          Analyser
        </Button>
      </StyledStack>
    </MainBox>
  );
}

export default PhysionomyHistoryFilters;
