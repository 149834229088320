import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { SelectChangeEvent } from '@mui/material';

import { useAppSelector } from 'common/reduxHooks';
import DropDown from 'components/dropDown/DropDown';
import { generateProjectBaseUrl } from 'utils/urlManipulation';

import { selectProjectByUser } from '../state/slice';

interface ProjectDropdownProps {
  projectId: number,
}

function ProjectDropdown (props: ProjectDropdownProps) {
  const { projectId } = props;

  const navigate = useNavigate();
  const [projectSelected, setProject] = useState<number>(projectId);

  const { currentUser } = useAppSelector(
    (state) => state.authentication,
  );

  const projects = useAppSelector(
    ((state) => selectProjectByUser(state.projects, currentUser)),
  );

  const sortedProjects = [...projects].sort((a, b) => a.name.localeCompare(b.name));

  const onChange = (e: SelectChangeEvent<any>) => {
    const { target: { value } } = e;

    if (value === -1) {
      const firstProject = sortedProjects[0];
      navigate(`${generateProjectBaseUrl(firstProject.id, firstProject.name)}results`);
      setProject(firstProject.id);
    }

    const project = sortedProjects.find((prj) => prj.id === value);
    if (project) {
      navigate(`${generateProjectBaseUrl(project.id, project.name)}results`);
      setProject(project.id);
    }
  };

  const projectsWithEmpty: any[] = [
    {
      id: -1,
      name: 'Sélectionnez un projet',
    },
    ...sortedProjects,
  ];

  return (
    <DropDown
      value={projectSelected || 0}
      onChange={onChange}
      options={sortedProjects.length ? projectsWithEmpty : sortedProjects}
      getOptionLabel={(project) => project.name}
      getOptionValue={(project) => project.id}
      displayEmptyInField
      displayEmptyInList={false}
      name="project"
      fullWidth
      height="2rem"
      optionEmptyHelper="Aucun projet"
      data-testid="select-project-drop-down"
      emptyOption="Sélectionnez un projet"
    />
  );
}

export default ProjectDropdown;
