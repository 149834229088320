import React, { useState } from 'react';

import { Box, Typography, MenuItem } from '@mui/material';

import { useAppDispatch } from 'common/reduxHooks';
import globalTheme from 'common/theme';
import Modal from 'components/modal/Modal';
import { archiveProject } from 'features/projects/state/slice';

type ArchiveProjectProps = {
  id: number,
  handleCloseMenu: Function,
  hasExternalUsers: boolean,
}

function ArchiveProject (props: ArchiveProjectProps) {
  const { id, handleCloseMenu, hasExternalUsers } = props;
  const dispatch = useAppDispatch();
  const [openModal, setOpenModal] = useState<boolean>(false);

  const handleConfirm = () => {
    dispatch(archiveProject(id));
    setOpenModal(false);
  };

  return (
    <Box>
      <MenuItem
        sx={{ fontSize: globalTheme.fontSize.middlesmall }}
        onClick={
          () => { if (!openModal) setOpenModal(true); handleCloseMenu(); }
        }
      >
        Archiver ce projet
      </MenuItem>
      {
        hasExternalUsers
          ? (
            <Modal
              title={(
                <Typography data-testid="modal-delete-project">
                  <Typography component="span" variant="bold">Archivage impossible</Typography>
                  {' '}
                  : le client a accès à ce projet
                </Typography>
              )}
              actionConfirm={() => setOpenModal(false)}
              displayCancel={false}
              isOpen={openModal}
            />
          )
          : (
            <Modal
              title={(
                <Typography data-testid="modal-delete-project">
                  <Typography component="span" variant="bold">Archiver</Typography>
                  {' '}
                  ce projet
                </Typography>
              )}
              actionConfirm={handleConfirm}
              actionCancel={() => setOpenModal(false)}
              isOpen={openModal}
            />
          )
      }
    </Box>
  );
}

export default ArchiveProject;
