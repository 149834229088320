import React, { useState } from 'react';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {
  Box,
  Typography,
  Button,
} from '@mui/material';
import { FormikHelpers, FormikProps, useFormik } from 'formik';

import { useAppDispatch } from 'common/reduxHooks';
import Modal from 'components/modal/Modal';

import {
  initValuesCreate,
  validationSchemaCreateExternal,
  validationSchemaCreatePP,
} from './createModifyExternalUserConfig';
import CreateModifyExternalUserModal from './CreateModifyForm';
import { createUser } from '../state/slice';
import { UserType, CreateModifyExternalUserForm } from '../types';
import UsersUtils from '../UsersUtils';

type CreateExternalUserProps = {
  userList: UserType[],
  isPP: boolean,
}

function CreateExternalUser(props: CreateExternalUserProps) {
  const { userList, isPP } = props;
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleSubmit = (
    values: CreateModifyExternalUserForm,
    formikHelpers: FormikHelpers<CreateModifyExternalUserForm>,
  ) => {
    const payload = UsersUtils.createUserPayload(values);

    dispatch(
      createUser(payload),
    );
    setIsOpen(false);
    formikHelpers.resetForm();
  };

  const formik: FormikProps<CreateModifyExternalUserForm> = useFormik<CreateModifyExternalUserForm>(
    {
      initialValues: initValuesCreate,
      validationSchema: isPP
        ? validationSchemaCreatePP(userList)
        : validationSchemaCreateExternal(userList),
      onSubmit: handleSubmit,
      enableReinitialize: true,
    },
  );

  return (
    <Box>
      <Button
        variant="contained"
        size="small"
        startIcon={<AddCircleOutlineIcon />}
        onClick={
          () => {
            setIsOpen(true);
          }
        }
      >
        {isPP ? 'Créer un accès PP' : 'Créer un accès client'}
      </Button>
      <Modal
        title={(
          <Typography>
            <Typography component="span" variant="bold">
              Créer
            </Typography>
            {' '}
            {isPP ? 'un accès Première Place' : 'un accès client'}
          </Typography>
        )}
        actionConfirm={formik.handleSubmit}
        actionCancel={() => {
          setIsOpen(false);
          formik.resetForm();
        }}
        isOpen={isOpen}
        dataTestId="create-external-user-modal"
      >
        <CreateModifyExternalUserModal
          formik={formik}
          isModif={false}
          isPP={isPP}
        />
      </Modal>
    </Box>
  );
}

export default CreateExternalUser;
